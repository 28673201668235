import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import { QueuePlayNext, DeleteForever } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import * as componentActions from "../../redux/actions/calledclassifications";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import If, { Else } from '../../components/conditionals/if'
import { PapiroConsole } from "../../utils/papiroConsole";
import RatingElement from "../../components/rating-element";

class CalledClassificationRegistration extends Component {
  constructor(props) {
    super(props);
    const { userSession, calledclassifications } = this.props;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isCoordenator = false;
    this.isClient = true;
    this.isTec = true
    this.starRating = null
    this.authenticatedUserID = userSession.user.id;
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype != 2) {
        this.isTec = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 4) {
          this.isCoordenator = true;
      }
    });
    
    console.log("=== prop ===")
    console.log(userSession.user)

    this.initialState = {
      updatedUser: userSession.user.id,
      id: calledclassifications.calledclassification.id,
      hirerId : calledclassifications.calledclassification.hirerid,
      CalledId: calledclassifications.calledclassification.CalledId,
      CalledText: calledclassifications.calledclassification.CalledText,
      AssignedUserId: calledclassifications.calledclassification.AssignedUserId ? calledclassifications.calledclassification.AssignedUserId : calledclassifications.calledclassification.assigneduser ? calledclassifications.calledclassification.assigneduser.id : 0,
      RequestingUserId: calledclassifications.calledclassification.requestinguserid ? calledclassifications.calledclassification.requestinguserid : calledclassifications.calledclassification.requestinguser ? calledclassifications.calledclassification.requestinguser.id : 0,
      AssignedUserText: calledclassifications.calledclassification.AssignedUserText,
      RequestingUserName: calledclassifications.calledclassification.requestinguser ? calledclassifications.calledclassification.requestinguser.name : "",
      note: calledclassifications.calledclassification.note,
      observation: calledclassifications.calledclassification.observation,
      OpeningDate: calledclassifications.calledclassification.OpeningDate,
      Type: calledclassifications.calledclassification.Type,
    };

    this.state = {
      calledclassification: this.initialState,
      surveytype: calledclassifications.calledclassification.satisfactionsurvey && calledclassifications.calledclassification
          && calledclassifications.calledclassification.satisfactionsurvey 
          &&  calledclassifications.calledclassification.satisfactionsurvey.satisfactionsurveyquestions
          &&  calledclassifications.calledclassification.satisfactionsurvey.satisfactionsurveyquestions.length > 0
          ? calledclassifications.calledclassification.satisfactionsurvey.satisfactionsurveyquestions[0].satisfactionsurveyquestionstypeid : null,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      CalledList: [],
      AssignedUserList: [],
      starRating: null,
      mstarRating: null,
      updatedUser: userSession.user.id,
    };

    PapiroConsole.log("=== CalledClassificationRegistration ===")
    PapiroConsole.log(this.props)
    PapiroConsole.log(this.initialState)
    PapiroConsole.log(this.state.calledclassification.RequestingUserId)
    PapiroConsole.log(this.state.calledclassification.AssignedUserId)
    PapiroConsole.log(this.state.calledclassification.updatedUser)

    console.log("=== State ===")
    console.log(this.state)

    // console.log(this.state);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    this.changeRating = this.changeRating.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() { }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    // console.log(name, value);

    this.setState((prevState) => ({
      calledclassification: {
        ...prevState.calledclassification,
        [name]: value,
      },
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const { calledclassification, starRating } = this.state;
    // console.log(calledclassification, starRating);

    let data = {
      adm: (this.globalAdm || this.AdmOrganization) ? true : false,
      ObservationAssigned: calledclassification.observation,
      CalledId: calledclassification.CalledId,
      NoteAssigned: starRating,
      CreatedUser: this.state.calledclassification.updatedUser
    };
    // console.log(data.ObservationAssigned);

    if (data.NoteAssigned === 0 || data.NoteAssigned == undefined || data.NoteAssigned == null) {
      const intl = this.props.intl;

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "note.request" }),
      });

    } else {
      this.setState((prevState) => ({
        calledclassification: {
          ...prevState.calledclassification,
        },
      }));

      Api.post("/calledclassifications", data)
        .then((result) => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({ id: "class.success" }),
            });

            if(result.data.data) {
              if(result.data.data.assigneduserid && result.data.data.assigneduserid == this.state.calledclassification.updatedUser && result.data.data.noterequest != null) {
                this.setState({ mstarRating: result.data.data.noterequest })
              } else if(result.data.data.requestinguserid && result.data.data.requestinguserid == this.state.calledclassification.updatedUser && result.data.data.noteassigned != null) {
                this.setState({ mstarRating: result.data.data.noteassigned })
              }
            }

            this.setState({ loading: false })

            this.componentDidMount();
          } else {
            // console.log("Erro");
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:
                err.response && err.response.data && err.response.data.errors
                  ? err.response.data.errors[0]
                  : intl.formatMessage({ id: "process.error" }),
            });
          }
        });
    }


  };



  closeNotification() {
    this.setState({ openNotification: false });
  }


  async resendEmail(){
    const intl = this.props.intl;
    const { calledclassification } = this.state;
    
   
     var calledId =  calledclassification.CalledId
     var hirerId = calledclassification.hirerId

    this.setState({ loading: true});

  
  Api.put(`feedback/resendFeedbackInside/${calledId}/${hirerId}`)
    .then(result => {
      if (result.data.success) {
  
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: intl.formatMessage({id:"nps.email.rating.feedback.link.sent"}),
        });
  
        
      }
    })
    .catch(err => {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        disabledResendButton : true,
        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        
      });
    })
  
    
    
  
  }



  changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      calledclassification: {
        ...prevState.calledclassification,
        [stateName]: value,
      },
    }));

    if (stateName === "CalledId") {
      this.setState((prevState) => ({
        calledclassification: {
          ...prevState.calledclassification,
          ["CalledText"]: text,
        },
      }));
    }

    this.setState({ loading: false });
  }

  checkRating = _ => {
    const { calledclassifications } = this.props
    const RequestingUserId = calledclassifications && calledclassifications.calledclassification && calledclassifications.calledclassification && calledclassifications.calledclassification.requestinguser && calledclassifications.calledclassification.requestinguser.id ? calledclassifications.calledclassification.requestinguser.id : 0
    const AssignedUserId = calledclassifications && calledclassifications.calledclassification && calledclassifications.calledclassification.assigneduser && calledclassifications.calledclassification.assigneduser.id ? calledclassifications.calledclassification.assigneduser.id : 0
    
    PapiroConsole.log("=== checkRating ===")
    PapiroConsole.log(this.state.mstarRating)
    PapiroConsole.log(this.authenticatedUserID)
    PapiroConsole.log(RequestingUserId)
    PapiroConsole.log(AssignedUserId)

    if(this.state.mstarRating != null) {
      PapiroConsole.log("=== checkRating => mstarRating ===")
      return false
    }

    if (this.authenticatedUserID != RequestingUserId && this.authenticatedUserID != AssignedUserId) {
      PapiroConsole.log("=== checkRating => diff ===")
      return false
    } else {
      PapiroConsole.log("=== checkRating => equals ===")
      return true
    }
  }

  changeRating(newValue) {
    this.setState({
      starRating: newValue
    })
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, calledclassifications } = this.props;
    const { calledclassification, surveytype, value, setValue } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="xl">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({id:"tab.called.report.called.number"})}
                      name="CalledId"
                      value={calledclassification.CalledId}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "called" })}
                      name="CalledText"
                      value={calledclassification.CalledText}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "assigned" })}
                      name="AssignedUserText"
                      value={calledclassification.AssignedUserText}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "client" })}
                      name="RequestingUserName"
                      value={calledclassification.RequestingUserName}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      label={intl.formatMessage({ id: "open.date" })}
                      name="OpeningDate"
                      value={calledclassification.OpeningDate}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={intl.formatMessage({ id: "type" })}
                      name="type"
                      value={calledclassification.Type}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} />
                  <Grid item xs={12} sm={4} />
                  <Grid item xs={12} sm={4} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    container
                    alignItems="flex-start"
                    justify="center"
                    direction="row"
                    style={{ marginTop: 15 }}
                  >
                    <Box component="fieldset" mb={3} borderColor="transparent" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <If test={calledclassification.RequestingUserId == calledclassification.updatedUser }>
                        <Typography component="h1" variant="h5">
                          &nbsp;&nbsp;&nbsp;
                          {intl.formatMessage({ id: "csat.requester.to.assigned" })}
                        </Typography>
                        <br />
                        <RatingElement value={this.state.starRating} type={surveytype} edit={this.checkRating()} onChangeRating={this.changeRating}/>
                        <br />
                      </If>
                      <If test={calledclassification.AssignedUserId == calledclassification.updatedUser}>
                      <Typography component="h1" variant="h5">
                          &nbsp;&nbsp;&nbsp;
                          {intl.formatMessage({ id: "csat.assigned.to.requester" })}
                        </Typography>
                        <br />
                        <Rating
                          name="ratingRequest"
                          required
                          readOnly={!this.checkRating()}
                          value={this.state.calledclassification.noteRequest}
                          onChange={(event, newValue) => {
                            this.setState({
                              starRating: newValue
                            })
                          }}
                        />
                      </If>
                      <If test={calledclassification.RequestingUserId != calledclassification.updatedUser && (calledclassification.AssignedUserId != calledclassification.updatedUser)}>
                        <Typography component="h1" variant="h5">
                            &nbsp;&nbsp;&nbsp;
                            {intl.formatMessage({ id: "classification" })}
                          </Typography>
                          <br />
                        <RatingElement value={this.state.starRating} type={surveytype} onChangeRating={this.changeRating}/>
                      </If>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "observation" })}
                      variant="outlined"
                      name="observation"
                      onChange={this.handleInputChange}
                      value={calledclassification.observation}
                      multiline
                      rows="8"
                      disabled={ !this.checkRating() }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                    
                      {!this.isClient &&
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          xl={3}
                          align-items-xs-center={"true"}
                          
                        >
                          <Button
                            
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.resendEmail}
                          >
                            {<FormattedMessage id="resend.csat.requester.to.assigned" />}
                          </Button>
                        </Grid>
                      }

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        xl={3}
                        
                      
                      >
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={ !this.checkRating() }
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>

                      
                      
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
CalledClassificationRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calledclassifications: state.calledclassifications,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(Styles)(CalledClassificationRegistration))
);
