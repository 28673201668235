import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material'
import { QueuePlayNext, DeleteForever } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import HeaderMenu from '../../components/header-menu'
import { connect } from 'react-redux'
import Footer from '../../components/footer'
import { browserHistory } from '../../helpers/history'
import { FormattedMessage, injectIntl } from 'react-intl'
import Title from '../../components/title-name'
import 'react-quill/dist/quill.snow.css'; // ES6
import { PapiroConsole } from '../../utils/papiroConsole'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyIcon from '@mui/icons-material/Key';
import fileDownload from 'js-file-download'
import { List, ListItem, ListItemText } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';





const bulletIconStyle = {
  fontSize: '10px', // Ajuste o tamanho da fonte do ícone
  marginRight: '8px', // Ajuste a margem conforme necessário
};

const listItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px', // Ajuste o espaçamento conforme necessário
};
class SecurityItemRegistered extends Component {

  constructor(props) {
    super(props)
    const { userSession, organizations } = this.props

    

    this.globalAdm = false
    this.isClient = true

    if (userSession.user.userrole == null) {
      Api.logoff()
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype !== 3) {
        this.isClient = false
      }
      if (item.role.roletype === 0) {
        this.globalAdm = true
      }
    })

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      action: '',
      active: false,
      title: '',
      applicationFilter: null,
      secretAccesskey: this.props.location.state ? this.props.location.state.secretAccesskey : '',
      accesskey: this.props.location.state ? this.props.location.state.accesskey : '',
    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      openAccordion: true,
      openAccordionBestPractices: true

    }


    this.closeNotification = this.closeNotification.bind(this)
    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.setAccordion = this.setAccordion.bind(this)
    this.downloadCsv = this.downloadCsv.bind(this)


    this.setAccordionBestPractices = this.setAccordionBestPractices.bind(this)




  }

  async downloadCsv() {

    //const intl = this.props.intl;

    this.setState({ loading: true });
    
    let url = `userapipermission/downloadcsv?accesskey=${this.state.item.accesskey}&secretaccesskey=${this.state.item.secretAccesskey}`;


    Api.get(url, {
      responseType: 'blob'
    })
      .then((response) => {

        //   let url = window.URL.createObjectURL(new Blob([response.data]));
        //saveAs(url, 'called_list.csv');

        fileDownload(response.data, `accesskey_${moment().format('DD/MM/YYYY HH:mm:ss')}.zip`);

        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });


  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  setAccordion() {

    this.setState({ openAccordion: !this.state.openAccordion })


  }

  setAccordionBestPractices() {

    this.setState({ openAccordionBestPractices: !this.state.openAccordionBestPractices })


  }



  handleCopyClick(link) {

    
    navigator.clipboard.writeText(link).then(() => {
      console.log('Link copied to clipboard:', link);
    }).catch(error => {
      console.error('Error copying link:', link);
    });
  }

  componentDidMount() {



  }





  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;



    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <KeyIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="Gerenciar chave de acesso" />}
                <Title />
              </Typography>

              <Grid container spacing={2} style={{ marginTop: 0 }}>
                <Grid item xs={12} >
                  <Accordion onChange={this.setAccordion} expanded={this.state.openAccordion} style={{ marginTop: 0 }} >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >

                      <Typography variant="h6" gutterBottom fontWeight="bold">
                        Chave de acesso
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container >
                        <Grid item xs={12} >
                          <Typography variant="subtitle2" gutterBottom>
                            Se você perder ou esquecer sua chave de acesso secreta, não poderá recuperá-la. Em vez disso, crie uma nova chave de acesso e torne a chave antiga inativa.
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: '30px' }} >



                          <Grid item xs={12} >
                            <Typography variant="subtitle2" gutterBottom color="textSecondary" fontWeight={600}>
                              Chave de acesso
                            </Typography>
                          </Grid>

                          <Grid item xs={12}  >
                            <Grid container>
                              <Grid item xs={1} >
                                <Tooltip title="Copy Link">
                                  <IconButton onClick={() => this.handleCopyClick(this.state.item.accesskey)}>
                                    <FileCopyIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={10} style={{ marginTop: '10px' }}  >
                                <Typography noWrap variant="subtitle2" gutterBottom color="textSecondary">
                                  {this.state.item.accesskey}
                                </Typography>
                              </Grid>
                            </Grid>

                          </Grid>



                        </Grid>
                        <Grid item xs={6} style={{ marginTop: '30px' }} >


                          <Grid item xs={12} >
                            <Typography noWrap variant="subtitle2" gutterBottom color="textSecondary" fontWeight={600}>
                              Chave de acesso secreta
                            </Typography>
                          </Grid>

                          <Grid item xs={12}  >
                            <Grid container>
                              <Grid item xs={1} >
                                <Tooltip title="Copy Link">
                                  <IconButton onClick={() => this.handleCopyClick(this.state.item.secretAccesskey)}>
                                    <FileCopyIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={10} style={{ marginTop: '10px' }}  >
                                <Typography noWrap variant="subtitle2" gutterBottom color="textSecondary">
                                  {this.state.item.secretAccesskey}
                                </Typography>
                              </Grid>
                            </Grid>

                          </Grid>





                        </Grid>
                      </Grid>



                    </AccordionDetails>
                  </Accordion>


                  <Accordion onChange={this.setAccordionBestPractices} expanded={this.state.openAccordionBestPractices} style={{ marginTop: 0 }} >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >

                      <Typography variant="h6" gutterBottom fontWeight="bold">
                        Acesse as principais práticas recomendadas
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <List style={{ listStyleType: 'none', padding: 0 }}>
                        <ListItem style={listItemStyle}>
                          <FiberManualRecordIcon style={bulletIconStyle} />
                          <ListItemText primary="Nunca armazene sua chave de acesso em texto sem formação, em um repositório de códigos ou em código" />
                        </ListItem>
                        <ListItem style={listItemStyle}>
                          <FiberManualRecordIcon style={bulletIconStyle} />
                          <ListItemText primary="Desabilite ou exclua a chave de acesso quando não for mais necessária" />
                        </ListItem>
                        <ListItem style={listItemStyle}>
                          <FiberManualRecordIcon style={bulletIconStyle} />
                          <ListItemText primary="Alterne regularmente as chaves de acesso" />
                        </ListItem>
                      </List>



                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                    <Grid item xs={12} sm={4}    >
                      <Button
                        onClick={this.downloadCsv}
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit} >
                        {<FormattedMessage id="download.csv" />}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>





            </div>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />


          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

SecurityItemRegistered.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(SecurityItemRegistered)));