import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { PapiroConsole } from '../../../../utils/papiroConsole';

class UserAssociation extends Component {
  constructor(props) {
    super(props);
    PapiroConsole.log("props 2 aqui ")
    PapiroConsole.log(this.props)

    this.state = {
      selectedTechnicians: [],
      autocompleteInputValue: '',
    };
  }

  handleTechnicianSelect = (technician) => {
    const order = this.state.selectedTechnicians.length + 1;
    this.setState((prevState) => ({
      selectedTechnicians: [...prevState.selectedTechnicians, { ...technician, order }],
    }));
  };

  filterOptions = (options, { inputValue }) => {
    const { selectedTechnicians } = this.state;

    return options.filter(
      (option) =>
        !selectedTechnicians.find((selected) => selected.id === option.id) &&
        !this.state.selectedTechnicians.find((selected) => selected.id === option.id)
    );
  };

  handleOrderChange = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedTechnicians = [...this.state.selectedTechnicians];
    const [movedTechnician] = updatedTechnicians.splice(result.source.index, 1);
    updatedTechnicians.splice(result.destination.index, 0, movedTechnician);

    // Atualiza a ordem após a reordenação
    updatedTechnicians.forEach((technician, index) => {
      technician.order = index + 1;
    });

    this.setState({ selectedTechnicians: updatedTechnicians });
  };

  handleTechnicianDelete = (index) => {
    const updatedTechnicians = [...this.state.selectedTechnicians];
    updatedTechnicians.splice(index, 1);

    // Atualiza a ordem após a exclusão
    updatedTechnicians.forEach((technician, i) => {
      technician.order = i + 1;
    });
    

    this.setState({ selectedTechnicians: updatedTechnicians });
  };

  componentDidMount() {
      PapiroConsole.log("variavel props aqui")
      PapiroConsole.log(this.props)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.TechinicalTeamId !== this.props.TechinicalTeamId) {
      PapiroConsole.log(prevProps.TechinicalTeamId)
      PapiroConsole.log(this.props.TechinicalTeamId)
     
     
      PapiroConsole.log("entrei aqui no componentDidUpdate ")
    }
  }

  render() {
    const { selectedTechnicians, autocompleteInputValue } = this.state;
    const { TechinicalTeamId } = this.props;
   

    return (
      <div style={{ maxWidth: '600px', margin: 'auto', padding: '16px' }}>
        <Autocomplete
          value={null}
          inputValue={autocompleteInputValue}
          options={[
            { name: 'Technician 1', id: '1' },
            { name: 'Technician 2', id: '2' },
            { name: 'Technician 3', id: '3' },
            // Adicione mais técnicos conforme necessário
          ]}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => value && this.handleTechnicianSelect(value)}
          renderInput={(params) => (
            <TextField {...params} label="Técnicos" variant="outlined" fullWidth />
          )}
          filterOptions={this.filterOptions}
          
        />

        <Paper elevation={3} style={{ marginTop: 16 }}>
          <DragDropContext onDragEnd={this.handleOrderChange}>
            <Droppable droppableId="technicians">
              {(provided) => (
                <TableContainer ref={provided.innerRef} {...provided.droppableProps}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome do Técnico</TableCell>
                        <TableCell>Ordem</TableCell>
                        <TableCell>Excluir</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedTechnicians.map((technician, index) => (
                        <Draggable key={technician.id} draggableId={technician.id} index={index}>
                          {(provided) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TableCell>{technician.name}</TableCell>
                              <TableCell>{technician.order}</TableCell>
                              <TableCell>
                                <IconButton
                            
                                  onClick={() => this.handleTechnicianDelete(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Droppable>
          </DragDropContext>
        </Paper>
      </div>
    );
  }
}

export default UserAssociation;
