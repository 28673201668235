import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SimpleCheckBox from '../../components/checkbox/check';
import FlightLand from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import * as Service from '../../services/organizationaccountemail.service';
import * as componentActions from '../../redux/actions/organizationaccountemails';
import { bindActionCreators } from 'redux';
import SimpleHelp from '../../components/help';
import { getAllByOrganizationIdSelectionModel as getAllAddressesByOrganizationIdSelecionModel } from '../../services/address.service'
import { getAllSelectionModelCanCreateCalledByOrganizationId as getAllOrganizationCategoryCanCreateCAlledByOrganizationId } from '../../services/organizationcategory.service'
import { getAllSelectionModel as getAllStatusSelectionModel } from'../../services/status.service'
import { getAllSelectionModel as getAllTypeSelectionModel } from'../../services/type.service'
import SimpleSelect from '../../components/select/simple';
import { PapiroConsole } from '../../utils/papiroConsole';

class OrganizationAccountEmailEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizationaccountemails } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!organizationaccountemails || (organizationaccountemails && !organizationaccountemails.organizationaccountemail)) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    // console.log(organizationaccountemails);
    let item = organizationaccountemails.organizationaccountemail;
    this.initialState = {
      id: item.id,
      UpdatedUser: userSession.user.id,
      active: item.active,
      organizationid: item.organizationid,
      providertype: item.providertype,
      responseemailisnewcalled: item.responseemailisnewcalled,
      accompanimentcalledforresponseemail: item.accompanimentcalledforresponseemail,
      name: item.name,
      addressId: 0,
      organizationCategoryId: 0,
      statusId: 0,
      typeId: 0,
      addressemail: item.addressemail,
      idSend: 0,
      serverSend: '',
      fromMailSend: '',
      portSend: '',
      cryptotypeSend: '',
      passwordSend: '',
      userSend: '',
      authenticationrequiredSend: false,
      authenticationmethodtypeIdSend: '',
      idReceived: 0,
      serverReceived: '',
      fromMailReceived: '',
      portReceived: '',
      cryptotypeReceived: '',
      passwordReceived: '',
      userReceived: '',
      authenticationrequiredReceived: false,
      authenticationmethodtypeIdReceived: '',
      emailtest: ''
    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrgList: [],
      ProviderList: [],
      CryptoList: [],
      AuthenticationList: [],
      addessesList: [],
      organizationCategoriesList: [],
      statusList: [],
      typesList: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getAllAddressesByOrganizationIdSelecionModel = this.getAllAddressesByOrganizationIdSelecionModel.bind(this)
    this.getAllOrganizationCategoryCanCreateCAlledByOrganizationId = this.getAllOrganizationCategoryCanCreateCAlledByOrganizationId.bind(this)
    this.getAllStatusSelectionModel = this.getAllStatusSelectionModel.bind(this)
    this.getAllTypeSelectionModel = this.getAllTypeSelectionModel.bind(this)
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getOrg();
    await this.getAllAddressesByOrganizationIdSelecionModel()
    await this.getAllOrganizationCategoryCanCreateCAlledByOrganizationId()
    await this.getAllStatusSelectionModel()
    await this.getAllTypeSelectionModel()
    await this.getProvider();
    await this.getAuthentication();
    await this.getCrypto();
    await this.getDados(1);
    await this.getDados(0);
    await this.setState({ loading: false });
  }

  getAllAddressesByOrganizationIdSelecionModel = async _ => {
    const { organizationaccountemails } = this.props;

    let result = await getAllAddressesByOrganizationIdSelecionModel(this.state.item.organizationid)
    if (result && result.success) {
      if (organizationaccountemails != null && organizationaccountemails.organizationaccountemail != null && organizationaccountemails.organizationaccountemail.addressid != null && result.data != null) {
        var item = result.data.find(item => item.id == organizationaccountemails.organizationaccountemail.addressid)
        if (item != null)
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              addressId: item
            }
          }));
      }
      this.setState({ addessesList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }

  getAllOrganizationCategoryCanCreateCAlledByOrganizationId = async _ => {
    const { organizationaccountemails } = this.props;

    let result = await getAllOrganizationCategoryCanCreateCAlledByOrganizationId(this.state.item.organizationid)
    if (result && result.success) {
      if (organizationaccountemails != null && organizationaccountemails.organizationaccountemail != null && organizationaccountemails.organizationaccountemail.organizationcategoryid != null && result.data != null) {
        var item = result.data.find(item => item.id == organizationaccountemails.organizationaccountemail.organizationcategoryid)
        if (item != null)
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              organizationCategoryId: item
            }
          }));
      }
      this.setState({ organizationCategoriesList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }

  getAllStatusSelectionModel = async _ => {
    const { organizationaccountemails } = this.props;

    let result = await getAllStatusSelectionModel()
    if (result && result.success) {
      if (organizationaccountemails != null && organizationaccountemails.organizationaccountemail != null && organizationaccountemails.organizationaccountemail.statusid != null && result.data != null) {
        var item = result.data.find(item => item.id == organizationaccountemails.organizationaccountemail.statusid)
        if (item != null)
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              statusId: item
            }
          }));
      }
      this.setState({ statusList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }

  getAllTypeSelectionModel = async _ => {
    const { organizationaccountemails } = this.props;

    let result = await getAllTypeSelectionModel()
    if (result && result.success) {
      if (organizationaccountemails != null && organizationaccountemails.organizationaccountemail != null && organizationaccountemails.organizationaccountemail.typeid != null && result.data != null) {
        var item = result.data.find(item => item.id == organizationaccountemails.organizationaccountemail.typeid)
        if (item != null)
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              typeId: item 
            }
          }));
      }
      this.setState({ typesList: result.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }

  async getDados(type) {
    //Type = 0 Send
    this.setState({ loading: true });
    var result = await Service.getDados(this.state.item.id, type);

    // console.log(result);
    if (result.success) {
      if (type == 0) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            idSend: result.data.id,
            serverSend: result.data.server,
            fromMailSend: result.data.frommail,
            portSend: result.data.port,
            cryptotypeSend: result.data.cryptotype,
            /*passwordSend: result.data.password,*/
            userSend: result.data.user,
            authenticationrequiredSend: result.data.authenticationrequired,
            authenticationmethodtypeIdSend: result.data.authenticationmethodtype,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            idReceived: result.data.id,
            serverReceived: result.data.server,
            fromMailReceived: result.data.frommail,
            portReceived: result.data.port,
            cryptotypeReceived: result.data.cryptotype,
            /*passwordReceived: result.data.password,*/
            userReceived: result.data.user,
            authenticationrequiredReceived: result.data.authenticationrequired,
            authenticationmethodtypeIdReceived: result.data.authenticationmethodtype,
          }
        }));
      }
    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  };
  async getOrg() {
    this.setState({ loading: true });
    var result = await Service.getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgList: result.data });

    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  };
  async getProvider() {
    this.setState({ loading: true });
    var result = [];
    result.push({ id: '1', name: 'Google' });

    this.setState({ loading: false, ProviderList: result });
  };
  async getAuthentication() {
    this.setState({ loading: true });
    var result = [];
    result.push({ id: '1', name: 'DKIM' });

    this.setState({ loading: false, AuthenticationList: result });
  };
  async getCrypto() {
    this.setState({ loading: true });
    var result = [];
    result.push({ id: '1', name: 'SSL' });

    this.setState({ loading: false, CryptoList: result });
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    if(this.state.item.serverSend == null || this.state.item.serverSend.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.server.field"})
      });
      return false
    }
    if(this.state.item.fromMailSend == null || this.state.item.fromMailSend.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `O campo 'E-mail' em 'Informações de Envio' é obrigatório`
      });
      return false
    }
    if(this.state.item.portSend == null || this.state.item.portSend.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.port.field"})
      });
      return false
    }
    if(this.state.item.cryptotypeSend == null || this.state.item.cryptotypeSend.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.crypto.field"})
      });
      return false
    }
    if(this.state.item.userSend == null || this.state.item.userSend.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.user.field"})
      });
      return false
    }
    if(this.state.item.passwordSend == null || this.state.item.passwordSend.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.password.field"})
      });
      return false
    }

    if(this.state.item.serverReceived == null || this.state.item.serverReceived.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.server.field.info"})
      });
      return false
    }
    if(this.state.item.fromMailReceived == null || this.state.item.fromMailReceived.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: `O campo 'E-mail' em 'Informações de Recebimento' é obrigatório`
      });
      return false
    }
    if(this.state.item.portReceived == null || this.state.item.portReceived.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.port.field.info"})
      });
      return false
    }
    if(this.state.item.cryptotypeReceived == null || this.state.item.cryptotypeReceived.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.crypto.field.info"})
      });
      return false
    }
    if(this.state.item.userReceived == null || this.state.item.userReceived.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.user.field.info"})
      });
      return false
    }
    if(this.state.item.passwordReceived == null || this.state.item.passwordReceived.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.missing.password.field.info"})
      });
      return false
    }

    this.setState({ loading: true });

    if (this.state.item.responseemailisnewcalled === true && this.state.item.accompanimentcalledforresponseemail === true) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"organization.account.email.registration.email.answer.is.new.called"})

      });
    } else {
      let data = new FormData();

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().endsWith('Id')) {
          if (this.state.item[key] && this.state.item[key].id && this.state.item[key].id > 0)
            data.append(key, this.state.item[key].id)
          else if (this.state.item[key] && this.state.item[key] > 0)
            data.append(key, this.state.item[key])
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      for (var pair of data.entries()) {
        // console.log(pair[0]+ ', '+ pair[1]); 
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      Api.put('/organizationaccountemails', data, config)

        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
            });


          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"}) + err.response.data
            });
          }
          Api.kickoff(err);
        });
    }

  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  testSendMail = async () => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    if (this.state.item.serverSend != '' && this.state.item.portSend != '' && this.state.item.userSend != '' && this.state.item.emailtest != '' && this.state.item.fromMailSend != '') {
      var data = {
        idSend: this.state.item.idSend,
        serverSend: this.state.item.serverSend,
        fromMailSend: this.state.item.fromMailSend,
        portSend: this.state.item.portSend,
        passwordSend: this.state.item.passwordSend != null ? this.state.item.passwordSend : null,
        userSend: this.state.item.userSend,
        addressemail: this.state.item.emailtest,
      }

      await Api.post('/emails/testSend', data)
        .then(result => {
          if (result && result.data && result.data.success == true) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"email.sent"})
            });
          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"error.verify.sending.info"})
            });
          }
        })
        .catch(err => {
          console.log(err)
          console.log("err")
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"error.verify.sending.info"})
          });
          Api.kickoff(err);
        });

    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"insert.sending.info"}),
      })
    }
  }

  testReceivedMail = async () => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    if (this.state.item.serverReceived != '' && this.state.item.portReceived != '' && this.state.item.userReceived != '' && this.state.item.fromMailReceived != '') {
      var data = {
        idReceived: this.state.item.idReceived,
        serverReceived: this.state.item.serverReceived,
        fromMailReceived: this.state.item.fromMailReceived,
        portReceived: this.state.item.portReceived,
        passwordReceived: this.state.item.passwordReceived != null ? this.state.item.passwordReceived : null,
        userReceived: this.state.item.userReceived,
      }

      await Api.post('/emails/testReceived', data)
        .then(result => {
          if (result && result.data && result.data.success == true) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"mailbox.read.success.first.half"}) + result.data.data + intl.formatMessage({id:"mailbox.read.success.second.half"})
            });
          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"error.verify.receiving.info"})
            });
          }
        })
        .catch(err => {
          console.log(err)
          console.log("err")
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"error.verify.receiving.info"})
          });
          Api.kickoff(err);
        });

    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"insert.receiving.info"}),
      })
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl' style={{paddingLeft: '10%', paddingRight: '10%', marginLeft:0}}>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{this.props.intl.formatMessage({id:"back"})}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {intl.formatMessage({id:"typography.add.item"})}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>

                <Grid container spacing={2}>
                  <Grid item xs={11} sm={11}>
                    <TextField fullWidth label={intl.formatMessage({ id: 'name' })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.email.name"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "location" })}
                      options={this.state.addessesList}
                      stateName="addressId"
                      changeSelect={this.changeValues}
                      selected={item.addressId} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message="Endereço do chamado" />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "category" })}
                      options={this.state.organizationCategoriesList}
                      stateName="organizationCategoryId"
                      changeSelect={this.changeValues}
                      selected={item.organizationCategoryId} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message="Categoria do chamado" />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "status" })}
                      options={this.state.statusList}
                      stateName="statusId"
                      changeSelect={this.changeValues}
                      selected={item.statusId} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message="Status do chamado" />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "type" })}
                      options={this.state.typesList}
                      stateName="typeId"
                      changeSelect={this.changeValues}
                      selected={item.typeId} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message="Tipo do chamado" />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: 'active' })} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.email.active"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: 'ResponseEmailIsNewCalled' })} name='responseemailisnewcalled' stateName='responseemailisnewcalled' changeSelect={this.changeValues} selected={item.responseemailisnewcalled} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.email.reply.new.called"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: 'AccompanimentCalledForResponseEmail' })} name='accompanimentcalledforresponseemail' stateName='accompanimentcalledforresponseemail' changeSelect={this.changeValues} selected={item.accompanimentcalledforresponseemail} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.email.reply.new.accompaniment"})} />
                  </Grid>
                  <Grid item xs={11} sm={11} style={{ marginTop: '4px' }}>

                  </Grid>
                </Grid>

                <Tabs indicatorColor={""} >
                  <TabList style={{ borderColor: '#BB8FCE' }}>
                    <Tab style={{ fontSize: "14px" }}><FlightTakeoffIcon /> {intl.formatMessage({id:"sending.info"})} </Tab>
                    <Tab style={{ fontSize: "14px" }}><FlightLand /> {intl.formatMessage({id:"receiving.info.imap"})}</Tab>
                  </TabList>

                  <TabPanel >
                    {/*Informações de Envio*/}
                    <Grid container spacing={2}>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({ id: 'server' })} autoComplete='fname' variant='outlined' name='serverSend' onChange={this.handleInputChange} value={item.serverSend} required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.smtp.sending.server"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label="E-mail" autoComplete='fname' variant='outlined' name='fromMailSend' onChange={this.handleInputChange} value={item.fromMailSend} type="email" required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message="Email que será utilizado para enviar mensagens" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({ id: 'port' })} autoComplete='fname' variant='outlined' name='portSend' onChange={this.handleInputChange} value={item.portSend} required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.smtp.port"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <SimpleSelect label={intl.formatMessage({ id: 'crypto' })} options={this.state.CryptoList} stateName='cryptotypeSend' name='cryptotypeSend' changeSelect={this.changeValues} selected={item.cryptotypeSend} />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.smtp.encryption"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({ id: 'user' })} autoComplete='fname' variant='outlined' name='userSend' onChange={this.handleInputChange} value={item.userSend} required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.smtp.user.email"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField type="password" placeholder={intl.formatMessage({id:"leave.empty"})} fullWidth label={intl.formatMessage({ id: 'password' })} autoComplete='fname' variant='outlined' name='passwordSend' onChange={this.handleInputChange} value={item.passwordSend} />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.smtp.user.password"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({id:"config.edit.label.send.test.email"})} autoComplete='fname' variant='outlined' name='emailtest' onChange={this.handleInputChange} value={item.emailtest} />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.test.email"})} />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Button
                          onClick={this.testSendMail}
                          fullWidth
                          variant='contained'
                          color='primary'
                        >
                          {intl.formatMessage({id:"test"})}
                        </Button>
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.test.message.box.info"})} />
                      </Grid>
                    </Grid>
                    {/*Fim Informações de Envio*/}
                  </TabPanel>

                  <TabPanel>
                    {/*Informações de Recebimento*/}
                    <Grid container spacing={2}>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({ id: 'server' })} autoComplete='fname' variant='outlined' name='serverReceived' onChange={this.handleInputChange} value={item.serverReceived} required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.imap.receiving.server"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label="E-mail" autoComplete='fname' variant='outlined' name='fromMailReceived' onChange={this.handleInputChange} value={item.fromMailReceived} type="email" required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message="Email de recebimento" />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({ id: 'port' })} autoComplete='fname' variant='outlined' name='portReceived' onChange={this.handleInputChange} value={item.portReceived} required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.imap.receiving.port"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <SimpleSelect options={this.state.CryptoList} label={intl.formatMessage({ id: 'crypto' })} name='cryptotypeReceived' stateName='cryptotypeReceived' changeSelect={this.changeValues} selected={item.cryptotypeReceived} />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.imap.receiving.encryption"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField fullWidth label={intl.formatMessage({ id: 'user' })} autoComplete='fname' variant='outlined' name='userReceived' onChange={this.handleInputChange} value={item.userReceived} required />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.imap.user"})} />
                      </Grid>
                      <Grid item xs={11} sm={11}>
                        <TextField type="password" placeholder={intl.formatMessage({id:"leave.empty"})} fullWidth label={intl.formatMessage({ id: 'password' })} autoComplete='fname' variant='outlined' name='passwordReceived' onChange={this.handleInputChange} value={item.passwordReceived} />
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.imap.password"})} />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Button
                          onClick={this.testReceivedMail}
                          fullWidth
                          variant='contained'
                          color='primary'
                        >
                          {intl.formatMessage({id:"test"})}
                        </Button>
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <SimpleHelp message={intl.formatMessage({id:"organization.account.email.registration.test.message.box.info"})} />
                      </Grid>
                    </Grid>

                    {/*Fim Informações de Recebimento*/}
                  </TabPanel>

                </Tabs>

                <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {this.props.intl.formatMessage({id:"save"})}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
OrganizationAccountEmailEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizationaccountemails: state.organizationaccountemails,
  organizations: state.organizations,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationAccountEmailEdit)));

