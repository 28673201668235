import React, { useState, useContext, createContext } from 'react'
import Queue from '../utils/queue'

export const LastCalledsQueueContext = createContext()

export const LastCalledsQueueProvider = ({ children }) => {
  
  const [lastCalleds, setLastCalleds] = useState(new Queue())

  const instantiateCalledsQueue = _ => {
    setLastCalleds(new Queue())
  }

  const equeuelastCalledsQueue = (element) => {
    if (lastCalleds.size() === 5)
      lastCalleds.dequeue()
    lastCalleds.equeue(element)
  }

  const clearlastCalledsQueue = _ => {
    lastCalleds.clear()
  }
  
  return (
    <LastCalledsQueueContext.Provider value={{ lastCalledsQueue: lastCalleds, instantiateCalledsQueue, equeuelastCalledsQueue, clearlastCalledsQueue }}>
      {children}
    </LastCalledsQueueContext.Provider>
  )
}

export function useLastCalledsQueueContext() {
  const context = useContext(LastCalledsQueueContext)

  return context
}