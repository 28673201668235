import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { PapiroConsole } from '../utils/papiroConsole'
import CustomizedSnackbars from "../components/material-snackbars";

import { connect } from 'react-redux'
import * as componentActions from '../redux/actions/chats'
import { bindActionCreators } from 'redux'
import Sound from "../assets/audio.wav"

import { GetChatList as GetChatListService } from '../services/2talk/omnichannel';


export const MessageHub2TalkContext = createContext();


const isClient = (userSession) => {
  let isClient = true;
  if (userSession != null) {
    userSession.user.userrole.map(item => {
      if (item.role.roletype != 3) {
        isClient = false
      }
    })

  }


  return isClient
};

//export function MessageHub2TalkProdiver({ children }) {
function MessageHub2TalkProdiver(props) {
  //const userSession = useSelector((state) => state.userSession);
  const chatselector = useSelector((state) => state.chats.chat);

  const [chatHub2Talk, setChatHub2Talk] = useState({});

  const [alertNotification, setAlertNotification] = useState({ variant: "error", isOpen: false, message: "" });
  const closeAlertMessage = () => setAlertNotification({ ...alertNotification, isOpen: false });
  const openAlertMessage = (variant, message) => setAlertNotification({ variant, message, isOpen: true });
  const [error, setError2Talk] = useState(false);

  const setChatHubConnection2Talk = connection => setChatHub2Talk(connection);
  const setErrorMessage2Talk = ErrorMessage => setErrorMessage2Talk(ErrorMessage);
  const [newMessage, setNewMessage] = useState(null);

  const [message, setMessage] = useState([])
/*
  useEffect(() => {

  }, [error]);

  useEffect(() => {
    if (newMessage) {
      alert(JSON.stringify(newMessage));
    }
    return () => setNewMessage(null);
  }, [newMessage])
*/

  useEffect(() => {
    //if (props.chats != null && props.chats.chat != null)
    //setMessage(props.chats.chat)
     //alert('USE EFFECT');
  }, [props.chats]);
  const MessageSent = async (item) => {

  }
  const ReceiveNewChat = async (item) => {
    playNotificationSound();
    if(item != null){
     /// como atualizar daqui a tela de chats de luiz, o dash de chats?
       //alert('Atualizar dash!');
       props.refreshDashboard(true);

        
    }
  }
  const NewItemNotification = async (item) => {
    //alert('NewItemNotification -> PLAY SOUND NOW!!!!!!!');
    playNotificationSound();
    if(item != null){
      //alert('Atualizar dash!!');
      props.refreshDashboard(true);
      /*
        let result = await GetChatListService()
        if (result && result.success) {
            props.addCurrentOmnichannelChatList(result.data);
            props.addTotalChatCount(result.data.length);
        } 
        else {
        }
        */
     }
  }
  
  
  const ReceiveMessage = async (item, userGuid = null , unreadMessages = null) => {

    PapiroConsole.log("entrei aqui 1 ")
    //alert('ReceiveMessage -> PLAY SOUND NOW!!');
    playNotificationSound();
    if(item != null){
        let message = JSON.parse(item)
     
        props.updateLastChatMessageV2(message.chatid, message, unreadMessages)

    }
    return;
   
 }

  const ReceiveNotification = async (item) => {

    PapiroConsole.log("entrei aqui 2 ")
    //alert('ReceiveNotification -> NO SOUND!');
    PapiroConsole.log("=== MessageHub2TalkContext -> ReceiveNotification ===")
    PapiroConsole.log(item)
    //alert('ReceiveNotification -> NO SOUND!');
    if(item != null){
        let message = JSON.parse(item)
        //alert(item);

        props.updateLastChatMessageV2(message.chatid, message)


     }


    /*
    alert(chats.length);

    var formated = {};
    var guid = item.chatMessages[0].guid;
    alert(guid);
    for (var i = 0; i < chats.length; i++) {
      formated[chats[i].id] = chats[i];
      for (var z = 0; z < chats[i].messages.length; z++) {
        //alert(chats[i].messages[z].content+" -> "+chats[i].messages[z].guid+" == "+ guid);
        if(chats[i].messages[z].guid == guid){

          alert('ENCONTREI A MENSAGEM');
        }
      }

    }
      for (var z = 0; z < messages.length; z++) {
        alert(messages[z].content+" -> "+messages[z].guid+" == "+ guid);
        if(messages[z].guid == guid){

          alert('ENCONTREI A MENSAGEM');
        }
      }


    const lastItem = chats.chat.messages[chats.chats.chat.messages.length - 1];
    */
    const lastItem = {};
    return;
    //alert(JSON.stringify(message));

   
 }
  const setNotificationSound2Talk = () => {
    playNotificationSound()
  };

  const playNotificationSound = () => {
    const audio = new Audio(Sound);
    audio.play();
  };


  const ShowError2Talk = (message, selectedChat, senderId) => {
    PapiroConsole.log(message)
    PapiroConsole.log(selectedChat)
    PapiroConsole.log(senderId)
    PapiroConsole.log("erro de mensagens aqui")
    if (message && selectedChat) {
      PapiroConsole.log("SHOW ERROR -> " + message);
      //setErrorMessage(message);
      setAlertNotification({ variant: "error", message: message.toString(), isOpen: true });
      setError2Talk(true);
    }
  };
  
  const CloseChat2Talk = (chatId, userId, chat) => {

    alert('CLOSE');
    
  }  
  

  const InitChat2Talk = (chat, username, organization) => {
    alert('INIT');

  };




  return (
    <MessageHub2TalkContext.Provider value={{
      chatHub2Talk,
      setChatHubConnection2Talk,
      setNotificationSound2Talk,
      ShowError2Talk,
      setErrorMessage2Talk,
      setError2Talk,
      InitChat2Talk,
      CloseChat2Talk,
      ReceiveNotification,
      ReceiveMessage,
      MessageSent,
      NewItemNotification,
      ReceiveNewChat
    }}>
      {props.children}
      <CustomizedSnackbars
        variant={alertNotification.variant}
        message={alertNotification.message}
        isOpen={alertNotification.isOpen}
        toClose={closeAlertMessage}
      />
    </MessageHub2TalkContext.Provider>
  )
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);


export function useMessageHub2TalkContext() {
  const context = useContext(MessageHub2TalkContext);
  return context;
}

export default connect(mapStateToProps, mapDispatchToProps)((MessageHub2TalkProdiver));
