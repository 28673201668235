import React, { Component } from 'react';
import clsx from 'clsx';

import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import { getChartByNpsId } from '../../../services/nps.service'
import { Can } from '../../../config/ability-context'
import { AbilityContext } from '../../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";
import AnalyticsIcon from '@mui/icons-material/Analytics';

import { Avatar } from '@mui/material';
import { QueuePlayNext } from '@mui/icons-material';




import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as componentActions from '../../../redux/actions/npssearchs';



import AddIcon from '@mui/icons-material/Add';
import CustomizedSnackbars from '../../../components/material-snackbars';
import HeaderMenu from '../../../components/header-menu';
import { PapiroConsole } from '../../../utils/papiroConsole';
import PizzaNps from '../../quality-dashboard/components/pizzaNps';

class NpsGraphic extends Component {

    constructor(props) {
        super(props);

        const { npssearchs } = this.props;

        PapiroConsole.log(npssearchs)
        PapiroConsole.log("npssearchs")

        this.state = {
            Id: npssearchs.npssearch.id,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            NpsNoteList: [],

        };

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {

        await this.getNpsClassification(this.state.Id)

    }

    async getNpsClassification(npsId = 0) {


        let result = await getChartByNpsId(npsId)

        if (result && result.success) {
            this.setState({ NpsNoteList: result.data })
        } else {
            const intl = this.props.intl

            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })

            Api.kickoff(result)
        }
    }



    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {

        const intl = this.props.intl;
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        PapiroConsole.log(this.state.NpsNoteList)
        PapiroConsole.log("this.state.NpsNoteList")

        return (

            <Container component='main' maxWidth='lg' style={{ marginTop: '50px' }}>
                <Grid container spacing={3} >
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <Avatar className={classes.avatar}>
                            <AnalyticsIcon />
                        </Avatar>
                        <Typography component='h1' variant='h5'>
                            {<FormattedMessage id="graphic" />}

                        </Typography>

                    </Grid>

                    <Grid item xs={12}>
                        {this.state.NpsNoteList.length > 0 &&
                            <Grid item xs={12} style={{ padding: '2px', minWidth: '320px' }} id="pizza">
                                <PizzaNps data={this.state.NpsNoteList} />
                            </Grid>

                        }

                    </Grid>

                </Grid>


            </Container>

        );
    }
}
NpsGraphic.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    npssearchs: state.npssearchs,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(NpsGraphic)));

NpsGraphic.contextType = AbilityContext;