import React, { Component } from 'react';
import { Grid } from "@mui/material";
import MELikeIcon from "../../../../components/me-feedback-images/like-icon";
import MEUnlikeIcon from "../../../../components/me-feedback-images/unlike-icon";
import { PapiroConsole } from '../../../../utils/papiroConsole';

class ThumbsUpOrDown extends Component {
  constructor(props) {
    super(props);

    let trueOption = null;
    PapiroConsole.log(this.props.fqa)
    PapiroConsole.log("this.props.fqa")
    if(this.props.fqa == 1 && this.props.index == 0 ){
      trueOption = 1
      this.props.changeValuesSurveyQuestions(this.props.index,true )
    }
    else if (this.props.fqa == 0  && this.props.index == 0){
      trueOption = 0
      this.props.changeValuesSurveyQuestions(this.props.index,false )
    }
    // Inicialize o estado do componente
    this.state = {
      trueOption:  trueOption,
    };
  }



  render() {
    return (
      <>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={4}  mt ={3} >
          <p style={{ color: '#09316F' }}><strong>0{this.props.disabledCallRejectionFlag ? this.props.index+1 : this.props.index+2}.</strong>{this.props.title}</p>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={7} xl={8}  mt ={3} >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: !this.props.newlayout ? 'center' : 'start' }} >
            <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.trueOption === 1 ? '#CCC' : '#EEE', borderRight: '1px solid #CCC', cursor: 'pointer' }}
              onClick={() => {
                this.props.changeValuesSurveyQuestions(this.props.index,true )
                this.setState({ trueOption: 1 })
              }}>
              <p style={{ color: '#595959', paddingRight: '10%' }}><strong>SIM</strong></p>
              <MELikeIcon style={{ width: 30 }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.trueOption === 0 ? '#CCC' : '#EEE', borderLeft: '1px solid #CCC', cursor: 'pointer' }}
              onClick={() => {
                this.props.changeValuesSurveyQuestions(this.props.index, false )
                this.setState({ trueOption: 0 })
              }}>
              <p style={{ color: '#595959', paddingRight: '10%' }}><strong>NÃO</strong></p>
              <MEUnlikeIcon style={{ width: 30 }} />
            </div>
          </div>
        </Grid>
      </>
    );
  }
}

export default ThumbsUpOrDown;