import Api2Talk from "../../services/api2talk";
import { PapiroConsole } from "../../utils/papiroConsole";

export const getChannelFromTechinicalTeam = async (id) => {
	let data;
	await Api2Talk.get(`/channel/techinicalteam/${id}`)
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.name
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getTechinicalTeamFromUser = async (id) => {
	let data;
	await Api2Talk.get(`/techinicalteam/user/${id}`)
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.name
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getTechList = async (chatid, channelid, techinicalteamid) => {
	let data;
	await Api2Talk.get(`/channeltechinicalteam/getusers/${chatid}/${channelid}/${techinicalteamid}`)
		.then(result => {
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.name
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getProviderList = async () => {
	let data;
	await Api2Talk.get("/messageprovider")
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.name
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getStatusList = async () => {
	let data;
	await Api2Talk.get("/chatstatus")
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.name,
						colorhex : item.colorhex
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getChatClosingStatus = async () => {
	let data;
	await Api2Talk.get("/chatclosingstatus")
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.name,
						resolved : item.resolved
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getChannelList = async () => {
	let data;
	await Api2Talk.get("/channel")
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.name
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const sendMessage = async (post) => {
	let data;
	//alert(JSON.stringify(post));
	await Api2Talk.post("/messenger/send",post)
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const sendMessageAttachment = async (post, config) => {
	let data;
	//alert(JSON.stringify(post));
	await Api2Talk.post("/messenger/send/attachment", post, config)
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}
export const reSendMessage = async (post) => {
	let data;
	//alert(JSON.stringify(post));
	await Api2Talk.post("/messenger/resend",post)
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}


export const  GetChatList = async (id, filterid, filterchatid) => {
	let data;
	let url = "/chat";
	if(id != null){
		url=url+"/"+id;
	}
	if(filterid && filterchatid ){
		url=url+`?filterid=${filterid}&filterChatId=${filterchatid}`;
	}
	await Api2Talk.get(url)
		.then(result => {
			if (result.data.success) {

			  data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const  getChatIndicators = async ( filterid, filterchatid) => {
	let data;
	let url = "/chat/indicators";
	
	if(filterid && filterchatid ){
		url=url+`?filterid=${filterid}&filterChatId=${filterchatid}`;
	}
	await Api2Talk.get(url)
		.then(result => {
			if (result.data.success) {

			  data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const GetChatListByTech = async (id, page = 1, filters = null) => {
	let data;
	let url = "/chat/technician";
	if(id != null){
		url=url+"/"+id;
	}
	if(page!= null){
		url=url+`?page=${page}`;
	}
	if(filters){
		url=url+`?title=${filters.title}&status=${filters.status}&techinicalteam=${filters.techinicalteam}&channel=${filters.channel}&origin=${filters.origin}`;
	}
	await Api2Talk.get(url)
		.then(result => {
			if (result.data.success) {

			  data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}


export const GetChatToObserve= async (id, page = 1) => {
	let data;
	let url = "/chat/technician/observe";
	if(id != null){
		url=url+"/"+id;
	}
	if(page!= null){
		url=url+`?page=${page}`;
	}
	
	await Api2Talk.get(url)
		.then(result => {
			if (result.data.success) {

			  data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const GetImageMeta = async (id) => {
	let data;
	let url = "/message/image/"+id;
	await Api2Talk.get(url)
		.then(result => {
			if (result.data.success) {
           
			  data= result.data;
			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}


export const getUserStatusList = async () => {
	let data;
	await Api2Talk.get("/userstatus")
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				data = result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getEnabledUsers = async () => {
	let data;
	await Api2Talk.get("/enableusers/getall")
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {
				data = result.data;
			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const getEnabledUserById = async (id) => {
	let data;
	PapiroConsole.log(id)
	PapiroConsole.log("id 2 ")
	await Api2Talk.get(`/enableusers/userid/${id}`)
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				data = result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}