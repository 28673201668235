import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Button, FormControl, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography } from "@mui/material";
import { SketchPicker } from "react-color";
import { PapiroConsole } from "../../../utils/papiroConsole";
import Dropzone from "react-dropzone";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FormattedMessage } from "react-intl";
import { DeleteForever } from "@mui/icons-material";
import Api from '../../../services/api'
import * as Service from '../../../services/emailcustomsettings.service';
import ReactQuill from "react-quill";
import AttachFile from "@mui/icons-material/AttachFile";
import Delete from "@mui/icons-material/Delete";
import * as FileService from '../../../services/attachment.service';
import DialogConfirmationdeleteAttachment from '../../../components/dialog-confirmation-not-button'


const reactQuillModules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        [{ 'size': ['small', false, 'large'] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ],
}

const reactQuillFormats = [
    'header',
    'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

class EmailCustomSettings extends Component {
    constructor(props) {
        super(props)
        const { emailcustomsettings, hirerId } = this.props

        this.state = {
            item: {
                id: emailcustomsettings && emailcustomsettings.id ? emailcustomsettings.id : 0,
                hirerid: emailcustomsettings && emailcustomsettings.hirerid ? emailcustomsettings.hirerid : hirerId,
                logo: emailcustomsettings && emailcustomsettings.logo ? emailcustomsettings.logo : {},
                barfillcolor: emailcustomsettings && emailcustomsettings.barfillcolor ? emailcustomsettings.barfillcolor : "",
                fontcolor: emailcustomsettings && emailcustomsettings.fontcolor ? emailcustomsettings.fontcolor : "",
                signature: emailcustomsettings && emailcustomsettings.signature ? emailcustomsettings.signature : "",
                footertext: emailcustomsettings && emailcustomsettings.footertext ? emailcustomsettings.footertext : "",
                active: emailcustomsettings && emailcustomsettings.active ? emailcustomsettings.active : false,
                attachmentid: emailcustomsettings && emailcustomsettings.attachmentid ? emailcustomsettings.attachmentid : null,
                attachment: emailcustomsettings && emailcustomsettings.attachment ? emailcustomsettings.attachment : null,
            },
            files: [],
        }
    }

    handleChangeColor = (name, color) => {
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: color.hex
            }
        }))
    }

    handleChange = (e) => {
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [e.target.name]: e.target.value
            }
        }))
    }

    removeItem = (file) => {
        let array = [...this.state.files];
        let index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    validateImagesAtachments = (files) => {
        let { intl, setMessage } = this.props

        if (files.length > 1) {
            this.setState({ files: [] })
            setMessage(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
            return
        }
        else {
            this.setState({ files: [...files, ...this.state.files] })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        const { intl, setLoading, setMessage, updateEmailCustomSettings } = this.props
        setLoading(true)
        let emailcustomsettings = this.state.item

        if (emailcustomsettings.hirerid === 0)
            emailcustomsettings.hirerid = this.props.hirerId

        let data = new FormData();

        for (let key in emailcustomsettings) {
            data.append(key, emailcustomsettings[key])
        }
        data.append("configid", this.props.configId)
        if (this.state.files.length > 0) {
            this.state.files.forEach(file => data.append('filesUpload', file))
        }

        let isEdit = emailcustomsettings.id === 0 ? false : true

        let response = isEdit ? await Service.editItem(data) : await Service.addItem(data)

        if (response && response.success) {
            setLoading(false)
            setMessage(true, 'success', intl.formatMessage({ id: isEdit ? "edit.success" : "add.success" }))
            if (response.data && response.data.id != null && typeof response.data.id !== 'undefined') {
                updateEmailCustomSettings(response.data)
                this.setState({ item: response.data, files: [] })
            }
        } else {
            setLoading(false)
            setMessage(true, 'error', response)
            Api.kickoff(response)
        }
    }

    handleChangeText(name, value) {
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }))
    }

    handleChangeSwitch = (e) => {
        let { name, checked } = e.target

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: checked
            }
        }))
    }

    getFile = async (hirerid, fileName, filerepositorymethod) => {
        let { intl, setLoading, setMessage } = this.props
        setLoading(true)

        let url = await FileService.getFileByPath(hirerid, fileName, filerepositorymethod);
        if (url && url.data && url.data.data && url.data.success) {
            setLoading(false)
            window.open(`${url.data.data}`, '_blank')
        } else {
            setLoading(false)
            setMessage(true, 'error', intl.formatMessage({ id: "file.not.found" }))
        }
    }

    getFileName = (fileName) => {
        let file = fileName != null ? fileName.substring(fileName.lastIndexOf('/') + 1) : "";
        return file;
    }

    deleteLogoImage = async () => {
        const { setLoading, setMessage, intl, updateEmailCustomSettings } = this.props
        setLoading(true)
        const result = await Service.deleteLogoImage(this.state.item.id)
        if (result.success) {
            const item = {
                ...this.state.item,
                attachment: null,
            }
            this.setState({ item: item })
            updateEmailCustomSettings(item)
            setMessage(true, 'success', intl.formatMessage({ id: "delete.sucess" }))

        } else {
            setMessage(true, 'error', result != null ? result : intl.formatMessage({ id: "process.error" }))
        }
        this.setState({ deleteAttachment: false })
        setLoading(false)
    }

    render() {
        const { item } = this.state
        PapiroConsole.log(item)
        PapiroConsole.log("item")
        const { intl, classes } = this.props

        return (
            <div>
                <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={12} spacing={2} direction='row' justifyContent='space-around'>
                            <Grid item container xs={12} sm={6} alignItems='center' direction='column'>
                                <Grid item>
                                    <label>
                                        {intl.formatMessage({ id: "bar.fillcolor" })}
                                    </label>
                                </Grid>
                                <Grid item>
                                    <SketchPicker
                                        color={item.barfillcolor}
                                        onChange={(value) => this.handleChangeColor('barfillcolor', value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sm={6} alignItems='center' direction='column'>
                                <Grid item>
                                    <label>
                                        {intl.formatMessage({ id: "fontcolor" })}
                                    </label>
                                </Grid>
                                <Grid item>
                                    <SketchPicker
                                        color={item.fontcolor}
                                        onChange={(value) => this.handleChangeColor('fontcolor', value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} marginTop={2} paddingBottom={5}>
                            <FormattedMessage id="mail.signature" />
                            <ReactQuill
                                value={item.signature || ""}
                                name="signature" style={{ height: 200 }} modules={reactQuillModules} formats={reactQuillFormats}
                                fullWidth
                                required
                                variant="outlined"
                                id="signature"
                                onChange={value => this.handleChangeText("signature", value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} paddingBottom={5}>
                            <FormattedMessage id="whitelabelconfig.footer.text" />
                            <ReactQuill
                                value={item.footertext || ""}
                                name="footertext" style={{ height: 200 }} modules={reactQuillModules} formats={reactQuillFormats}
                                fullWidth
                                required
                                variant="outlined"
                                id="footertext"
                                onChange={value => this.handleChangeText("footertext", value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Logomarca</Typography>
                            <div className={classes.dropzoneArea} >
                                <Dropzone onDrop={this.validateImagesAtachments}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <AttachFileIcon />
                                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                <FormattedMessage id="drag.and.drop" />
                                            </Typography>
                                        </div>
                                    )}
                                </Dropzone>
                                {this.state.files.map(file => {
                                    return (
                                        <div className={classes.dropzoneItem} key={file.name}>
                                            {file.name}
                                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </Grid>
                        {item.attachment != null && (
                            <Grid item xs={12} sm={12}>
                                <label>Anexo</label>
                                <ul>
                                    <li key={item.attachment.path}>
                                        <Grid container alignItems='center'>
                                            <Grid item sm={.5}>
                                                <AttachFile />
                                            </Grid>
                                            <Grid item sm={10.5}>
                                                <Button onClick={() => this.getFile(item.attachment.hirerid, item.attachment.path, item.attachment.filerepositorymethod)}>
                                                    {this.getFileName(item.attachment.path)}
                                                </Button>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <Button color='inherit' onClick={_ => this.setState({ deleteAttachment: true })}>
                                                    <Delete />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={item.active}
                                                onChange={this.handleChangeSwitch}
                                                name="active" />
                                        }
                                        label={intl.formatMessage({ id: "active" })} />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                                <Grid item xs={12} sm={4} >
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        olor='primary'
                                        className={classes.submit} >
                                        {<FormattedMessage id="save" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                {this.state.deleteAttachment && (
                    <DialogConfirmationdeleteAttachment
                        msgButton={intl.formatMessage({ id: "confirm" })}
                        msg={intl.formatMessage({ id: "delete.logo.image" })}
                        msgDisagree={intl.formatMessage({ id: "cancel" })}
                        msgAgree={intl.formatMessage({ id: "yes" })}
                        handleConfirmation={_ => this.deleteLogoImage()}
                        handleClose={_ => this.setState({ deleteAttachment: false })}
                        classes={classes}
                        disabled={false}
                        icon={<Delete />}
                    />
                )}
            </div>
        )
    }
}

EmailCustomSettings.prototypes = {
    hirerId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    emailcustomsettings: PropTypes.object.isRequired,
}

export default connect()(EmailCustomSettings)