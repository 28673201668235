import React, { memo } from 'react'
import { Avatar, Grid, Button } from "@mui/material";
import './styles.css'
import { injectIntl } from 'react-intl';
import moment from 'moment';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import BadgeIcon from '@mui/icons-material/Badge';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import ChatIcon from '@mui/icons-material/Chat';

import InfoTooltip from './components/info-tooltip';
import InfoAccordion from './components/info-accordion';

export const ItemCard = memo(function ItemCard(props) {
  console.log(props)
  console.log("props aqui 1 ")





  return (
    <Grid item xs={12} className='chatItem' style={{ cursor: props.name == "chatsInAttendance" ? 'pointer' : 'default' }} onClick={props.name == "chatsInAttendance" ? (e) => props.updateChat(e,props.chats.id) : null} >

      <Grid container spacing={0} >

        <Grid item xs={4} >
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <Avatar alt={props.chats.customer ? props.chats.customer.name : ''}>
                {props.chats.customer ? props.chats.customer.name.charAt(0) : ''}
              </Avatar>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <span> {props.chats.customer ? props.chats.customer.name : ''}</span>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3} className='mainContent' >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'  }}>

            <span className='boldLine-two'> <BadgeIcon /> ID {props.chats.id} {props.chats.organizationname ? ' | ' + props.chats.organizationname : ''}  </span>
           
            {props.chats.assignedusername &&
              < span style={{ fontSize: '1.1em' }}>  <PersonIcon />Técnico : {props.chats.assignedusername} </span>
            }
            < span style={{ fontSize: '1.1em' }}>  <ConnectWithoutContactIcon />{props.chats.channelid && props.chats.channelname ? "Canal: " + props.chats.channelname : null } </span>
            <span style={{ fontSize: '1.1em' }}>  
            {(props.chats.messageproviderid == 2) && <WhatsAppIcon sx={{ color: '#59BC6B' }}  />}
            {(props.chats.messageproviderid == 3) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
              <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
            </svg>}
            {(props.chats.messageproviderid == 4) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
              <path fill="#448AFF" d="M24,4C13.5,4,5,12.1,5,22c0,5.2,2.3,9.8,6,13.1V44l7.8-4.7c1.6,0.4,3.4,0.7,5.2,0.7c10.5,0,19-8.1,19-18C43,12.1,34.5,4,24,4z"></path><path fill="#FFF" d="M12 28L22 17 27 22 36 17 26 28 21 23z"></path>
            </svg>}
            {(props.chats.messageproviderid == 5) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
              <path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"></path><path fill="#b0bec5" d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"></path><path fill="#cfd8dc" d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"></path>
            </svg>}
            {(props.chats.messageproviderid == 6) && <ChatIcon sx={{ color: '#277afe' }}  />}
            
            
             Provedor: {props.chats.messageprovider ? props.chats.messageprovider.name : '' + ' | ' }   {props.chats.messageproviderid == 2 ? ' | '+props.chats.senderid : null}  </span>
            
             <span style={{ fontSize: '1.1em' }}>
             {props.intl.formatMessage({ id: "open.date" })}: {moment(props.chats.createdat).format('DD/MM/YYYY HH:mm')} 
              </span>        

           

          </div>

        </Grid>
        <Grid item xs={2} className='mainContent' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'  }}  >
         <span> <InfoTooltip messageProviderId={props.chats.messageproviderid} phoneNumber={props.chats.registrationnumberrequestphonenumber}  phoneNumberId ={props.chats.registrationnumberrequestphonenumberid} accountId = {props.chats.registrationnumberrequestaccountid} registrationNumberRequestMessageProviderConfigAccountSid = {props.chats.registrationnumberrequestmessageproviderconfigaccountsid} webchatHash = {props.chats.webchathash}  webchatAccountId={props.chats.webchataccountid} webchatActive={props.chats.webchatactive} webchatIsDefault={props.chats.webchatisdefault} webchatTitle={props.chats.webchattitle} /> </span>
        </Grid>
       
        <Grid item xs={3} className='mainContent' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'  }} >


          {props.name == "myChatsInAttendance"  &&

            <Button
              variant='contained'
              type="button"
              onClick={(e) => props.updateChat(e,props.chats.id)}
            >
              {props.intl.formatMessage({ id: "enter" })}
            </Button>
          }

          {props.name == "otherChatsInAttendance"  &&

            <Button
              variant='contained'
              type="button"
              //parâmetro de observar como true 
              onClick={(e) => props.updateChat(e,props.chats.id, true)}
            >
              {props.intl.formatMessage({ id: "observe" })}
            </Button>
          }

          {props.name == "chatsWaitingAttendance" &&

            <Button
              variant='contained'
              type="button"
              onClick={(e) => props.updateChat(e,props.chats.id)}
            >
              {props.intl.formatMessage({ id: "answer" })}
            </Button>
          }

            

        </Grid>


      </Grid>
    </Grid >
  )

})

export default injectIntl(ItemCard);