import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Service from '../../services/segment.service';
import * as componentActions from '../../redux/actions/npssearchs';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear'
import Title from '../../components/title-name';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { getOrg } from "../../services/organization.service";
import { DataGrid, GridToolbarFilterButton  } from '@mui/x-data-grid';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import SimpleCheckBox from '../../components/checkbox/check';
import DialogAddMultiple from '../../components/dialog-add-multiple';

const NoDataRows = _ => {
    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
    )
  }
class NpsSearchEditContent extends Component {

  constructor(props) {
    super(props);
            const { userSession,npssearchs } = this.props;

          
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }
  /*  if(!npssearchs || (npssearchs && !npssearchs.npsSearch)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }*/

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );


 
    /*this.initialState = {
      updatedUser: userSession.user.id,
       id: npssearchs.npsSearch.id,
     name : npssearchs.npsSearch.name, 

     };     */

     //console.log(npssearchs.npssearch.organizationnpses)
     //console.log("teste aqui")
     var array = []

     if(npssearchs.npssearch.organizationnpses){

        npssearchs.npssearch.organizationnpses.forEach(element => {

          if(element.organization!= null){
                  array.push(element.organization)
          }
          
      });

     }
     

     
     this.initialState = {
        createdUser: userSession.user.id,
        createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
        npstitle : npssearchs.npssearch.npstitle, 
        npsdescription : npssearchs.npssearch.npsdescription,
        active : npssearchs.npssearch.active,
        OrgListsId: [...array],
        dateEnd: npssearchs.npssearch.searchenddate,
        Id : npssearchs.npssearch.id
        
       };    
    this.state = {
        item: this.initialState,
        files: [],
        loading: false,
        openNotification: false,
        notificationVariant: 'error',
        notificationMessage: '',
        OrgListForDelete: "",
        OrgListForSelect: [],
        OrgList: [...array],
        OrgListSelect: [],
        openOrgInsertModal: false,
        openModalSendMail: false,

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

    

                    componentDidMount() {
                      this.getOrganizations();
     };


     handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
    
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
      };

      async getOrganizations() {
        this.setState({ loading: true });
        var result = await getOrg();
       
        if (result.success) {
         
          this.setState({ loading: false, OrgListForSelect: result.data });
        } else {

       
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.response && result.response.data && result.response.data.errors
                ? result.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
    
          Api.kickoff(result);
        }
      }
    
      handleSubmit = async e => {
        e.preventDefault();
    
        this.setState({ loading: true });
    
        var returnReponse = this.checkValidations();
    
        if(returnReponse == true){
    
          let data = new FormData();
    
          if (this.isClient) {
            this.setState(prevState => ({
              item: {
                ...prevState.item,
              }
            }));
          } else {
            this.setState(prevState => ({
              item: {
                ...prevState.item,
              }
            }));
          }
          let idsStringOrg = "";
          
          if (this.state.item.OrgListsId.length > 0) {
            this.state.item.OrgListsId.forEach(
              (organization) => (idsStringOrg += organization.id + ",")
            );
          }
          
          var newStr = idsStringOrg.slice(0, -1);
         
          data.append("Organizations", newStr);
    
          for (let key in this.state.item) {
            if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
              data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
            } else {
              data.append(key, this.state.item[key]);
            }
          }
          

         
    
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          };
            Api.put('/nps', data, config)
    
            .then(result => {
              if (result.data.success) {

                
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({id:"add.success"}),
                  
                });
    
    
              }
              else{
    
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(result);
    
              }          
            })
            .catch(err => {
              if(err.response && err.response.data && err.response.data.errors){
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
              }
              else{
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    
                });
              }
            });
    
    
        }
    
        
      };


      sendMailNps = async e => {
        e.preventDefault();
    
        this.setState({ loading: true });
    
        var returnReponse = this.checkValidations();
    
        if(returnReponse == true){
    
          let data = new FormData();
    
          if (this.isClient) {
            this.setState(prevState => ({
              item: {
                ...prevState.item,
              }
            }));
          } else {
            this.setState(prevState => ({
              item: {
                ...prevState.item,
              }
            }));
          }
          let idsStringOrg = "";
         
          if (this.state.item.OrgListsId.length > 0) {
            this.state.item.OrgListsId.forEach(
              (organization) => (idsStringOrg += organization.id + ",")
            );
          }
          
          var newStr = idsStringOrg.slice(0, -1);
         
          data.append("Organizations", newStr);
    
          for (let key in this.state.item) {
            if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
              data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
            } else {
              data.append(key, this.state.item[key]);
            }
          }
          

      
    
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          };
            Api.put('/nps/send-mail-nps', data, config)
    
            .then(result => {
              if (result.data.success) {

              
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({id:"add.success"}),
                  openModalSendMail: false
                });
    
    
              }
              else{
    
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(result);
    
              }          
            })
            .catch(err => {
              if(err.response && err.response.data && err.response.data.errors){
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
              }
              else{
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    
                });
              }
            });
    
    
        }
    
        
      };
    
      checkValidations() {
        
        
        if(moment(this.state.item.dateEnd).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')){

    
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "O campo data não pode ser menor ou igual que a data atual  "
    
          });
    
          return false
          
        }
        if(this.state.item.OrgListsId == null ||  this.state.item.OrgListsId.length == 0 ){
    
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "O campo selecione as organizações é obrigatório "
    
          });
    
          return false
          
        }
       
        return true
    
        
      }
    
      closeNotification() {
        this.setState({ openNotification: false });
      }
    
      openErrorNotification(){
        const intl = this.props.intl;
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"select.user"})
        })
    
      }
    
      changeValues(stateName, value, text = '') {
        this.setState({ loading: true });
    
    
        if(stateName == "dateEnd"){
            this.setState(prevState => ({
                item: {
                  ...prevState.item,
                  [stateName]: moment(value).format('YYYY/MM/DD HH:mm:ss')
                }
              })); 
        }
    
        
        else{
    
            this.setState(prevState => ({
                item: {
                  ...prevState.item,
                  [stateName]: value
                }
              })); 
    
        }
    
        
        this.setState({ loading: false });
    
      };
    
      async getOrganizations() {
        this.setState({ loading: true });
        var result = await getOrg();
        if (result.success) {
          this.setState({ loading: false, OrgListForSelect: result.data });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.response && result.response.data && result.response.data.errors
                ? result.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
    
          Api.kickoff(result);
        }
      }
    
      saveOrgInsertModal = async () => {
    
        const intl = this.props.intl;
    
     
        this.setState(prevState => ({
            loading: false,
            OrgList: this.state.item.OrganizationId,
            item: {
                ...prevState.item,
                OrgListsId: this.state.item.OrganizationId,
            },
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            openOrgInsertModal: !this.state.openOrgInsertModal,
           
          }));
       
    
      }
    
      openOrgInsertModal = async () => {
        this.setState({
          openOrgInsertModal: !this.state.openOrgInsertModal
        })
      }

      openModalSendMail = async () => {
        this.setState({
          openModalSendMail: !this.state.openModalSendMail
        })
      }

      
    
      setOrgDelete = async (org) => {
        this.setState({
          OrgListForDelete: org + "," + this.state.OrgListForDelete
        })
      }
    
      orgDeleteModal = async () => {
        const intl = this.props.intl;
        if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {
    
          this.setState({ loading: true })
    
          var orgList = [];
          var orgDeleteList = this.state.OrgListForDelete.split(",");
    
          orgDeleteList.map(organizationDelete => {
            if(organizationDelete != ""){
              let returnok = this.state.OrgList.filter( el => el.id != organizationDelete)
              returnok.map(item => {
                if(orgList.filter(el => el.id != item.id )){
                  orgList.push(item);
                }
              })
            }
          })
          
          const intl = this.props.intl;
          this.setState({
            loading: false,
            OrgList: orgList,
            item: {
                OrgListsId: this.state.item.OrganizationId,
              },
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          })
    
          this.setState(prevState => ({
            loading: false,
            OrgList: orgList,
            item: {
                ...prevState.item,
                OrgListsId: this.state.item.OrganizationId,
              },
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
           
          }));
    
        } else {
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({id:"organization.material.registration.select.organization"})
          })
        }
      }
    
      removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ files: array });
        }
      }

    render() {
      
    const { classes, headerMenu, npssearchs } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root} style={{marginTop:'35px'}}>
   

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />} {<FormattedMessage id="nps.search" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='npstitle' onChange={this.handleInputChange} value={item.npstitle} required />
                    </Grid>      
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"description"})} autoComplete='fname' variant='outlined' name='npsdescription' onChange={this.handleInputChange} value={item.npsdescription} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Accordion defaultExpanded ={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>{intl.formatMessage({id:"select.organizations"})}</Typography>
                        </AccordionSummary>
                            <AccordionDetails>
                                <Grid container xs={12} sm={12} >
                                  <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                                      <Grid container spacing={0}>
                                        <Grid xs={12} sm={4} ></Grid>
                                        <Grid xs={12} sm={4} style={{ paddingRight: 15 }}>
                                          <DialogConfirmation
                                              msgButton={intl.formatMessage({id:"organization.material.registration.remove.organization"})}
                                              msg={intl.formatMessage({id:"organization.material.registration.remove.organization"})}
                                              msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                                              msgAgree={this.props.intl.formatMessage({id:"yes"})}
                                              handleConfirmation={e => this.orgDeleteModal()}
                                              classes={classes}
                                              icon={<DeleteIcon />}
                                              //userListForDelete={this.state.UserListForDelete}
                                              //openErrorNotification={ () => this.openErrorNotification()}
                                          />
                                        </Grid>
                                        <Grid xs={12} sm={4} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.button}
                                            startIcon={<AddIcon />}
                                            onClick={e => this.openOrgInsertModal()} >
                                            {intl.formatMessage({id:"organization.material.registration.add.organization"})}
                                        </Button>
                                        </Grid>
                                      </Grid>
                                  </Grid>

                                  <Grid xs={12} sm={12}
                                      style={{
                                      marginLeft: 5,
                                      marginBottom: 5,
                                      border: '3px solid #eee'
                                      }} >
                                      <div style={{ height: 350 }}>
                                      <DataGrid
                                          style={{ fontSize: 14, color: '#000' }}
                                          className="DataGridStyle"
                                          components={{
                                          NoRowsOverlay: NoDataRows,
                                          Toolbar: GridToolbarFilterButton,
                                          }}
                                          rows={this.state.OrgList}
                                          rowsPerPageOptions={[5]}
                                          checkboxSelection
                                          onSelectionModelChange={itm => {
                                          if (itm.length > 0) {
                                              this.setOrgDelete(itm);
                                          } else {
                                              this.setState({
                                              OrgListForDelete: [],
                                              })
                                          }
                                          }}
                                          columns={[
                                          { headerName: this.props.intl.formatMessage({id:"menu.organization"}), field: 'name', width: 500 },
                                          ]}
                                          HeadersVisibility="None"
                                      />
                                      </div>
                                  </Grid>
                                
                                </Grid>
                                    
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={item.dateEnd} required />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <SimpleCheckBox label={intl.formatMessage({id:"active"})} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                    </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
                  <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                    <Grid item xs={12} sm={4} style={{ paddingRight: 15 }}>
                        <Button 
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                          style={{marginRight:'8px'}}
                          onClick={e => this.openModalSendMail()} 
                        >
                          {intl.formatMessage({id:"send.email"})}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}   >
                        <Button 
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>

          <DialogAddMultiple
              title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
              selected={this.state.item.OrganizationId}
              options={this.state.OrgListForSelect}
              checked={this.state.selectOrganizations}
              changeValues={this.changeValues}
              open={this.state.openOrgInsertModal}
              close={this.closeOrgModal}
              save={this.saveOrgInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
              checkboxStateName='selectOrganizations'
              autoCompleteLabel={intl.formatMessage({ id: "organization" })}
              autoCompleteStateName="OrganizationId"
            />
          
            <Dialog
              fullWidth
              maxWidth="sm"
              open={this.state.openModalSendMail}
              onClose={this.closeModalSendMail}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }} >
              <DialogTitle id="form-dialog-title-org-mat">
                Envio de email NPS
                <ClearIcon onClick={this.openModalSendMail} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Ao fazer o envio de email você irá salvar todas as informações alteradas e o nps será ativado. 
                  </DialogContentText>
              </DialogContent>
              <Grid spacing={2} container justifyContent="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    type="button"
                    onClick={this.openModalSendMail}
                    
                    fullWidth >
                    {this.props.intl.formatMessage({id:"cancel"})}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.sendMailNps}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
                
              </Grid>
            </Dialog>
                       
      </div>
    );
  }
}
NpsSearchEditContent.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  npssearchs: state.npssearchs,
});

const mapDispatchToProps = dispatch =>
bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(NpsSearchEditContent)));
