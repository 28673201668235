import Api from './api';

export const getChart = async (dateStart, dateEnd, organizationId) => { 
  let data = [];
  // await Api.get(`/nps/chart/query?&dateStart=${dateStart}&dateEnd=${dateEnd}&organizationId=${organizationId}`)
  await Api.get(`/calledclassifications/chart/query/nps?&dateStart=${dateStart}&dateEnd=${dateEnd}&organizationId=${organizationId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  return data;
};

export const getChartByNpsId = async (npsId) => { 
  let data = [];
  await Api.get(`/nps/chart/query/dapper?npsId=${npsId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  return data;
};
