import { PapiroConsole } from "../utils/papiroConsole";
import Api from "./api";
import ApiReports from "./apireport";

export const getTypes = async () => {
  let data = [];
  await Api.get("/types")
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getReportData = async (formData, config) => {
  let data = [];
  await Api.post("/calleds/getreportdata", formData, config)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getOrgPatrimony = async (id) => {
  let data = [];
  await Api.get(`/calledorganizationpatrimonies/called/${id}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getPatrimonyByOrg = async (id) => {
  let data = [];
  await Api.get(`/organizationpatrimonies/organization/${id}`)
    .then((result) => {
      // console.log(result);
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getSLAs = async () => {
  let data = [];
  await Api.get("/slas")
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getCategories = async () => {
  let data = [];
  await Api.get("/categories/childrens")
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getTypeById = async (id, orgid) => {
  let data = [];
  await Api.get(`/organizationcategories/categoryorg/${id}/${orgid}`)
    .then((result) => {
      data = result.data;
      // console.log("o resultado result");
      // console.log(data);
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getAllCategoriesWithParent = async () => {
  let data = [];
  await Api.get("/categories/parent")
    .then((result) => {
      // console.log(result.data);
      // data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getCategoriesByOrganization = async (organizationId) => {
  let data = [];
  await Api.get("/organizationcategories/organizations/" + organizationId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getRootCategoriesByOrganization = async (organizationId) => {
  let data = [];
  await Api.get("/organizationcategories/rootcategories/organizations/" + organizationId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getByParentOrganizationCategoryId = async (parentId) => {
  let data = [];
  await Api.get("/organizationcategories/parent/" + parentId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getCategoryParentByChildrenOrganizationCategoryId = async (childrenId) => {
  let data = [];
  await Api.get("/organizationcategories/parentbychildren/" + childrenId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getCategoriesByOrganizationSimple = async (organizationId) => {
  let data = [];
  await Api.get("/organizationcategories/simple/organizations/" + organizationId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getOrganizationCategoryDepartaments = async (organizationCategoryId) => {
  let data = [];
  await Api.get("/organizationcategoriedepartaments/organizationcategory/" + organizationCategoryId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getParentCalled = async (organizationid) => {
  let data = [];
  await Api.get("/calleds/"+organizationid)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getParentCalledById = async (id) => {
  let data = [];
  await Api.get(`/calleds/calledinfo/${id}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getCalledGridById = async (id) => {
  let data = [];
  await Api.get(`/calleds/calledgrid/${id}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const calledExists = async (id) => {
  let data = [];
  await Api.get(`/calleds/exists/${id}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const searchById = async (id) => {
  let data = [];
  await Api.get(`/performance/calleds/search/${id}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const changeCalledOrganization = async (formData) => {
  let data = []
  await Api.post("/performance/calleds/change-organization", formData)
    .then((result) => {
      data = result.data
    })
    .catch((err) => {
      data = err
    })
  return data
}

export const consultById = async (id) => {
  let data = [];
  await Api.get(`/performance/calleds/consult/${id}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const consultToChangeOrganizationById = async (id) => {
  let data = [];
  await Api.get(`/performance/calleds/consult/changeorganization/${id}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const addLastCalledsQueue = async (formData) => {
  let data = [];
  await Api.post(`/performance/calleds/lastcalleds`, formData)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getLastCalledsQueueByUserId = async (userId) => {
  let data = [];
  await Api.get(`/performance/calleds/lastcalleds/user/${userId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getAllCategories = async () => {
  let data = [];
  await Api.get("/categories")
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getOrganizations = async () => {
  let data = [];
  await Api.get("/organizations/tech")
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getOrganizationsSimple = async () => {
  let data = [];
  await Api.get("/organizations/new/simple")
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};



export const getOrganizationsByRole = async () => {
  let data = [];
  await Api.get("/organizations/tech")
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};


export const getDepartaments = async (urlids) => {
  let data = [];
  await Api.get(`/organizationdepartaments/getDepartmentsByRole?ids=${urlids}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getOrganizationsByCategory = async (categoryId) => {
  let data = [];
  await Api.get(`/organizations/category/${categoryId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getRequestingUsers = async (organizationId) => {
  let data = [];
  await Api.get(`/users/list/selectionmodel/clients/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getRequestingUsersByLocation = async (organizationId, locationid) => {
  let data = [];
  await Api.get(`/users/list/selectionmodel/clients/${organizationId}/${locationid}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const migrateResm = async (calledId) => {
  let data = [];
  await Api.get(`/calleds/calledorganizationmaterial/migrateresm/${calledId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getTechinicalTeamsByOrganizationIdAndOrganizationCategoryId = async (organizationId, organizationCategoryId) => {
  let data = [];
  await Api.get(`/techinicalteams/organization/${organizationId}/organizationcategory/${organizationCategoryId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getTechnicalUsersByTechnicalTeamIdAndRoleType = async (techinicalTeamId, roleType) => {
  let data = [];
  await Api.get(`/techinicalteamusers/technicalteam/${techinicalTeamId}/roletype/${roleType}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getTechnicalUsers = async (categoryId, organizationId) => {
  let data = [];
  await Api.get(`/users/category/${categoryId}/organization/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getTechnicalUsersByOrganizationId = async organizationId => {
  let data = [];
  await Api.get(`/users/techinical/organization/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getCalledsByFiltering = async ({ dateStart, dateEnd, ...params}) => {
  let data = [];
  //const url = `/calledreports/new/filter?dateStart=${dateStart}&dateEnd=${dateEnd}`;
  const url = `/report/calledclassification/paginate?dateStart=${dateStart}&dateEnd=${dateEnd}`;
  let filterParams = '';

  Object.keys(params).forEach(key => {
    filterParams += `&${key}=${params[key]}`
  });

  await ApiReports.get(`${url}${filterParams}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getChatsClassificationByFiltering = async ({ dateStart, dateEnd, ...params}) => {
  let data = [];

  const url = `/report/chatclassification/paginate?dateStart=${dateStart}&dateEnd=${dateEnd}`;
  let filterParams = '';

  Object.keys(params).forEach(key => {
    filterParams += `&${key}=${params[key]}`
  });

  await ApiReports.get(`${url}${filterParams}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getCalledsByFilteringExcellOrPdf = async ({ dateStart, dateEnd, ...params}) => {
  let data = [];
  const url = `/report/calledclassification/excel?dateStart=${dateStart}&dateEnd=${dateEnd}`;
  let filterParams = '';

  Object.keys(params).forEach(key => {
    filterParams += `&${key}=${params[key]}`
  });

  await ApiReports.get(`${url}${filterParams}`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};


export const getUsersExceptClients = async (organizationId) => {
  let data = [];
  await Api.get(`/users/listAllExceptClients/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getOrganizationAddresses = async (organizationId) => {
  let data = [];
  await Api.get(`/organizations/addresses/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getUserAddresses = async (userId) => {
  let data = [];
  await Api.get(`/addresses/user/${userId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};


export const checkifUserCanAcessCalled = async (calledId) => {
  let data = [];
  PapiroConsole.log("numero do chamado aqui")
  PapiroConsole.log(calledId)
  await Api.get(`/calleds/checkifusercanacesscalled/${calledId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getClientsByOrganizationId  = async (organizationId) => {
  let data = [];
  await Api.get(`/users/list/selectionmodel/clients/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const UsersTechReportList = async (organizationId) => {
  let data = [];
  await Api.get(`/users/getuserstechreportlist/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const UsersTechReportListNew = async (organizationId) => {
  let data = [];
  await Api.get(`/users/getuserstechreportlistnew/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};



export const getOrganizationAddressesByUser = async (userid) => {
  let data = [];
  await Api.get(`/organizations/addresses/user/${userid}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};


export const getOrganizationDepartaments = async (organizationId) => {
  let data = [];
  
  await Api.get(`/organizations/organizationDepartaments/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const GetDepartamentsByOrgAndUser = async (organizationId) => {
  let data = [];
  
  await Api.get(`/organizations/GetDepartamentsByOrgAndUser/${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};



export const getStatus = async (id = 0) => {
  let data = [];
  if (id == 0) {
    await Api.get("/statuses")
      .then((result) => {
        data = result.data;
      })
      .catch((err) => {
        data = err;
      });
    return data;
  } else {
    await Api.get(`/statuses/${id}`)
      .then((result) => {
        data = result.data;
      })
      .catch((err) => {
        data = err;
      });
    return data;
  }
};
export const getCategoryOrganizationDefault = async (organizationId) => {
  let data = [];
  await Api.get("/organizationcategories/default/" + organizationId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getByCalledIdAndHistoryCheckType = async (calledId, historyCheckTypeId) => {
  let data = [];
  await Api.get(`/calledhistorycheck/called/${calledId}/historychecktype/${historyCheckTypeId}`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getByAccompanimentIdAndHistoryCheckType = async (accompanimentId, historyCheckTypeId) => {
  let data = [];
  await Api.get(`/calledhistorycheck/accompaniment/${accompanimentId}/historychecktype/${historyCheckTypeId}`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getAllAccompanimentByCalledIdOrderByDesc = async (calledId) => {
  let data = [];
  await Api.get(`/calledhistorycheck/called/${calledId}/desc`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};

export const getWithAccompanimentById = async (id) => {
  let data = [];
  await Api.get(`/calledhistorycheck/withaccompaniment/${id}`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getWithUserByCalledIdAndType = async (id, type) => {
  let data = [];
  await Api.get(`/calledhistorycheck/withuser/called/${id}/type/${type}`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getAccompanimentsToFinish = async (calledId, requestingUserId) => {
  let data = [];
  await Api.get(`/accompaniments/finish/called/${calledId}/notrequestinguser/${requestingUserId}/public`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getAllByCalledIdGroupByAttendance = async (calledId) => {
  let data = [];
  await Api.get(`/calledhistorycheck/called/${calledId}/attendance`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getCalledDescriptionById = async (id) => {
  let data = [];
  await Api.get(`/calleds/getdescription/${id}`)
    .then((result) => {
      data = result;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getMontlySLADashboardData = async (startDate, endDate, organizationId) => {

  if(organizationId == null || organizationId == '')
    organizationId = 0

  let data = [];
  await Api.get(`/calleds/dashboard/montlysla?dateStart=${startDate}&dateEnd=${endDate}&organizationId=${organizationId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getTechnicalAttendanceRank = async (startDate, endDate, organizationId, technicalTeamId, userId) => {

  if(organizationId == null || organizationId == '')
    organizationId = 0

  let data = [];
  await Api.get(`/calleds/dashboard/technicalrank?dateStart=${startDate}&dateEnd=${endDate}&organizationId=${organizationId}&technicalTeamId=${technicalTeamId}&userId=${userId}`)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
}

export const getFile = async (hirerid,calledid,filename,filerepositorymethod) => {
	let data = [];
	await Api.get(`/calleds/file/${hirerid}/${calledid}?path=${filename}&filerepositorymethod=${filerepositorymethod}`)
	  .then((result) => {
		data = result;
	  })
	  .catch((err) => {
		data = err;
	  });
	return data;
  };

  export const GetProviderNameByCalledId = async (id) => {
    let data = [];
    await Api.get(`/calleds/providernamebycalledid/${id}`)
      .then((result) => {
        data = result.data;
      })
      .catch((err) => {
        data = err;
      });
    return data;
  }