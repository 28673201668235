export function addCurrentOmnichannelChatList(omnichannelchats) {
  return {
      type: 'ADD_CURRENT_OMNICHANNEL_CHAT_LIST',
      omnichannelchats,
  }
}

export function addCurrentChatList(chats) {
  return {
      type: 'ADD_CURRENT_CHAT_LIST',
      chats,
  }
}

export function addCurrentChat(chat) {
  return {
      type: 'ADD_CURRENT_CHAT_MESSAGE',
      chat,
  }
}

export function addCurrentMessages(messages) {
  return {
      type: 'ADD_CURRENT_CHAT_MESSAGES',
      messages,
  }
}

export function setCurrentChatByCustomerId(customerid) {
  return {
    type: 'SET_CURRENT_CHAT_BY_CUSTOMER_ID',
    customerid,
  }
}

export function addTotalChatCount(chatscount) {
  return {
    type: 'ADD_CURRENT_CHATS_COUNT',
    chatscount,
  }
}

export function setUserChatStatus(userstatus) {
  return {
    type: 'SET_USER_CHAT_STATUS',
    userstatus,
  }
}

export function filterChatsByMessageProvider(messageproviderid) {
  return {
    type: 'FILTER_CHAT_BY_MESSAGE_PROVIDER',
    messageproviderid,
  }
}

export function updateLastChatMessage(chatid, lastmessage) {
  return {
    type: 'UPDATE_LAST_CHAT_MESSAGE',
    chatid,
    lastmessage,
  }
}

export function updateLastChatMessageV2(chatid, lastmessage, unreadMessages = null) {
  return {
    type: 'UPDATE_LAST_CHAT_MESSAGE_V2',
    chatid,
    lastmessage,
    unreadMessages,
  }
}

export function resetChatNumberOfNotification(chatid) {
  return {
    type: 'RESET_CHAT_NUMBER_OF_NOTIFICATIONS',
    chatid,
  }
}
export function updateCurrentChatCustomerData(chatid, customerdetails) {
  return {
    type: 'UPDATE_CURRENT_CHAT_CUSTOMER_DATA',
    chatid,
    customerdetails
  }
}

export function updateCurrentChatDetailsData(chatid, chatdetails) {
  return {
    type: 'UPDATE_CURRENT_CHAT_DETAILS_DATA',
    chatid,
    chatdetails
  }
}

export function handleChatUserDetailsSidebarToggle(issidebaropen) {
  return {
    type: 'HANDLE_CHAT_USER_DETAILS_SIDEBAR_TOGGLE',
    issidebaropen,
  }
}

export function updateChatStatusId(chatstatus,chatid) {
  return {
      type: 'UPDATE_CHAT_STATUS_ID',
      chatstatus,
      chatid
  }
}
export function updateChatNotes(chatnotes,chatid) {
  return {
      type: 'UPDATE_CHAT_NOTES',
      chatnotes,
      chatid
  }
}

export function updateUserInformations(userInfos,chatid) {
  return {
      type: 'UPDATE_USER_INFORMATIONS',
      userInfos,
      chatid
  }
}

export function updateUserDetailsInformations(userInfos,chatid) {
  return {
      type: 'UPDATE_USER_DETAILS_INFORMATIONS',
      userInfos,
      chatid
  }
}
export function refreshDashboard(refreshdashboard) {
  return {
    type: 'REFRESH_DASHBOARD',
    refreshdashboard,
  }
}
