import Api from './api';

export const getTechinicalTeams = async () => { 
  let data = [];
  await Api.get('/techinicalteams')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};


export const getTechinicalTeamsByOrganization = async (id) => { 
    let data = [];
    await Api.get('/techinicalteams/organization/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  

export const getCategories = async () => {
  let data = [];
  await Api.get("/categories/childrens")
      .then(result => {
          data = result.data
      })
      .catch(err => {
          data = err;
      });
  return data;
};

export const getAllCategories = async () => {
    let data = [];
    await Api.get("/categories")
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
  };
  
  
export const getAllCategoriesWithParent = async () => {
    let data = [];
    await Api.get("/categories/parent")
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
  };
  
  export const getAllCategoriesWithParentByOrganization = async (id) => {
    let data = [];
    await Api.get("/organizationcategories/organizations/"+id)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
  };
  
  export const getAllCategoriesWithParentByOrganizationList = async (id) => {
    let data = [];
    await Api.get(`/organizationcategories/organizationsList?ids=${id}`)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
  };

  export const getAllCategoriesWithParentByOrganizationListNotInTechinicalTeamSelectionModel = async (id, techinicalTeamId) => {
    let data = [];
    await Api.get(`/organizationcategories/organizationsList/notin/techinicalteam/${techinicalTeamId}/selectionmodel?ids=${id}`)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
  }

  export const getOrganizations = async () => { 
    let data = [];
    await Api.get('/organizations')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const getAllByTechnicalTeamId = async (id) => {
    let data = []
    await Api.get(`/techinicalteamorganizationcategories/technicalteam/${id}`)
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err
      })
    return data
  }

  export const getAllByTechnicalTeamIdSelectionModel = async (id) => {
    let data = []
    await Api.get(`/techinicalteamorganizationcategories/technicalteam/${id}/selectionmodel`)
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err
      })
    return data
  }