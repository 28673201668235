import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid, IconButton, Menu, MenuItem, Tooltip, Divider, ListItemIcon, DialogContentText } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useLocation } from 'react-router-dom';
import  {Link as LinkA} from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MyMaterialTable from '../../components/table';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';

import { TablePopup } from '../../components/table/table-popup';
import Api from "../../services/api";
import * as calledsActions from '../../redux/actions/calleds';
import * as headerMenuActions from '../../redux/actions/header-menu';
import * as lastCalledsQueueActions from '../../redux/actions/last-calleds-queue'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getSlaAtendimento, getSlaSolucao } from './called.functions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import SimpleSelectDefault from '../../components/select/default';
import ClearIcon from '@mui/icons-material/Clear';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SaveIcon from '@mui/icons-material/Save'
import * as Service from "../../services/organizationsetting.service";
import CallMade from '@mui/icons-material/CallMade';
import Rating from '@mui/material/Rating';
import Chip from '@mui/material/Chip';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EmailIcon from '@mui/icons-material/Email';
import TreeDots from '../../components/tree-dots';
import ModalVideoCall from "../../components/modal-video-call-list";
import FirstElement from "../../components/first-element";
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import { PapiroConsole } from '../../utils/papiroConsole';
import SimpleAutoComplete from '../../components/select/simple';
import fileDownload from 'js-file-download'  
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MoreVert from "@mui/icons-material/MoreVert";
import * as TableCustomFilterService from '../../services/tablecustomfilter.service'
import * as CalledListDataService from '../../services/calledlistdata.service'
import HelpIcon from '@mui/icons-material/Help'
import FilterListIcon from '@mui/icons-material/FilterList'
import StarRateIcon from '@mui/icons-material/StarRate'
import SimpleCheckBox from "../../components/checkbox/check";
import './style.css'
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PanToolIcon from '@mui/icons-material/PanTool';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchBar from './components/search-bar';
import SearchBarDialog from './components/search-bar-dialog'
import TableList from './components/table-list';
import { ItemCard } from './components/itemcard/item-card'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { saveAs } from 'file-saver';
import { getAllSelectionModelToTableCustomFilterByRoleType } from "../../services/organization.service";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple";
import CalledsQuantityCards from "../dashboard/components/calledsquantitycards/calledsquantitycards";
import Favoritefilterscards from "../dashboard/components/favoritefilterscards/favoritefilterscards";
import { getLastCalledsQueueByUserId } from "../../services/called.service";

var TableTitles = [];

class Calleds extends Component {

    constructor(props) {

        super(props);
        this.Reload = this.Reload.bind(this);
        this.Reload();

        PapiroConsole.log("constructor")
        PapiroConsole.log(props)

        this.isFinishedState = 0
        this.isArchivedState = 0
        this.isCanceledState = 0
        if (this.props.location && this.props.location.state && this.props.location.state == "finishedCalleds")
            this.isFinishedState = 1

        if (this.props.location && this.props.location.state && this.props.location.state == "archivedcallleds")
        {
            this.isArchivedState = 1
            PapiroConsole.log(this.isArchivedState)
            PapiroConsole.log("this.isArchivedState")
        }

        if (this.props.location && this.props.location.state && this.props.location.state == "canceledcalleds")
        {
            this.isCanceledState = 1
            PapiroConsole.log(this.isCanceledState)
            PapiroConsole.log("this.isCanceledState")
        }
        

        this.typingTimeout = null;

        const { userSession, location } = this.props;

        PapiroConsole.log("location nessa linha")
        PapiroConsole.log(this.props)

        const intl = this.props.intl;
        this.arrayConteudo = []
        this.organizationsettings = null;
        
        this.emptySearchArchived = null;
        this.emptySearchCanceled = null;
        this.emptySearchFinishCalleds = null;

        let filterTypes = [
            { name: intl.formatMessage({ id: "and" }), id: 1 },
            { name: intl.formatMessage({ id: "or" }), id: 2 }
        ]

        let filters = [
            { name: intl.formatMessage({ id: "id" }), id: "id" },
            { name: intl.formatMessage({ id: "title" }), id: "title" },
            { name: intl.formatMessage({ id: "organization" }), id: "org" },
            { name: intl.formatMessage({ id: "category" }), id: "category" },
            { name: intl.formatMessage({ id: "menu.statuses" }), id: "status" },
            { name: intl.formatMessage({ id: "type" }), id: "type" },
            { name: intl.formatMessage({ id: "requestuser" }), id: "requestuser" },
            { name: intl.formatMessage({ id: "assigned" }), id: "assigneduser" },
            { name: intl.formatMessage({ id: "open.date" }), id: "open" },
            { name: intl.formatMessage({ id: "location" }), id: "loc" },
            { name: intl.formatMessage({id:"classification"}), id: "classification" },
            { name: intl.formatMessage({id:"origin.call"}), id: "origin" },
            { name: intl.formatMessage({id:"called.list.calls.with.geolocalization"}), id: "geolocalization" },
        ];

        this.popupColumns = [
            { title: intl.formatMessage({ id: "type" }), field: 'typeName' },
            { title: intl.formatMessage({id:"menu.organizationpatrimonies"}), field: 'patrimonies' },
            { title: intl.formatMessage({ id: "attendance.team" }), field: 'time' },
        ]

        this.popupColumnsClient = [
            { title: intl.formatMessage({id:"menu.organizationpatrimonies"}), field: 'patrimonies' },
            { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName' },
            { title: intl.formatMessage({ id: "open.date" }), field: 'opening' },
            { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' },
        ]

        this.categorySetting = true
        this.typeSetting = false

        this.isClient = true;
        this.isAdm = false;
        this.isTechnical = false
        this.admGlobal = false;
        this.isAdmOrg = false;
        this.isCoordenator = false;

        if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }
        this.userOrganizations = userSession.user.userorganizations;

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 2) {
                this.isTechnical = true;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1 || item.role.roletype == 4) {
                this.isAdm = true;
            }
            if (item.role.roletype == 1)
                this.isAdmOrg = true;
            if (item.role.roletype == 4)
                this.isCoordenator = true;
            if (item.role.roletype == 0) {
                this.admGlobal = true;
            }
        });

        if(!this.isClient) {
            filters.push(
                { name: 'E-mail do solicitante', id: "requestusermail" },
                { name: intl.formatMessage({ id: "request.user.document" }), id: "requestuserdocument" },
                { name: intl.formatMessage({ id: "departament" }), id: "dept" },
                { name: 'Campo personalizado', id: "customfield" },
                { name: intl.formatMessage({id:"is.called.expired"}), id: "expiredcall" },
                { name: intl.formatMessage({id:"attendance.team"}), id: "techinicalteam" }
            )
        }

        let selectedColumnns = []
        if (this.isAdm) {
            TableTitles.push(
                { title: intl.formatMessage({id:"situation"}), field: 'CallDueDate' },
                { title: intl.formatMessage({ id: "id" }), field: 'id' },
                { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                { title: intl.formatMessage({ id: "title" }), field: 'title' },
                { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' },
                { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
                { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName', },
                { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' },
                { title: intl.formatMessage({ id: "location" }), field: 'addressname' },
                { title: intl.formatMessage({id:"classification"}), field: 'classification' },
                { title: intl.formatMessage({ id: "creation.date" }), field: 'DateCreatedAt' },
                { title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' }
            )
        } else if (!this.isAdm && this.isClient) {
            TableTitles.push(
                { title: intl.formatMessage({ id: "id" }), field: 'id' },
                { title: intl.formatMessage({id:"title"}), field: 'title' },
                { title: (this.categorySetting && this.typeSetting) ? intl.formatMessage({ id: "category.and.type" }) : (!this.categorySetting && this.typeSetting) ? intl.formatMessage({ id: "type" }) : intl.formatMessage({ id: "category" }), field: 'orgCat' },
                { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName' },
                { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' },
                { title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' },
            )
        } else {
            TableTitles.push(
                { title: intl.formatMessage({id:"situation"}), field: 'CallDueDate' },
                { title: intl.formatMessage({ id: "id" }), field: 'id' },
                { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                { title: intl.formatMessage({id:"title"}), field: 'title' },
                { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' },
                { title: intl.formatMessage({id:"menu.organizationpatrimonies"}), field: 'patrimonies' },
                { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName' },
                { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
                { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                { title: intl.formatMessage({ id: "location" }), field: 'addressname' },
                { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' },
                { title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' },
            )
        }

        this.organizationId = null;
        
        if (userSession.user.organizationusers) {
            this.organizationId = userSession.user.organizationlistid;
        }
        this.isCheckEmail = false;
        this.canStartCallCenter = false;
        userSession.user.organizationusers.map((item) => {
            if (item.organization.organizationsettings) {
                if (item.organization.organizationsettings.allowscreateanonymouscalledsbyemail) {
                    this.isCheckEmail = true;
                }
                if (item.organization.organizationsettings.canstartcallcenter) {
                    this.canStartCallCenter = true;
                }
            }
        });

        if (this.admGlobal == true && this.isClient == false) {
            this.isCheckEmail = true;
            this.canStartCallCenter = true;
        }

        this.orderFieldList = [
            {
                name: "id",
                displayName: "ID",
            },
            {
                name: "openingdate",
                displayName: intl.formatMessage({ id: "creation.date" }),
            },
            {
                name: "duedate",
                displayName: intl.formatMessage({id:"estimated.solution.date"}),
            }
        ];

        this.createTableCustomDialogInitialState = {
            name: '',
            exhibitionName:'',
            displayFilterInCalledList : false,
            isPrivate: false,
            fields: '',
            operators: '',
            values: '',
            isFavorite: false,
            isDefault: false,
            tableCustomFilterOrganizationIds: [],
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            createdUser: userSession.user && userSession.user.id
        }

        this.editTableCustomDialogInitialState = {
            id: '',
            userId: 0,
            name: '',
            isPrivate: false,
            fields: '',
            operators: '',
            values: '',
            isFavorite: false,
            isDefault: false,
            exhibitionName:'',
            displayFilterInCalledList : false,
            displayFilterInCreatorCalledList : false,
            tableCustomFilterOrganizationIds: [],
            tablecustomfilterorganizations: [],
            tablecustomfilterfields: [],
            updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            updatedUser: userSession.user && userSession.user.id
        }

        this.state = {
            emptyArray : null, 
            emptySearchArchived : false,
            emptySearchCanceled : false,
            emptySearchFinishCalleds : false,
            locationState: 'calleds',
            selectedColumnns: selectedColumnns,
            tableTitles: TableTitles,
            countExpiredCalleds : 0,
            countExpiredCalledsToday : 0,
            countOpenCalleds : 0,
            countPendingCalledsToday : 0,
            openAccordionFilter : false,
            countCalledsWithoutAssignedUser : 0,
            countOpenCalledsToday : 0,
            filters: filters,
            itemsTree: [],
            filterMenuAnchorEl: null,
            filterFavorite: [],
            filtersByFlag: [],
            createTableCustomDialog: this.createTableCustomDialogInitialState,
            editTableCustomDialog: this.editTableCustomDialogInitialState,
            deleteTableCustomFilter: null,
            listTableCustomDialogList: [],
            calledToAssign: null,
            calledsList: [],
			tableCustomFilterOrganizationList: [],
			countLoading: 0,
            organizationId: this.organizationId,
            currentPopupData: null,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            dataFilter: false,
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            searchValueListTableCustomField: '',
            searchValueEdit: '',
            filterSelected: 0,
            filterSelectedEdit: 0,
            filterTypes: filterTypes,
            multipleSearch: '',
            multipleFilter: '',
            contentFilter: '',
            contentFilterEdit: '',
            contentOrder: '',
            contentOrderEdit: '',
            filterType: 0,
            viewFilterType: false,
            calledCall: null,
            modalopen: false,
            MessageCreateNewCall: intl.formatMessage({id:"called.list.create.first.call"}),
            disabledvertex: true,
            checkMailAndVideoCallInsert: false,
            organizationCheckEmail: 0,
            calledCountTimeData: 0,
            calledCountNotTimeData: 0,
            countFavorite: 0,
            confirmDeleteTableCustomFilterDialogOpen: false,
            createTableCustomDialogOpen: false,
            listTableCustomDialogOpen: false,
            canAssignCalledDialogOpen: false,
            canAssignCalledRowData: null,
            canAssignCalledNewTab: false,
            editTableCustomFilterStep: 1,
            customFilters: intl.formatMessage({id:"custom.filters"}),
            tableCustomFilterToDeleteId: 0,
            typeSetting: false,
            categorySetting: false,
            confirmAssignCalledDialogOpen: false,

            isFinished: this.isFinishedState,
            isArchived: this.isArchivedState,
            isCanceled: this.isCanceledState,
			orderFields: this.orderFieldList,
            orderActions: [
                {
                    id: 1,
                    name: intl.formatMessage({ id: "search" })
                }
            ],
            populateCalledsDialogOpen: false,
            populateCalledsSize: { id: 10000, name: '10000' }
        };

        this.orderTwoActions = [
            "id", "title", "open", "duedate"
        ]

        this.changeValues = this.changeValues.bind(this);
        this.changeValuesCreateTableCustomDialog = this.changeValuesCreateTableCustomDialog.bind(this)
        this.getCalledsToCard = this.getCalledsToCard.bind(this);
        this.setAccordion = this.setAccordion.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.closeNotification = this.closeNotification.bind(this);
        this.closeCreateTableCustomDialog = this.closeCreateTableCustomDialog.bind(this)
        this.openCreateTableCustomDialog = this.openCreateTableCustomDialog.bind(this)
        this.openListTableCustomDialog = this.openListTableCustomDialog.bind(this)
        this.closeListTableCustomDialog = this.closeListTableCustomDialog.bind(this)
        this.closeEditStep = this.closeEditStep.bind(this)
        this.setEditStep = this.setEditStep.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.openConfirmDeleteTableCustomFilterDialog = this.openConfirmDeleteTableCustomFilterDialog.bind(this)
        this.closeConfirmDeleteTableCustomFilterDialog = this.closeConfirmDeleteTableCustomFilterDialog.bind(this)
        this.editTableCustomFilter = this.editTableCustomFilter.bind(this)
        this.addFilterOnComponentDidMount = this.addFilterOnComponentDidMount.bind(this)
        this.callSearch = this.callSearch.bind(this);
        this.callSearchEdit = this.callSearchEdit.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        
        this.checkEmail = this.checkEmail.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.videoCallClick = this.videoCallClick.bind(this);
        this.setCurrentPopupData = this.setCurrentPopupData.bind(this);
        this.loading = this.loading.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleOrderDelete = this.handleOrderDelete.bind(this)
        this.handleDeleteFilterEdit = this.handleDeleteFilterEdit.bind(this)
        this.handleDeleteOrderEdit = this.handleDeleteOrderEdit.bind(this)
        this.VertexIsAvaliable = this.VertexIsAvaliable.bind(this);
        this.getById = this.getById.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        this.getOrganizationsSettings = this.getOrganizationsSettings.bind(this);
        this.setCountLoading = this.setCountLoading.bind(this)
        this.getCalleds = this.getCalleds.bind(this);
        this.handleClickFilterMenu = this.handleClickFilterMenu.bind(this)
        this.handleCloseFilterMenu = this.handleCloseFilterMenu.bind(this)
        this.getFavoritesAndCountByUserIdAndTableName = this.getFavoritesAndCountByUserIdAndTableName.bind(this)
        this.getFavoritesByUserIdAndTableName = this.getFavoritesByUserIdAndTableName.bind(this)
        this.getCountByUserIdAndTableName = this.getCountByUserIdAndTableName.bind(this)
        this.saveTableCustomFilter = this.saveTableCustomFilter.bind(this)
        this.openConfirmAssignCalledDialog = this.openConfirmAssignCalledDialog.bind(this)
        this.closeConfirmAssignCalledDialog = this.closeConfirmAssignCalledDialog.bind(this)
        this.setColumnTitles = this.setColumnTitles.bind(this)
        this.calledListDataFormmat = this.calledListDataFormmat.bind(this)
        this.arrayUnique = this.arrayUnique.bind(this)
        this.populateAllCalleds = this.populateAllCalleds.bind(this)
        this.getCalledCostCount = this.getCalledCostCount.bind(this)
        this.assignCalled = this.assignCalled.bind(this)
        this.goToCalled = this.goToCalled.bind(this)
        this.showMessage = this.showMessage.bind(this)
        this.openConfirmAssignCalledDialogFinal = this.openConfirmAssignCalledDialogFinal.bind(this)
        this.handleChangeFieldValue = this.handleChangeFieldValue.bind(this)
        this.handleClickExportToCSV = this.handleClickExportToCSV.bind(this)
        this.addFilterEdit = this.addFilterEdit.bind(this)
        this.openPopulateCalledsDialog = this.openPopulateCalledsDialog.bind(this)
        this.closePopulateCalledsDialog = this.closePopulateCalledsDialog.bind(this)
        this.closeCanAssignCalledDialog = this.closeCanAssignCalledDialog.bind(this)
        this.openCanAssignCalledDialog = this.openCanAssignCalledDialog.bind(this)
        this.negativeCanAssignCalledDialog = this.negativeCanAssignCalledDialog.bind(this)
    }

    closeCanAssignCalledDialog = _ => this.setState({ canAssignCalledDialogOpen: false, canAssignCalledRowData: null,
        canAssignCalledNewTab: false })

    openCanAssignCalledDialog = (rowData, newTab = false) => {
        if (this.isTechnical && !this.isClient && !this.isCoordenator && !this.isAdmOrg && !this.admGlobal && !this.isAdm && rowData && rowData.status && rowData.status.statustypeid && rowData.status.statustypeid == 1) {
            this.setState({
                canAssignCalledDialogOpen: true,
                canAssignCalledRowData: rowData,
                canAssignCalledNewTab: newTab
            })
        } else {
            this.goToCalled(rowData, newTab)
        }
    }

    negativeCanAssignCalledDialog = async _ => {
        this.setState({
            canAssignCalledDialogOpen: false,
            canAssignCalledRowData: null,
            canAssignCalledNewTab: false
        })
        await this.goToCalled(this.state.canAssignCalledRowData, this.state.canAssignCalledNewTab)
    }

    confirmCanAssignCalledDialog = async _ => {
        this.setState({
            canAssignCalledDialogOpen: false,
            canAssignCalledNewTab: false,
            calledToAssign: this.state.canAssignCalledRowData
        }, () => this.assignCalled())
    }

    openPopulateCalledsDialog = _ => this.setState({ populateCalledsDialogOpen: true })
    closePopulateCalledsDialog = _ => this.setState({ populateCalledsDialogOpen: false })

    handleClickExportToCSV = () => {
        this.setState({ loading: true });

        let url = "calleds/getallcsv"

        let newFilter = localStorage.getItem('newFilter');
        let newSearch = localStorage.getItem('newSearch');
        let newOrderFilter = localStorage.getItem('newOrderFilter');
        let newOrderSearch = localStorage.getItem('newOrderSearch');

        url += `?type=0`;

        if (newFilter != '' && newSearch != '') {

            url += `&search=${newSearch}&field=${newFilter}`;
            this.setState({ dataFilter: true });
        }
        if (newOrderFilter != '' && newOrderSearch != '') {
            url += `&osearch=${newOrderSearch}&ofield=${newOrderFilter}`
        }
        let filter = ""
        if (filter != '') {
            url = url + '&filter=' + filter;
        }

        url += `&isfinished=${this.state.isFinished}`;

        url += `&isarchived=${this.state.isArchived}`;

        url += `&iscanceled=${this.state.isCanceled}`;

        Api.get(url, {
            responseType: 'blob'
        })
            .then((response) => {

                /*let url = window.URL.createObjectURL(new Blob([response.data]));
                saveAs(url, 'called_list.csv');*/
                fileDownload(response.data, `called_list.csv.zip`);
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    }

    async getCalledsToCard() {
    
    
        this.setState({ loading: true })
      
    
        let url = `/cards/getcalledstocards`;
    
        await Api.get(url)
          .then(result => {
            if (result.data.success) {
              this.setState({
                countOpenCalleds: result.data.data.countopencalleds,
                countPendingCalledsToday: result.data.data.countpendingcalledstoday,
                countExpiredCalleds: result.data.data.countexpiredcalleds,
                countExpiredCalledsToday: result.data.data.countexpiredcalledstoday,
                countCalledsWithoutAssignedUser: result.data.data.countcalledswithoutassigneduser,
                countOpenCalledsToday : result.data.data.countopencalledstoday
               
              });
            }
            else {
              const intl = this.props.intl;
              this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
              });
            }
          })
          .catch(err => {
            const intl = this.props.intl;
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          });
    
    
         this.setState({ loading:false })
      }

    handleChangeFieldValue = (value) => {
        const { intl } = this.props
        PapiroConsole.log("=== handleChangeFieldValue ===")
        PapiroConsole.log(value)

        let returnAction = [
            {
                id: 1,
                name: intl.formatMessage({ id: "search" })
            }
        ]

        let twoActions = this.orderTwoActions.find(e => e === value)
        PapiroConsole.log(twoActions)
        if (twoActions && twoActions.length > 0) {
            returnAction = [
                {
                    id: 1,
                    name: intl.formatMessage({ id: "search" })
                },
                {
                    id: 2,
                    name: intl.formatMessage({ id: "order" })
                }
            ]
        }
        PapiroConsole.log(returnAction)

        return returnAction
    }

    showMessage = async ( loading , openNotification , notificationVariant , notificationMessage) =>  {
            await this.setState({
                loading: loading,
                openNotification: openNotification,
                notificationVariant: notificationVariant,
                notificationMessage: notificationMessage
            });
    }

    openConfirmAssignCalledDialog = async (rowData) =>  {
        const intl = this.props.intl;
        
        if(rowData.assigneduserid != null && typeof rowData.assigneduserid !== 'undefined' && rowData.assigneduserid > 0) {
            await this.setState({
                openNotification: true,
                notificationVariant: "info",
                notificationMessage: intl.formatMessage({id:"called.list.notification.call.has.assigned.tech"})
            });
        } else {
            this.setState({ confirmAssignCalledDialogOpen: true, calledToAssign: rowData })
        }
    }

	openConfirmAssignCalledDialogFinal = async (confirm, rowData) =>  {
            this.setState({ confirmAssignCalledDialogOpen: confirm, calledToAssign: rowData })
    }

    closeConfirmAssignCalledDialog = () => this.setState({ confirmAssignCalledDialogOpen: false, calledToAssign: null })
    
    closeListTableCustomDialog = () => { 
        const intl = this.props.intl;   
        this.setState({listTableCustomDialogOpen: !this.state.listTableCustomDialogOpen, editTableCustomFilterStep: 1, customFilters: intl.formatMessage({id:"custom.filters"}), contentFilterEdit: '', contentOrderEdit: '' })
    }
    openListTableCustomDialog = async () => {
        this.handleCloseFilterMenu()

        await this.listTableCustomFilters(true)
    }

    closeCreateTableCustomDialog = () => this.setState({ createTableCustomDialogOpen: !this.state.createTableCustomDialogOpen })

    openCreateTableCustomDialog = async () => {
        this.setState({ loading: true })
        const intl = this.props.intl;

        if (this.state.tableCustomFilterOrganizationList == null || (this.state.tableCustomFilterOrganizationList && this.state.tableCustomFilterOrganizationList.length == 0))
        {
            let result = await getAllSelectionModelToTableCustomFilterByRoleType();
            if(result && result.success) {
                if(result.data != null)
                    this.setState({ loading: false, tableCustomFilterOrganizationList: result.data })
            } else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }

		if(this.state.contentFilter || this.state.contentOrder){
            if ((this.isClient) || (this.isTechnical && !this.isAdm && !this.admGlobal)) {
                this.setState(prevState => ({
                    createTableCustomDialog: {
                        ...prevState.createTableCustomDialog,
                        isPrivate: true,
                    },
                    createTableCustomDialogOpen: !this.state.createTableCustomDialogOpen,
                    loading: false,
                }));
            } else {
                this.setState({ createTableCustomDialogOpen: !this.state.createTableCustomDialogOpen,loading: false, });
            }
		}
		else{
			this.setState({
                openNotification: true, notificationVariant: "error",
                loading: false,
                notificationMessage: intl.formatMessage({id:"called.list.notification.missing.filter"})
            });
		}
	}

    openConfirmDeleteTableCustomFilterDialog = (id) => this.setState({ confirmDeleteTableCustomFilterDialogOpen: !this.state.confirmDeleteTableCustomFilterDialogOpen, tableCustomFilterToDeleteId: id })

    closeConfirmDeleteTableCustomFilterDialog = () => this.setState({ confirmDeleteTableCustomFilterDialogOpen: !this.state.confirmDeleteTableCustomFilterDialogOpen, tableCustomFilterToDeleteId: 0 })

    closeEditStep = () => {
        const intl = this.props.intl;
        this.setState({
            editTableCustomDialog: this.editTableCustomDialogInitialState,
            editTableCustomFilterStep: 1,
            customFilters: intl.formatMessage({id:"custom.filters"}),
            contentFilterEdit: '',
            contentOrderEdit: ''
        })
    }

    setEditStep = async (id) => {
        this.setState({ loading: true })

        if (this.state.tableCustomFilterOrganizationList == null || (this.state.tableCustomFilterOrganizationList && this.state.tableCustomFilterOrganizationList.length == 0))
        {
            let result = await getAllSelectionModelToTableCustomFilterByRoleType();
            if(result && result.success) {
                if(result.data != null)
                    this.setState({ loading: false, tableCustomFilterOrganizationList: result.data })
            } else {
                const intl = this.props.intl
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }

        let tableCustom = null
        PapiroConsole.log(this.state.listTableCustomDialogList)
        PapiroConsole.log("this.state.listTableCustomDialogList")
        if(this.state.listTableCustomDialogList && this.state.listTableCustomDialogList.length > 0) {
            PapiroConsole.log("entrei no if")
            let tableCustomFilter = this.state.listTableCustomDialogList.filter(item => item.id == id)
            PapiroConsole.log("tableCustomFilter")
            PapiroConsole.log(tableCustomFilter)
            
            if(tableCustomFilter && tableCustomFilter.length > 0) {
                tableCustom = tableCustomFilter[0]
            } else {
                PapiroConsole.log("entrei no else")
                let result = await TableCustomFilterService.getById(id)

                PapiroConsole.log("result")
                PapiroConsole.log(result)
                if(result && result.success) {
                    if(result.data != null)
                        tableCustom = result.data
                } else {
                    const intl = this.props.intl
                    this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: 'error',
                      notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    })
                    Api.kickoff(result)
                }
            }
        } else {
            PapiroConsole.log("entrei no else dois")
            let result = await TableCustomFilterService.getById(id)
            PapiroConsole.log("result 2")
            if(result && result.success) {
                if(result.data != null)
                    tableCustom = result.data
            } else {
                const intl = this.props.intl
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }

        PapiroConsole.log("=== tableCustom")
        PapiroConsole.log(tableCustom)
        if(tableCustom != null) {
            const intl = this.props.intl
            const { userSession } = this.props;

            let displayFilterInCreatorCalledList = false
            let displayFilterInCalledList = false
            
            let checkIfUserLoggedAddFilterInCalledList = await TableCustomFilterService.getByIdAndUserId(tableCustom.id,userSession.user.id )
            if(checkIfUserLoggedAddFilterInCalledList && checkIfUserLoggedAddFilterInCalledList.success) {
                if(checkIfUserLoggedAddFilterInCalledList.data != null)
                    if(checkIfUserLoggedAddFilterInCalledList.data.tablecustomfilterappearincalledlist != null){
                        
                        displayFilterInCalledList = true
                    }
            }


            if(this.admGlobal && tableCustom.userid != userSession.user.id){

                PapiroConsole.log("entrei no if da requisição")
                let checkIfUserOfFilterAddFilterInCalledList = await TableCustomFilterService.getByIdAndUserId(tableCustom.id,tableCustom.userid )
                if(checkIfUserOfFilterAddFilterInCalledList && checkIfUserOfFilterAddFilterInCalledList.success) {
                    if(checkIfUserOfFilterAddFilterInCalledList.data != null)
                        if(checkIfUserOfFilterAddFilterInCalledList.data.tablecustomfilterappearincalledlist != null){
                            PapiroConsole.log("entrei nesse if checkIfUserOfFilterAddFilterInCalledList")
                            displayFilterInCreatorCalledList = true
                        }
                }
                
               
            }

            this.setState(prevState => ({
                editTableCustomDialog: {
                    ...prevState.editTableCustomDialog,
                    id: tableCustom.id,
                    userId: tableCustom.userid,
                    name: tableCustom.name,
                    isPrivate: ((this.isClient) || (this.isTechnical && !this.isAdm && !this.admGlobal)) ? true : tableCustom.isprivate,
                    isFavorite: tableCustom.tablecustomfilterfavorite != null ? true : false,
                    isDefault: tableCustom.tablecustomfilterdefault != null ? true : false,
                    exhibitionName: tableCustom.exhibitionname != null ? tableCustom.exhibitionname : '',
                    displayFilterInCalledList : displayFilterInCalledList,
                    displayFilterInCreatorCalledList : displayFilterInCreatorCalledList,
                    tableCustomFilterOrganizationIds: tableCustom.tablecustomfilterorganizations.map(item => { return item.organization }),
                    tablecustomfilterorganizations: tableCustom.tablecustomfilterorganizations != null ? tableCustom.tablecustomfilterorganizations : [],
                    tablecustomfilterfields: tableCustom.tablecustomfilterfields != null ? tableCustom.tablecustomfilterfields : []
                },
                editTableCustomFilterStep: 2,
                customFilters: intl.formatMessage({id:"custom.filter.edit"}),
                loading: false
            }), () => this.setFiltersEdit(tableCustom))
        } else {
            const intl = this.props.intl
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "process.error" })
            })
        }


       
    }

    handleClickFilterMenu = (event) => {
        this.setState({ filterMenuAnchorEl: event.currentTarget })
    }

    handleCloseFilterMenu = _ => {
        this.setState({ filterMenuAnchorEl: null })
    }

    searchFilter = async _ => {
        this.setState({ loading: true })
        if(this.state.searchValueListTableCustomField && this.state.searchValueListTableCustomField.length > 0) {
            const { userSession } = this.props
            const intl = this.props.intl;
            let userId = userSession && userSession.user && userSession.user.id

            let formData = new FormData();
            formData.append("userid", userId)
            formData.append("tablename", "calleds")
            formData.append("search", this.state.searchValueListTableCustomField)

            var result = await TableCustomFilterService.getAllByUserIdAndTableNameSearch(formData)
            PapiroConsole.log("print resultado aqui")
            PapiroConsole.log(result)
            if(result && result.success) {
                if(result.data != null) {
                    let formattedList = result.data.map(item => {
                        return {
                            ...item,
                            isfavorite: item.tablecustomfilterfavorite != null ? true : false,
                            isDefault: item.tablecustomfilterdefault != null ? true : false,
                            exhibitionName: item.exhibitionname != null ? item.exhibitionname : '',
                            filterCreatorName: item.user? item.user.name : '' ,
                            displayFilterInCalledList : item.tablecustomfilterappearincalledlist ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}),
                            deleteId: { id: item.id, user: item.userid == userId ? true : false },
                            editId: { id: item.id, user: item.userid == userId ? true : false },
                            isPrivateText: item.isprivate ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}),
                            data: item
                        }
                    })

                    this.setState({
                        loading: false,
                        listTableCustomDialogList: formattedList,
                        editTableCustomFilterStep: 1,
                        customFilters: intl.formatMessage({id:"custom.filters"}),
                        contentFilterEdit: '',
                        contentOrderEdit: '',
                        searchValueListTableCustomField: ''
                    })
                }
            } else {
                const intl = this.props.intl
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        } else {
            const intl = this.props.intl;

            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"called.list.notification.insert.search.value"})
            })
        }
    }

    setFilters = async (item, modal = '') => {
        let { intl } = this.props
        if(modal && modal.length > 0) {
            if(modal == 'listCustomTableFilter')
                this.closeListTableCustomDialog()
        }

        this.handleCloseFilterMenu()
        localStorage.setItem('newFilter', '');
        localStorage.setItem('newSearch', '');
        localStorage.setItem('newOrderFilter', '');
        localStorage.setItem('newOrderSearch', '');
        PapiroConsole.log("content filter atualizado 1")
        await this.setState({ filterSelected: 0, searchValue: '', contentFilter: '', contentOrder: '', loading: true })

        let newFilter = ''
        let newSearch = ''
        let newOrderFilter = ''
        let newOrderSearch = ''
        item.tablecustomfilterfields.map(field => {

            let operator = ''

            switch(field.operator) {
                case 1:
                    operator = ' == '
                    break
                case 2:
                    operator = ' = '
                    break
                case 3:
                    operator = ' STARTS WITH '
                    break
                case 4:
                    operator = ' ENDS WITH '
                    break
                case 5:
                    operator = ' IS EMPTY '
                    break
                case 6:
                    operator = ' IS NOT EMPTY '
                    break
                case 7:
                    operator = ' BIGGER THEN '
                    break
                case 8:
                    operator = ' LESS THAN '
                    break
                case 9:
                    operator = ' BIGGER OR EQUAL '
                    break
                case 10:
                    operator = ' LESS OR EQUAL '
                    break
                case 11:
                    operator = ` ${intl.formatMessage({id:"order.filter"})}: `
                    break
            }

            if (field.field.trim() === "open") {
                let date = field.value.split("/");
                if (date.length === 3) {
                    date = `${date[2]}-${date[1]}-${date[0]}`
                }
                newFilter += field.field.trim() + "|";
                newSearch += date + "|";

                let nameField;
                for (let i = 0; i < this.state.filters.length; i++) {
                    if (this.state.filters[i].id === field.field.trim()) {
                        nameField = this.state.filters[i].name;
                    }
                }
                PapiroConsole.log("content filter atualizado 3")
                this.setState({
                    contentFilter: this.state.contentFilter + nameField + operator + field.value.trim() + "\n"
                })
            } else {
                if (field.operator != 11) {
                    newFilter += field.field.trim() + "|";
                    newSearch += field.value.trim() + "|";
                } else {
                    newOrderFilter += field.field.trim() + "|";
                    newOrderSearch += field.value.trim() + "|";
                }

                let nameField;
                for (let i = 0; i < this.state.filters.length; i++) {
                    if (this.state.filters[i].id === field.field.trim()) {
                        nameField = this.state.filters[i].name;
                    }
                }
                if (field.operator != 11) {
                    PapiroConsole.log("content filter atualizado 4")
                    this.setState({
                        contentFilter: `${this.state.contentFilter}${nameField}${operator}${field.value.trim()}\n`
                    });
                } else {
                    this.setState({
                        contentOrder: `${this.state.contentOrder}${nameField}${operator}${field.value.trim() == 1 ? `${intl.formatMessage({id:"order.filter.ascending"})}\n` : `${intl.formatMessage({id:"order.filter.descending"})}`}\n`
                    });
                }
            }
        })
        console.log("newFilter aqui 1")
        console.log(newFilter)
        localStorage.setItem("newFilter", newFilter)
        localStorage.setItem("newSearch", newSearch)
        localStorage.setItem('newOrderFilter', newOrderFilter);
        localStorage.setItem('newOrderSearch', newOrderSearch);
        await this.getCalleds('', this.state.page)
    }

    setFiltersEdit = async (item) => {
        PapiroConsole.log("=== setFiltersEdit")
        PapiroConsole.log(item)
        let { intl } = this.props
        let newFilter = ''
        let newSearch = ''
        let newFilterEdit = ''
        let newOrderEdit = ''
        
        item.tablecustomfilterfields.map(field => {
            let operator = ''
            switch(field.operator) {
                case 1:
                    operator = ' == '
                    break
                case 2:
                    operator = ' = '
                    break
                case 3:
                    operator = ' STARTS WITH '
                    break
                case 4:
                    operator = ' ENDS WITH '
                    break
                case 5:
                    operator = ' IS EMPTY '
                    break
                case 6:
                    operator = ' IS NOT EMPTY '
                    break
                case 7:
                    operator = ' BIGGER THEN '
                    break
                case 8:
                    operator = ' LESS THAN '
                    break
                case 9:
                    operator = ' BIGGER OR EQUAL '
                    break
                case 10:
                    operator = ' LESS OR EQUAL '
                    break
                case 11:
                    operator = ` ${intl.formatMessage({id:"order.filter"})}: `
                    break
            }

            if (field.field.trim() === "open") {
                let date = field.value.split("/");
                if (date.length === 3) {
                    date = `${date[2]}-${date[1]}-${date[0]}`
                }
                newFilter += field.field.trim() + "|";
                newSearch += date + "|";

                let nameField;
                for (let i = 0; i < this.state.filters.length; i++) {
                    if (this.state.filters[i].id === field.field.trim()) {
                        nameField = this.state.filters[i].name;
                    }
                }

                newFilterEdit += `${nameField}${operator}${field.value.trim()}\n`
            } else {
                newFilter += field.field.trim() + "|";
                newSearch += field.operator != 11 ? field.value.trim() + "|" : field.value == 1 ? `${intl.formatMessage({id:"order.filter.ascending"})}|` : `${intl.formatMessage({id:"order.filter.descending"})}|`

                let nameField;
                for (let i = 0; i < this.state.filters.length; i++) {
                    if (this.state.filters[i].id === field.field.trim()) {
                        nameField = this.state.filters[i].name;
                    }
                }

                let fieldValue = field.operator != 11 ? field.value.trim() : field.value == 1 ? `${intl.formatMessage({id:"order.filter.ascending"})}` : `${intl.formatMessage({id:"order.filter.descending"})}`

                if (field.operator != 11)
                    newFilterEdit += `${nameField}${operator}${fieldValue}\n`
                else
                    newOrderEdit += `${nameField}${operator}${fieldValue}\n`
            }
        })

        PapiroConsole.log("=== setFiltersEdit ===")
        PapiroConsole.log(newFilterEdit)
        PapiroConsole.log(newOrderEdit)

        this.setState({
            contentFilterEdit: newFilterEdit,
            contentOrderEdit: newOrderEdit
        })

       
    }

    async VertexIsAvaliable() {
        var result = await Api.VertexIsAvaliable();
        this.setState({ disabledvertex: !result });

    }

    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);


        }
        return null;
    }

    closeModal = () => {

        this.setState({ modalopen: false });

    }

    videoCallClick = () => {
        this.setState({ modalopen: true });
    };

    onChangePaginate = async (e, page) => {
        this.setState({
            loading: true,
        });

        this.setState({ page: page, countLoading: 0 });
        localStorage.setItem('calledsPage', `${page}`)
        await this.getCalleds('', page);
    }

    loading(choice) {
        this.setState({ loading: choice });
    }

    async getUserById(id) {
        await Api.get(`/users/${id}`)
            .then(result => {
                if (result.data.success) {
                }
            })

            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });
    }

    setPagination(count, page, pageCount) {
        let result = {};
        let totalPage = Math.ceil(count / pageCount);
        //this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < pageCount) {
                    //this.setState({ pageStart: 1, pageEnd: count });
					result.pageStart =1;
					result.pageEnd = count;
                } else {
                    //this.setState({ pageStart: 1, pageEnd: 10 });
					result.pageStart =1;
					result.pageEnd = pageCount;
                }
            } else {
                let pageStart = ((page - 1) * pageCount) + 1;

				result.pageStart =pageStart;


                if (count >= page * pageCount) {
                   // this.setState({ pageStart: pageStart, pageEnd: page * pageCount });
					result.pageEnd = page * pageCount ;
                } else {
                    //this.setState({ pageStart: pageStart, pageEnd: count });
					result.pageEnd = page * pageCount ;
                }
            }
			result.totalPages =totalPage;
			result.page =page;
			result.count =count;
			result.pageCount =pageCount;


        } else {
            //this.setState({ pageStart: 0, pageEnd: 0, totalPages: 0, page: 1, count: 0 });
			result.totalPages =0;
			result.page =1;
			result.count =0;
			result.pageStart =0;
			result.pageEnd = 0;
			result.pageCount = pageCount;
		}
		return result;

    }

    onFieldChange(event) {
        event.preventDefault();
        let name = event.target.name
        let value = event.target.value

        this.setState({ [name]: value });
    }

    onFieldChangeEdit = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    callSearch = async (filter, value, type = 1) => {
        PapiroConsole.log(filter)
        PapiroConsole.log(value)
        PapiroConsole.log(type)
        PapiroConsole.log("variaveis de filtro")
		if ((value && value.trim() != '') && filter != 0) {
			await this.addFilter(filter, value, type);
            PapiroConsole.log("entrei aqui")
        }
        if ((this.state.contentFilter && this.state.contentFilter.length > 0) || (this.state.contentOrder && this.state.contentOrder.length > 0)) {
          
                
                this.setState({ loading: true });
                await this.getCalleds('', this.state.page);
                this.setState({ loading: false });

                
            
           
        }
    }
    callSearchEdit = async (filter, value, type = 1) => {
		if ((value && value.trim() != '') && filter != 0) {
			await this.addFilterEdit(filter, value, type);
        }
        if ((this.state.contentFilterEdit && this.state.contentFilterEdit.length > 0) || (this.state.contentOrderEdit && this.state.contentOrderEdit.length > 0)) {
            this.setState({ loading: true });
            if (this.state.editTableCustomDialog && this.state.editTableCustomDialog.id > 0)
                await this.setFilters(this.state.editTableCustomDialog, 'listCustomTableFilter')
            await this.getCalleds('', this.state.page);
            this.setState({ loading: false });
        }
    }

    async clearFields() {
        PapiroConsole.log("entrei aqui no clear fields")
        this.clearFilters();
        PapiroConsole.log(this.state.isArchived)
        PapiroConsole.log(this.state.isFinished)
        PapiroConsole.log(this.state.isArchived)
        if(this.state.isArchived == 0 && this.state.isFinished == 0 && this.state.isCanceled == 0 ){

            await this.getCalleds('', 1);
        }
        else{
            await this.setState({  loading: false })
        }
        

    }

    async clearFilters(){

        localStorage.setItem('newFilter', '');
        localStorage.setItem('newSearch', '');
        localStorage.setItem('newOrderFilter', '');
        localStorage.setItem('newOrderSearch', '');
        localStorage.setItem('calledsPage', '1')
        PapiroConsole.log("content filter atualizado 4")
        await this.setState({ filterSelected: 0, searchValue: '', page: 1, contentFilter: '', contentOrder: '', loading: true })

    }

    clearFieldsEdit = _ => {
        this.setState({ filterSelectedEdit: 0, searchValueEdit: '', contentFilterEdit: '', contentOrderEdit: '' })
    }

    async getOrganizationsSettings() {
        let infos;
        let id = parseInt(this.organizationId)


        await Api.get(`/organizationsettings/organization/${id}`)
            .then(result => {
                if (result.data.success) {
                    infos = result.data.data;
                }
                this.setCountLoading();

            })

            .catch(err => {
                // console.log("erro organization settings")
                const intl = this.props.intl;
                this.setState({

                    notificationMessage: intl.formatMessage({id:"error"})
                });
                Api.kickoff(err);
                this.setCountLoading();

            });
        return infos;

    }



    async getOrganizations() {
        const { userSession } = this.props
        if(userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            //this.setState({ OrganizationList: userSession.user.userorganizations })
			let fommatedList = userSession.user.userorganizations.map(item => {
				return {
                    id: item.id,
                    name: item.name,
                    organizationsettings: item.organizationsettings ? {
                        ...item.organizationsettings,
                        organization: null,
                        originrequest: null,
                        status: null,
                        type: null
                    } : {}
                }
			})

			this.userOrganizations = fommatedList;
        } else {
            var result = await Service.getOrganizations(0);
            if (result.success) {
                //this.setState({ OrganizationList: result.data });
				let fommatedList = result.data.map(item => {
					return {
						id: item.id,
						name: item.name
					}
				})
				this.userOrganizations = fommatedList;


            } else {
                // console.log("erro get organizations")
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.response && result.response.data && result.response.data.errors
                            ? result.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
                Api.kickoff(result);

            }
        }
    }

    async addFilter(filter, value, type = 1) {
        PapiroConsole.log("=== addFilter ===")
        PapiroConsole.log(filter)
        PapiroConsole.log(value)
        PapiroConsole.log(type)
        const intl = this.props.intl;
       
        if (value.trim() == '' && filter == 0) {
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"caled.list.notification.select.one.filter.option"})
            });
        } else {
            PapiroConsole.log("=== addFilter -> 1 ===")
            let newFilter = localStorage.getItem('newFilter');
            let newSearch = localStorage.getItem('newSearch');
            let newOrderFilter = localStorage.getItem('newOrderFilter');
            let newOrderSearch = localStorage.getItem('newOrderSearch');

            if (value != '' && filter != 0) {
                PapiroConsole.log("=== addFilter -> 1.1 ===")
                if (filter === "open") {
                    PapiroConsole.log("=== addFilter -> 1.1.1 ===")
                    if (type == 1) {
                        let date = value.split("/");
                        if (date.length === 3) {
                            date = `${date[2]}-${date[1]}-${date[0]}`
                        }
                        newFilter += filter + "|";
                        newSearch += date + "|";
                    } else {
                        newOrderFilter += filter + "|";
                        newOrderSearch += value + "|";
                    }

                    let nameField;
                    for (let i = 0; i < this.state.filters.length; i++) {

                        if (this.state.filters[i].id === filter) {
                            nameField = this.state.filters[i].name;
                        }
                    }

                    if (type == 1) {
                        PapiroConsole.log("content filter atualizado 5")
                        this.setState({
                            contentFilter: `${this.state.contentFilter}${nameField} = ${value}\n`
                        });
                    } else {
                        let filterOrderConditional = value == "1" ? `${intl.formatMessage({id:"order.filter.ascending"})}` : `${intl.formatMessage({id:"order.filter.descending"})}`

                        await this.setState({
                            contentOrder: `${this.state.contentOrder}${nameField} ${intl.formatMessage({id:"order.filter"})}: ${filterOrderConditional}\n`
                        });
                    }

                } else {
                    PapiroConsole.log("=== addFilter -> 1.1.2 ===")
                    if (type == 1) {
                        newFilter += filter + "|";
                        newSearch += value + "|";
                    } else {
                        newOrderFilter += filter + "|";
                        newOrderSearch += value + "|";
                    }

                    let nameField;
                    PapiroConsole.log(this.state.filters.length)
                    PapiroConsole.log("this.state.filters.length")
                    PapiroConsole.log(this.state.filters)
                    PapiroConsole.log("this.state.filters")
                    for (let i = 0; i < this.state.filters.length; i++) {
                        
                        if (this.state.filters[i].id === filter) {
                            nameField = this.state.filters[i].name;
                        }

                       
                    }
                   
                    if(nameField == undefined && filter == "calledcards"){
                        nameField = intl.formatMessage({ id: "called.report.header" })
                        
                    }

                    if (type == 1) {
                        PapiroConsole.log("entrei no tipo aqui")
                        PapiroConsole.log(this.state.contentFilter)
                        PapiroConsole.log(`${nameField} = ${value}\n`)
                        PapiroConsole.log("content filter atualizado 5")
                        this.setState({
                            contentFilter: `${this.state.contentFilter}${nameField} = ${value}\n`
                        });
                    } else {
                        let filterOrderConditional = value == "1" ? `${intl.formatMessage({id:"order.filter.ascending"})}` : `${intl.formatMessage({id:"order.filter.descending"})}`

                        await this.setState({
                            contentOrder: `${this.state.contentOrder}${nameField} ${intl.formatMessage({id:"order.filter"})}: ${filterOrderConditional}\n`
                        });
                    }
                }
                PapiroConsole.log(newFilter)
                PapiroConsole.log("newFilter aqui")
                localStorage.setItem("newFilter", newFilter);
                localStorage.setItem("newSearch", newSearch);
                localStorage.setItem('newOrderFilter', newOrderFilter);
                localStorage.setItem('newOrderSearch', newOrderSearch);

                this.setState({ searchValue: '', filterSelected: 0 })
            }
        }

        PapiroConsole.log("=== this.state.contentFilter ===");
        PapiroConsole.log(this.state.contentOrder);

    }

    addFilterEdit(filter, value, type = 1) {
        const { intl } = this.props;
        if (value.trim() == '' && filter == 0) {
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"caled.list.notification.select.one.filter.option"})
            });
        } else {
            let newFilter = ''
            let newSearch = ''
            let newOrderFilter = ''
            let newOrderSearch = ''
            if (value != '' && filter != 0) {
                if (filter === "open") {
                    if (type == 1) {
                        let date = value.split("/");
                        if (date.length === 3) {
                            date = `${date[2]}-${date[1]}-${date[0]}`
                        }

                        newFilter += filter + "|";
                        newSearch += date + "|";
                    } else {
                        newOrderFilter += filter + "|";
                        newOrderSearch += value + "|";
                    }

                    let nameField;
                    for (let i = 0; i < this.state.filters.length; i++) {
                        if (this.state.filters[i].id === filter) {
                            nameField = this.state.filters[i].name;
                        }
                    }

                    if (type == 1) {
                        this.setState({
                            contentFilterEdit: `${this.state.contentFilterEdit}${nameField} = ${value}\n`
                        });
                    } else {
                        let filterOrderConditional = value == "1" ? `${intl.formatMessage({id:"order.filter.ascending"})}` : `${intl.formatMessage({id:"order.filter.descending"})}`

                        this.setState({
                            contentOrderEdit: `${this.state.contentOrderEdit}${nameField} ${intl.formatMessage({id:"order.filter"})}: ${filterOrderConditional}\n`
                        });
                    } 

                } else {
                    if (type == 1) {
                        newFilter += filter + "|";
                        newSearch += value + "|";
                    } else {
                        newOrderFilter += filter + "|";
                        newOrderSearch += value + "|";
                    }

                    let nameField;
                    for (let i = 0; i < this.state.filters.length; i++) {
                        if (this.state.filters[i].id === filter) {
                            nameField = this.state.filters[i].name;
                        }
                    }

                    if (type == 1) {
                        this.setState({
                            contentFilterEdit: `${this.state.contentFilterEdit}${nameField} = ${value}\n`
                        });
                    } else {
                        let filterOrderConditional = value == "1" ? `${intl.formatMessage({id:"order.filter.ascending"})}` : `${intl.formatMessage({id:"order.filter.descending"})}`

                        this.setState({
                            contentOrderEdit: `${this.state.contentOrderEdit}${nameField} ${intl.formatMessage({id:"order.filter"})}: ${filterOrderConditional}\n`
                        });
                    }
                }
                console.log("newFilter aqui 2")
                console.log(newFilter)
                localStorage.setItem("newFilter", newFilter);
                localStorage.setItem("newSearch", newSearch);
                localStorage.setItem('newOrderFilter', newOrderFilter);
                localStorage.setItem('newOrderSearch', newOrderSearch);
            }
        }
    }

    addFilterOnComponentDidMount = async (intl) => {

        let newFilter = localStorage.getItem('newFilter')
        let newSearch = localStorage.getItem('newSearch')
        let newOrderFilter = localStorage.getItem('newOrderFilter');
        let newOrderSearch = localStorage.getItem('newOrderSearch');
        PapiroConsole.log(newFilter)
        PapiroConsole.log(newSearch)
        PapiroConsole.log(newOrderFilter)
        PapiroConsole.log(newOrderSearch)
        PapiroConsole.log("newOrderFilter")
        PapiroConsole.log("newOrderSearch")
        PapiroConsole.log("newFilter")
        PapiroConsole.log("newSearch")
        if (newOrderFilter != null && newOrderFilter.length > 0 && newOrderSearch != null && newOrderSearch.length > 0) {
            await this.setState({ contentOrder: '' })
            let field = newOrderFilter.split('|')
            let value = newOrderSearch.split('|')
            PapiroConsole.log(field)
            PapiroConsole.log(value)
            PapiroConsole.log("field")
            PapiroConsole.log("value")
            if(field && field.length > 0 && value && value.length > 0) {
                for(let i = 0; i < field.length; i++) {
                    if(field[i].length > 0) {
                        let nameField = field[i]
                        let filterName = this.state.filters.filter(f => f.id === field[i])
                        if(filterName && filterName.length > 0)
                            nameField = filterName[0].name

                        await this.setState({
                            contentOrder: `${this.state.contentOrder}${nameField} ${intl.formatMessage({id:"order.filter"})}: ${value[i] == 1 ? intl.formatMessage({id:"order.filter.ascending"}) : intl.formatMessage({id:"order.filter.descending"})}\n`
                        });
                    }
                }
            }
        }
        PapiroConsole.log("newFilter aqui")
        PapiroConsole.log(newFilter)
        PapiroConsole.log(newSearch)
        if(newFilter != null && newFilter.length > 0 && newSearch != null && newSearch.length > 0) {
            
            await this.setState({ contentFilter: '' })
            var field = newFilter.split('|')
            var value = newSearch.split('|')
            if(field && field.length > 0 && value && value.length > 0) {
                for(let i = 0; i < field.length; i++) {
                    if(field[i].length > 0) {
                        let nameField = field[i]
                        let filterName = this.state.filters.filter(f => f.id === field[i])
                        if(filterName && filterName.length > 0)
                            nameField = filterName[0].name
                        
                        PapiroConsole.log(nameField)
                        PapiroConsole.log("nameField")
                        if(nameField == "calledcards"){
                            nameField = intl.formatMessage({ id: "called.report.header" })
                        }
                        
                        
                        PapiroConsole.log(nameField)
                        var contentFilterTest = '' + nameField + " = " + value[i] + "\n"
                        /*var contentFilterTest2 = this.state.contentFilter + nameField + " = " + value[i] + "\n"
                        PapiroConsole.log("content filter atualizado 7")
                        PapiroConsole.log(this.state.contentFilter)
                        PapiroConsole.log(contentFilterTest)
                        PapiroConsole.log(contentFilterTest2)*/
                        if(!(this.state.contentFilter.includes(contentFilterTest)) ){
                            await this.setState({
                                contentFilter: this.state.contentFilter + nameField + " = " + value[i] + "\n"
                            });
                        }
                        
                    }
                }
            }
        }
        let calledListPersistFilter = localStorage.getItem('calledListPersistFilter')
        if (calledListPersistFilter && calledListPersistFilter == 'true') {
            localStorage.setItem('calledListPersistFilter', '');
        } else if ((newOrderFilter != null && newOrderSearch != null && newOrderFilter.length == 0 && newOrderSearch.length == 0) && (newFilter != null && newSearch != null && newFilter.length == 0 && newSearch.length == 0)) {
            await this.getDefaultFilter()
        }
    }

    listTableCustomFilters = async (modal = false) => {
        const intl = this.props.intl;

        this.setState({ loading: true })
        const { userSession } = this.props
        let userId = userSession && userSession.user && userSession.user.id

        let result = await TableCustomFilterService.getAllByUserIdAndTableName(userId, "calleds")
        if(result && result.success) {
            if(result.data != null) {
                let formattedList = result.data.map(item => {
                    return {
                        ...item,
                        isfavorite: item.tablecustomfilterfavorite != null ? true : false,
                        filterCreatorName: item.user? item.user.name : '' ,
                        isDefault: item.tablecustomfilterdefault != null ? true : false,
                        exhibitionName: item.exhibitionname != null ? item.exhibitionname : '',
                        displayFilterInCalledList : item.tablecustomfilterappearincalledlist ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}),
                        deleteId: { id: item.id, user: item.userid == userId ? true : false },
                        editId: { id: item.id, user: item.userid == userId ? true : false },
                        isPrivateText: item.isprivate ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}),
                        data: item
                    }
                })
                console.log(formattedList)
                console.log("formattedList dos filtros")
                this.setState({
                    loading: false,
                    listTableCustomDialogList: formattedList,
                    editTableCustomFilterStep: 1,
                    customFilters: intl.formatMessage({id:"custom.filters"}),
                    contentFilterEdit: '',
                    contentOrderEdit: ''
                }, () => {
                    if(modal && this.state.listTableCustomDialogOpen == false)
                        this.setState({ listTableCustomDialogOpen: !this.state.listTableCustomDialogOpen })
                })
            }
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }
    ShowFilter() {
        const openFilterMenu = Boolean(this.state.filterMenuAnchorEl)
        const intl = this.props.intl
            return (
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card className="background-title-search">
                            <CardContent>
                            
                                {(this.state.contentFilter || this.state.contentOrder) &&
                                    <Grid container spacing={6}>
                                        <Grid item xs={12} sm={12}>
                                            
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                        <Grid item xs={8} md={6}>
                                                            <Typography component={'span'} variant="h5">
                                                                {intl.formatMessage({id:"filters"})}:
                                                        </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <strong>
                                                                {this.state.contentFilter && this.state.contentFilter.length > 0 && this.state.contentFilter.split("\n").map((i, key) => {
                                                                    if (i.trim() != "") {
                                                                        return <Chip
                                                                            key={key}
                                                                            label={i}
                                                                            onDelete={() => this.handleDelete(key)}
                                                                            color="primary"
                                                                            variant="outlined"
                                                                        />
                                                                    }
                                                                })}
                                                                {this.state.contentOrder && this.state.contentOrder.length > 0 && this.state.contentOrder.split("\n").map((i, key) => {
                                                                    if (i.trim() != "") {
                                                                        return <Chip
                                                                            key={key}
                                                                            label={i}
                                                                            onDelete={() => this.handleOrderDelete(key)}
                                                                            color="primary"
                                                                            variant="outlined"
                                                                        />
                                                                    }
                                                                })}
                                                            </strong>
                                                        </Grid>
                                                    </Grid>
                                            
                                        
                                        </Grid>
                                    </Grid>
                                    }
                                
                            
                                <Grid container spacing={1} justify="space-between" alignItems="center">
                                    <Grid item xs={12} sm={12} md={2} lg={2}>
                                        <Typography component={'span'} variant="h5">
                                            {<FormattedMessage id="called.header" />}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={10} lg={10}>
                                        <Grid container spacing={1} direction="row" alignItems="center">
                                            <SearchBar
                                                field={this.state.filterSelected}
                                                filterValue={this.state.searchValue}
                                                filterName={"searchValue"}
                                                filters={this.state.filters}
                                                addFilterEdit={this.addFilter} 
                                                clearFieldsEdit={this.clearFields}
                                                callSearch={this.callSearch}
                                                orderFields={this.state.orderFields}
                                                handleChangeFieldValue={this.handleChangeFieldValue}
                                                {...this.props} />
                                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                                <IconButton
                                                    color='primary'
                                                    aria-label='filters'
                                                    component='span'
                                                    id="filter-menu"
                                                    aria-controls={ openFilterMenu ? 'filter-menu' : undefined }
                                                    aria-haspopup="true"
                                                    aria-expanded={ openFilterMenu ? 'true' : undefined }
                                                    onClick={this.handleClickFilterMenu} >
                                                    <MoreVert />
                                                </IconButton>
                                                <Menu
                                                    id="filter-menu"
                                                    aria-labelledby="filter-menu"
                                                    anchorEl={this.state.filterMenuAnchorEl}
                                                    open={openFilterMenu}
                                                    onClose={this.handleCloseFilterMenu}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left'
                                                    }} >
                                                        <MenuItem onClick={this.openCreateTableCustomDialog} disabled={ (this.state.contentFilter == null || this.state.contentFilter == '') && (this.state.contentOrder == null || this.state.contentOrder == '') }>
                                                            <ListItemIcon style={{ minWidth: 25 }}>
                                                                <SaveIcon style={{ fontColor: "#3f51b4", fill: "#3f51b4" }} />
                                                            </ListItemIcon>
                                                            {intl.formatMessage({id:"save"})}
                                                        </MenuItem>
                                                        <Divider />
                                                        <MenuItem disabled={true}>{intl.formatMessage({id:"custom.filters"})}</MenuItem>
                                                        { this.state.filterFavorite && this.state.filterFavorite.map((item, idx) => {
                                                            return <MenuItem key={idx} onClick={() => this.setFilters(item)}>
                                                                {item.tablecustomfilterfavorite != null && (
                                                                <ListItemIcon style={{ minWidth: 25 }}>
                                                                    <StarRateIcon style={{ fontColor: "#ffb400", fill: "#ffb400" }} />
                                                                </ListItemIcon>
                                                                )}
                                                                {item.name}
                                                            </MenuItem>
                                                        })}
                                                        { this.state.countFavorite > 0 &&
                                                        (<Divider />)}
                                                        { this.state.countFavorite > 0 &&
                                                        (<MenuItem onClick={this.openListTableCustomDialog} >
                                                            <ListItemIcon style={{ minWidth: 25 }}>
                                                                <FormatListBulletedIcon style={{ fontColor: "#3f51b4", fill: "#3f51b4" }} />
                                                            </ListItemIcon>
                                                            {intl.formatMessage({id:"called.report.config.organization.field.block.list.all"})}
                                                        </MenuItem>)}
                                                        <Divider />
                                                        <MenuItem onClick={this.handleClickExportToCSV} >
                                                            <ListItemIcon style={{ minWidth: 25 }}>
                                                                <ExitToAppIcon style={{ fontColor: "#3f51b4", fill: "#3f51b4" }} />
                                                            </ListItemIcon>
                                                            {intl.formatMessage({id:"called.list.export"})}
                                                        </MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>

                </Grid>
              
            )
       
    }

    getFavoritesAndCountByUserIdAndTableName = async _ => {
        this.setState({ loading: true })

        const { userSession } = this.props
        let userId = userSession && userSession.user && userSession.user.id

        let result = await TableCustomFilterService.getFavoritesAndCountByUserIdAndTableName(userId, "calleds")
        if(result && result.success) {
            if(result.data != null) {
                this.setState({
                    filterFavorite: result.data.favorites,
                    countFavorite: result.data.totalcount,
                })
            }
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }

    getFiltersByFlagDisplayFilterInCalledList = async _ => {
        this.setState({ loading: true })

        const { userSession } = this.props
        let userId = userSession && userSession.user && userSession.user.id

        let result = await TableCustomFilterService.showfiltersincalledlistbyflag(userId, "calleds")
        PapiroConsole.log(result)
        PapiroConsole.log("result de filtros aqui ")
        if(result && result.success) {
            if(result.data != null) {
                this.setState({
                    filtersByFlag : result.data,
                    

                   /* filtersByFlag: result.data.favorites,
                    countFiltersByFlag: result.data.totalcount,*/
                })
            }
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }


    getFiltersByFlagDisplayFilterInCalledListNew = async _ => {
        
        this.setState({ loading: true })
        const { userSession } = this.props
        let userId = userSession && userSession.user && userSession.user.id
        let url = `tablecustomfilter/showfiltersincalledlistbyflag?userid=${userId}&isfinished=${this.state.isFinished}&isarchived=${this.state.isArchived}&iscanceled=${this.state.isCanceled}&type=0`;

        await Api.get(url)
          .then(result => {

                PapiroConsole.log(result)
                PapiroConsole.log("result endpoint reformulado")
                if (result.data.success) {
                    if(result.data.data != null){

                        this.setState({
                            filtersByFlag : result.data.data,
                        })

                    }
                    
                }
                else {
                const intl = this.props.intl;
                this.setState({
                    openNotification: true, notificationVariant: "error",
                    notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                }
          })
          .catch(err => {
            const intl = this.props.intl;
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          });
    
    
        // this.setState({ loading:false })
    }

    

    getFavoritesByUserIdAndTableName = async _ => {
        this.setState({ loading: true })

        const { userSession } = this.props
        let userId = userSession && userSession.user && userSession.user.id

        let result = await TableCustomFilterService.getFavoritesByUserIdAndTableName(userId, "calleds")
        if(result && result.success) {
            if(result.data != null)
                this.setState({ filterFavorite: result.data })
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }

    getCountByUserIdAndTableName = async _ => {

        const { userSession } = this.props
        let userId = userSession && userSession.user && userSession.user.id

        let result = await TableCustomFilterService.getCountByUserIdAndTableName(userId, "calleds")
        if(result && result.success) {
            if(result.data != null)
                this.setState({ countFavorite: result.data })
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }

        this.setState({ loading: false })
    }

    deleteTableCustomFilter = async _ => {
        this.setState({ loading: true })
        if(this.state.tableCustomFilterToDeleteId && this.state.tableCustomFilterToDeleteId != 0) {
            var result = await TableCustomFilterService.deleteItem(this.state.tableCustomFilterToDeleteId)
            if(result && result.success) {
                const intl = this.props.intl
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "delete.sucess" })
                }, () => {
                    this.closeConfirmDeleteTableCustomFilterDialog()
                    this.closeListTableCustomDialog()
                    this.getFavoritesAndCountByUserIdAndTableName()
                    //this.getFavoritesByUserIdAndTableName()
                    //this.getCountByUserIdAndTableName()
                })
            } else {
                const intl = this.props.intl
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }
        this.setState({ loading: false })
    }

    editTableCustomFilter = async _ => {
        this.setState({ loading: true })
        const { editTableCustomDialog } = this.state

        
        if (!this.admGlobal && (this.isAdmOrg || this.isCoordenator) && !editTableCustomDialog.isPrivate && (editTableCustomDialog.tableCustomFilterOrganizationIds == null || (editTableCustomDialog.tableCustomFilterOrganizationIds && editTableCustomDialog.tableCustomFilterOrganizationIds.length == 0))) {
            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Para criar um filtro público, o campo 'Organização' é obrigatório"
            })
        }else if(editTableCustomDialog.exhibitionName == null || editTableCustomDialog.exhibitionName == "" ){

            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Para criar um filtro é necessário colocar um nome de exibição do filtro "
            })

        }
         else {
            let fields = ''
            let operators = ''
            let values = ''

            let valueAtual = this.state.contentFilterEdit.split("\n")
            valueAtual.map(item => {
                if (item != "") {
                    let itemSplit = item.split(" = ")
                    if(itemSplit && itemSplit[0] && itemSplit[1]) {
                        let idField = itemSplit[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplit[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplit[1] + '|'
                        operators += '2|'
                    }
                    let itemSplitEquals = item.split(" == ")
                    if(itemSplitEquals && itemSplitEquals[0] && itemSplitEquals[1]) {
                        let idField = itemSplitEquals[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitEquals[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitEquals[1] + '|'
                        operators += '1|'
                    }
                    let itemSplitStartsWith = item.split(" s= ")
                    if(itemSplitStartsWith && itemSplitStartsWith[0] && itemSplitStartsWith[1]) {
                        let idField = itemSplitStartsWith[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitStartsWith[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitStartsWith[1] + '|'
                        operators += '3|'
                    }
                    let itemSplitEndsWith = item.split(" f= ")
                    if(itemSplitEndsWith && itemSplitEndsWith[0] && itemSplitEndsWith[1]) {
                        let idField = itemSplitEndsWith[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitEndsWith[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitEndsWith[1] + '|'
                        operators += '4|'
                    }
                    let itemSplitIsEmpty = item.split(" e= ")
                    if(itemSplitIsEmpty && itemSplitIsEmpty[0] && itemSplitIsEmpty[1]) {
                        let idField = itemSplitIsEmpty[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitIsEmpty[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitIsEmpty[1] + '|'
                        operators += '5|'
                    }
                    let itemSplitIsNotEmpty = item.split(" !e= ")
                    if(itemSplitIsNotEmpty && itemSplitIsNotEmpty[0] && itemSplitIsNotEmpty[1]) {
                        let idField = itemSplitIsNotEmpty[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitIsNotEmpty[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitIsNotEmpty[1] + '|'
                        operators += '6|'
                    }
                    let itemSplitBiggerThen = item.split(" > ")
                    if(itemSplitBiggerThen && itemSplitBiggerThen[0] && itemSplitBiggerThen[1]) {
                        let idField = itemSplitBiggerThen[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitBiggerThen[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitBiggerThen[1] + '|'
                        operators += '7|'
                    }
                    let itemSplitLessThan = item.split(" < ")
                    if(itemSplitLessThan && itemSplitLessThan[0] && itemSplitLessThan[1]) {
                        let idField = itemSplitLessThan[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitLessThan[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitLessThan[1] + '|'
                        operators += '8|'
                    }
                    let itemSplitBiggerOrEqual = item.split(" >= ")
                    if(itemSplitBiggerOrEqual && itemSplitBiggerOrEqual[0] && itemSplitBiggerOrEqual[1]) {
                        let idField = itemSplitBiggerOrEqual[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitBiggerOrEqual[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitBiggerOrEqual[1] + '|'
                        operators += '9|'
                    }
                    let itemSplitLessOrEqual = item.split(" <= ")
                    if(itemSplitLessOrEqual && itemSplitLessOrEqual[0] && itemSplitLessOrEqual[1]) {
                        let idField = itemSplitLessOrEqual[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitLessOrEqual[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitLessOrEqual[1] + '|'
                        operators += '10|'
                    }
                }
            })

            let { intl } = this.props
            let orderValues = this.state.contentOrderEdit.split("\n")
            orderValues.map(item => {
                if (item != "") {
                    let itemSplit = item.split(` ${intl.formatMessage({id:"order.filter"})}: `)
                    if(itemSplit && itemSplit[0] && itemSplit[1]) {
                        let idField = itemSplit[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplit[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id

                        let convertValue = itemSplit[1] == `${intl.formatMessage({id:"order.filter.ascending"})}` ? 1 : 2
                        fields += idField + '|'
                        values += convertValue + '|'
                        operators += '11|'
                    }
                }
            })

            let formData = new FormData();
            formData.append("id", editTableCustomDialog.id)
            formData.append("userid", editTableCustomDialog.userId)
            formData.append("name", editTableCustomDialog.name)
            formData.append("table", "calleds")
            formData.append("isprivate", editTableCustomDialog.isPrivate)
            formData.append("isfavorite", editTableCustomDialog.isFavorite)
            formData.append("isDefault", editTableCustomDialog.isDefault)

            formData.append("isfinished", this.state.isFinished)

            formData.append("isarchived", this.state.isArchived)

            formData.append("iscanceled", this.state.isCanceled)

            formData.append("ExhibitionName", editTableCustomDialog.exhibitionName)
            formData.append("DisplayFilterInCalledlist", editTableCustomDialog.displayFilterInCalledList)

            formData.append("DisplayFilterInCreatorCalledList", editTableCustomDialog.displayFilterInCreatorCalledList)

            

           
            
            formData.append("fields", fields)
            formData.append("operators", operators)
            formData.append("values", values)
            formData.append("updatedat", editTableCustomDialog.updatedAt)
            formData.append("updateduser", editTableCustomDialog.updatedUser)

            let tableCustomFilterOrganizationIds = ""
            editTableCustomDialog.tableCustomFilterOrganizationIds.forEach(item => {
                tableCustomFilterOrganizationIds += `${item.id},`
            })

            formData.append("tableCustomFilterOrganizationIds", tableCustomFilterOrganizationIds)

            var result = await TableCustomFilterService.editItem(formData)
            if(result && result.success) {
                const intl = this.props.intl
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "edit.success" }),
                editTableCustomDialog: this.editTableCustomDialogInitialState
                }, () => {
                    this.closeListTableCustomDialog()
                    this.getFavoritesAndCountByUserIdAndTableName()
                    this.getFiltersByFlagDisplayFilterInCalledListNew()
                    //this.getFavoritesByUserIdAndTableName()
                    //this.getCountByUserIdAndTableName()
                })
            } else {
                const intl = this.props.intl
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }
        this.setState({ loading: false })
    }

    saveTableCustomFilter = async _ => {
        this.setState({ loading: true })
        
        const { userSession } = this.props
        const { createTableCustomDialog } = this.state
        if (!this.admGlobal && (this.isAdmOrg || this.isCoordenator) && !createTableCustomDialog.isPrivate && (createTableCustomDialog.tableCustomFilterOrganizationIds == null || (createTableCustomDialog.tableCustomFilterOrganizationIds && createTableCustomDialog.tableCustomFilterOrganizationIds.length == 0))) {
            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Para criar um filtro público, o campo 'Organização' é obrigatório"
            })
        } 
        else if(createTableCustomDialog.exhibitionName == null || createTableCustomDialog.exhibitionName == "" ){

            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "O campo Nome de exibição do filtro é obrigatório "
            })

        }else {
            let userId = userSession && userSession.user && userSession.user.id

            let fields = ''
            let operators = ''
            let values = ''

            let valueAtual = this.state.contentFilter.split("\n")
            valueAtual.map(item => {
                if (item != "") {
                    let itemSplit = item.split(" = ")
                    if(itemSplit && itemSplit[0] && itemSplit[1]) {
                        let idField = itemSplit[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplit[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplit[1] + '|'
                        operators += '2|'
                    }
                    let itemSplitEquals = item.split(" == ")
                    if(itemSplitEquals && itemSplitEquals[0] && itemSplitEquals[1]) {
                        let idField = itemSplitEquals[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitEquals[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitEquals[1] + '|'
                        operators += '1|'
                    }
                    let itemSplitStartsWith = item.split(" s= ")
                    if(itemSplitStartsWith && itemSplitStartsWith[0] && itemSplitStartsWith[1]) {
                        let idField = itemSplitStartsWith[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitStartsWith[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitStartsWith[1] + '|'
                        operators += '3|'
                    }
                    let itemSplitEndsWith = item.split(" f= ")
                    if(itemSplitEndsWith && itemSplitEndsWith[0] && itemSplitEndsWith[1]) {
                        let idField = itemSplitEndsWith[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitEndsWith[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitEndsWith[1] + '|'
                        operators += '4|'
                    }
                    let itemSplitIsEmpty = item.split(" e= ")
                    if(itemSplitIsEmpty && itemSplitIsEmpty[0] && itemSplitIsEmpty[1]) {
                        let idField = itemSplitIsEmpty[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitIsEmpty[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitIsEmpty[1] + '|'
                        operators += '5|'
                    }
                    let itemSplitIsNotEmpty = item.split(" !e= ")
                    if(itemSplitIsNotEmpty && itemSplitIsNotEmpty[0] && itemSplitIsNotEmpty[1]) {
                        let idField = itemSplitIsNotEmpty[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitIsNotEmpty[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitIsNotEmpty[1] + '|'
                        operators += '6|'
                    }
                    let itemSplitBiggerThen = item.split(" > ")
                    if(itemSplitBiggerThen && itemSplitBiggerThen[0] && itemSplitBiggerThen[1]) {
                        let idField = itemSplitBiggerThen[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitBiggerThen[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitBiggerThen[1] + '|'
                        operators += '7|'
                    }
                    let itemSplitLessThan = item.split(" < ")
                    if(itemSplitLessThan && itemSplitLessThan[0] && itemSplitLessThan[1]) {
                        let idField = itemSplitLessThan[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitLessThan[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitLessThan[1] + '|'
                        operators += '8|'
                    }
                    let itemSplitBiggerOrEqual = item.split(" >= ")
                    if(itemSplitBiggerOrEqual && itemSplitBiggerOrEqual[0] && itemSplitBiggerOrEqual[1]) {
                        let idField = itemSplitBiggerOrEqual[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitBiggerOrEqual[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitBiggerOrEqual[1] + '|'
                        operators += '9|'
                    }
                    let itemSplitLessOrEqual = item.split(" <= ")
                    if(itemSplitLessOrEqual && itemSplitLessOrEqual[0] && itemSplitLessOrEqual[1]) {
                        let idField = itemSplitLessOrEqual[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplitLessOrEqual[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id
                        fields += idField + '|'
                        values += itemSplitLessOrEqual[1] + '|'
                        operators += '10|'
                    }
                }
            })

            let { intl } = this.props
            let valueOrder = this.state.contentOrder.split("\n")
            valueOrder.map(item => {
                if (item != "") {
                    let itemSplit = item.split(` ${intl.formatMessage({id:"order.filter"})}: `)
                    if(itemSplit && itemSplit[0] && itemSplit[1]) {
                        let idField = itemSplit[0]
                        let idFieldFilter = this.state.filters.filter(f => f.name == itemSplit[0])
                        if(idFieldFilter && idFieldFilter.length > 0)
                            idField = idFieldFilter[0].id

                        let convertValue = itemSplit[1] == `${intl.formatMessage({id:"order.filter.ascending"})}` ? 1 : 2
                        fields += idField + '|'
                        values += convertValue + '|'
                        operators += '11|'
                    }
                }
            })

            let formData = new FormData();
            formData.append("userid", userId)
            formData.append("name", createTableCustomDialog.name)
            formData.append("table", "calleds")
            formData.append("isprivate", createTableCustomDialog.isPrivate)
            formData.append("isfavorite", createTableCustomDialog.isFavorite)
            formData.append("isDefault", createTableCustomDialog.isDefault)
            formData.append("ExhibitionName", createTableCustomDialog.exhibitionName)
            formData.append("DisplayFilterInCalledlist", createTableCustomDialog.displayFilterInCalledList)
            formData.append("fields", fields)
            formData.append("operators", operators)
            formData.append("values", values)
            formData.append("createdat", createTableCustomDialog.createdAt)
            formData.append("createduser", createTableCustomDialog.createdUser)

            let tableCustomFilterOrganizationIds = ""
            createTableCustomDialog.tableCustomFilterOrganizationIds.forEach(item => {
                tableCustomFilterOrganizationIds += `${item.id},`
            })

            formData.append("tableCustomFilterOrganizationIds", tableCustomFilterOrganizationIds)

            formData.append("isfinished", this.state.isFinished)

            formData.append("isarchived", this.state.isArchived)

            formData.append("iscanceled", this.state.isCanceled)

            var result = await TableCustomFilterService.addItem(formData)
            PapiroConsole.log(result)
            PapiroConsole.log("result do post aqui")
            if(result && result.success) {
                const intl = this.props.intl
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "add.success" }),
                createTableCustomDialog: this.createTableCustomDialogInitialState
                }, () => {
                    this.closeCreateTableCustomDialog()
                    this.getFavoritesAndCountByUserIdAndTableName()
                    this.getFiltersByFlagDisplayFilterInCalledListNew()
                    //this.getFavoritesByUserIdAndTableName()
                    //this.getCountByUserIdAndTableName()
                })
            } else {
                const intl = this.props.intl
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(result)
            }
        }
        this.setState({ loading: false })
    }

    async changeValues(stateName, value, text = '') {

        
        this.setState({ filterSelected: value });
    }

    changeValuesFilterType = (stateName, value, text = '') => {
        localStorage.setItem('newTypeFilter', `${value}`)
        this.setState({ filterType: value });
    }

    changeValuesEdit = (stateName, value, text = '') => {
        this.setState({ filterSelectedEdit: value });
    }

    changeValuesCreateTableCustomDialog = async (stateName, value, text = '') => {
        await this.setState(prevState => ({
            createTableCustomDialog: {
                ...prevState.createTableCustomDialog,
                [stateName]: value
            }
        }))
    }

    changeValuesEditTableCustomDialog = async (stateName, value, text = '') => {
        await this.setState(prevState => ({
            editTableCustomDialog: {
                ...prevState.editTableCustomDialog,
                [stateName]: value
            }
        }))
    }

    changeValuesCheckEmail = async (stateName, value, text = '') => {
        
        await this.setState({ organizationCheckEmail: value });
    }

    changeValuesPopulateCalledsSize = async (stateName, value, text = '') => {
        await this.setState({ populateCalledsSize: value });
    }

    openCheckEmail = () => {
        this.setState({ openCheckEmail: true });
    }

    closeCheckEmail = () => {
        this.setState({ openCheckEmail: false });
    }

    deleteItem(data, resolve) {
        const intl = this.props.intl;

        //console.log("DEL");
        let abl = this.context.can('Delete', 'Called');
        //console.log(abl);
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
            resolve(); return false;
        }
        Api.delete(`/calleds/${data.id}`)

            .then(async result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                        resultList: []
                    });

                    await this.getCalleds('', 1);
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });


    };

    setCountLoading() {


        this.setState({ countLoading: this.state.countLoading + 1, });

        if (this.state.countLoading >= 1) {
            this.setState({
                loading: false,
            });

        }

    }

    async componentDidMount() {

        PapiroConsole.log("entrei no did mount teste ------------------")
        const intl = this.props.intl;

        const { userSession } = this.props

        var userId = userSession && userSession.user && userSession.user.id

        let getAccordionDefault = localStorage.getItem(`openaccordionfilter${userId}`) ? localStorage.getItem(`openaccordionfilter${userId}`) : false

       
		PapiroConsole.log(getAccordionDefault)
        PapiroConsole.log("getAccordionDefault")
        this.setState({
            loading: true,
            openAccordionFilter : getAccordionDefault,
        });

        PapiroConsole.log(this.state.loading)
        PapiroConsole.log("loading aqui")

        

        if(this.props.location && this.props.location.state && this.props.location.state.origin ){

            this.getFiltersByFlagDisplayFilterInCalledListNew()
           


            if(this.props.location.state.origin == 'dashboardcards'){

                PapiroConsole.log(this.state.loading)
                PapiroConsole.log("loading aqui")
                //cards fixos
                if(this.props.location.state.field =="calledcards" && this.props.location.state.search){
                    if(this.props.location.state.search == intl.formatMessage({id:"expired.calleds.total.filter"})){
                        this.callSearch("calledcards", intl.formatMessage({id:"expired.calleds.total.filter"}), 1) 
    
                    }
                    else if (this.props.location.state.search == intl.formatMessage({id:"expired.calleds.today.filter"}) ){
                        this.callSearch("calledcards", intl.formatMessage({id:"expired.calleds.today.filter"}), 1)
    
                    }
                    else if(this.props.location.state.search == intl.formatMessage({id:"general.open.calleds"})){
                        this.callSearch("calledcards", intl.formatMessage({id:"general.open.calleds"}), 1)
                    }
                    else if(this.props.location.state.search == intl.formatMessage({id:"today.pending.calleds"})){
                        this.callSearch("calledcards", intl.formatMessage({id:"today.pending.calleds"}), 1)
                    }
                    else if(this.props.location.state.search == intl.formatMessage({id:"calleds.without.assigned"})){
                        this.callSearch("calledcards", intl.formatMessage({id:"calleds.without.assigned"}), 1)
                    }
                    else if(this.props.location.state.search == intl.formatMessage({id:"today.open.calleds"})){
                        PapiroConsole.log("segundo papiro ")
                        PapiroConsole.log("this.props.location.state.search")
                        this.callSearch("calledcards", intl.formatMessage({id:"today.open.calleds"}), 1)
                    }
                    else{
                        PapiroConsole.log("entrei aqui")
                        this.callSearch(this.props.location.state.field , this.props.location.state.search, 1)
                    }
    
                    

                    
                }

                

            }
            else if(this.props.location.state.origin == 'dashboardcardscreatedbyusers'){
                
                PapiroConsole.log("filtros aqui ")
                PapiroConsole.log(this.props.location.state.filters)
                this.props.location.state.filters.forEach(element => {

                    this.callSearch(element.field, element.value, 1) 
                    
                  });
            }

            if(!this.isClient){
                await this.getCalledsToCard(); 
                
            }

            
            
        }

        else{
                /*const { pathname } = this.props.location;
                let pathnameSplit = pathname.split('/')
                PapiroConsole.log(pathname)
                if (pathnameSplit && pathnameSplit.length > 0) {
                    PapiroConsole.log(pathnameSplit)
                    let routePage = pathnameSplit[pathnameSplit.length - 1]
                    if (routePage != null) {
                        PapiroConsole.log(routePage)
                        localStorage.removeItem('calledListPage')
                        localStorage.setItem('calledListPage', `${routePage}`)
                    }
                }*/
                localStorage.setItem('newOrderFilter', '');
                localStorage.setItem('newOrderSearch', '');

                let sessionPage = localStorage.getItem('calledsPage')
                if(sessionPage == null) {
                    sessionPage = 1
                    localStorage.setItem('calledsPage', '1')
                }
                if(!isNaN(sessionPage) && typeof sessionPage === 'string')
                    sessionPage = parseInt(sessionPage)


                PapiroConsole.log("entrada aqui 1 ")
                await this.addFilterOnComponentDidMount(intl)
                this.setState({ page: sessionPage })

                const { userSession } = this.props

                //if ((this.admGlobal && (userSession && userSession.user && (userSession.user.email == 'suporte@papiro.ai' || userSession.user.email == 'suporte@mexx.ai' || userSession.user.email == 'eduardo.lima@cmtech.com.br' || userSession.user.email == 'tpporto@gmail.com'))))
                //    this.getCalledCostCount()
                    
                await this.VertexIsAvaliable();
                this.getOrganizations();

                if (this.canStartCallCenter && !this.isClient && !this.state.checkMailAndVideoCallInsert) {
                    var itemCallCenter =
                        <Can I="Open" a="Called">
                            <Button
                                color="secondary"
                                size="large"
                                style={{ marginBottom: "5px" }}
                                onClick={this.videoCallClick}
                                startIcon={<VideoCallIcon />}
                                disabled={this.state.disabledvertex}
                            >
                                {intl.formatMessage({id:"video.call"})}
                            </Button>
                        </Can>;
                        if (this.state.itemsTree && !this.state.itemsTree.includes(itemCallCenter)) {
                        this.state.itemsTree.push(itemCallCenter);
                        }

                }
                if (this.isCheckEmail && !this.isClient && !this.state.checkMailAndVideoCallInsert) {
                    var itemEmail =
                        <Can I="Open" a="Called">
                            <Button
                                color="secondary"
                                size="large"
                                style={{ marginBottom: "5px" }}
                                onClick={this.openCheckEmail}
                                startIcon={<EmailIcon />}
                            >
                                {intl.formatMessage({id:"check.email"})}
                        </Button>
                        </Can>;
                    if (this.state.itemsTree && !this.state.itemsTree.includes(itemEmail)) {
                        this.state.itemsTree.push(itemEmail);
                    }

                }

                this.getFiltersByFlagDisplayFilterInCalledListNew()
                this.getFavoritesAndCountByUserIdAndTableName()
                
                this.setState({ checkMailAndVideoCallInsert: true })

                //if (this.props.match && this.props.match.params && this.props.match.params.lang && this.props.match.url  && this.props.match.url == `${this.props.match.params.lang}/archived-calleds`) {
                
                if (this.props.match && this.props.match.params && this.props.match.params.lang && this.props.match.url && this.props.match.url == `/${this.props.match.params.lang}/archived-calleds`) {
                    if(this.props.userSession && this.props.userSession.user && this.props.userSession.user && this.props.userSession.user.canuserviewarchivedcalleds == true )
                    {
                        PapiroConsole.log("entrei aqui")
                        
                        
                        await this.setState({ loading: false, isArchived: 1,isFinished:0, isCanceled: 0, emptySearchArchived : true, emptySearchFinishCalleds : false, emptySearchCanceled : false,  emptyArray : true, dataFilter: false })
                    // await this.getCalleds("archivedcallleds",  parseInt(sessionPage) );
                    
                        this.setState({ locationState: 'archivedcallleds' })
                    }
                    else{
                        this.props.history.push(`/${this.props.match.params.lang}/calleds`)
                        await this.getCalleds(this.props.location.state, parseInt(sessionPage));
                    }
                    
                }
                else if (this.props.match && this.props.match.params && this.props.match.params.lang && this.props.match.url && this.props.match.url == `/${this.props.match.params.lang}/canceled-calleds`) {
                   
                    
                        PapiroConsole.log("entrei aqui")
                        
                        
                        await this.setState({ loading: false, isCanceled: 1, isArchived: 0,isFinished:0, emptySearchCanceled : true, emptySearchArchived : false, emptySearchFinishCalleds : false, emptyArray : true, dataFilter: false })
                    // await this.getCalleds("archivedcallleds",  parseInt(sessionPage) );
                    
                        this.setState({ locationState: 'canceledcalleds' })
                    
                    
                    
                }
                else if (this.props.match && this.props.match.params && this.props.match.params.lang && this.props.match.url && this.props.match.url == `/${this.props.match.params.lang}/finishedCalleds`) {
                    
                    PapiroConsole.log("entrei aqui")
                    
                    
                    await this.setState({ loading: false, isArchived: 0,isFinished:1, isCanceled: 0, emptySearchFinishCalleds : true, emptySearchArchived : false,emptySearchCanceled : false, emptyArray : true, dataFilter: false })
                // await this.getCalleds("archivedcallleds",  parseInt(sessionPage) );
                
                    this.setState({ locationState: 'finishedCalleds' })
                
                
                
                }
                
                else{
                    await this.getCalleds(this.props.location.state, parseInt(sessionPage));
                }

                if(!this.isClient){
                    await this.getCalledsToCard(); 
                }

            
                

                //console.error('componentDidMount'); 
        }



        
        await this.getLastCalledsQueueByUserId(userSession.user.id)
    }
    async componentWillReceiveProps(nextProps) {


        PapiroConsole.log("=== componentWillReceiveProps ===")
        PapiroConsole.log(nextProps)
        PapiroConsole.log(this.props)
        if (nextProps && nextProps.location && nextProps.location.state != undefined)
        {
            PapiroConsole.log(nextProps)
            PapiroConsole.log(this.props)
            
            /*const { pathname } = this.props.location;
            let pathnameSplit = pathname.split('/')
            PapiroConsole.log(pathname)
            if (pathnameSplit && pathnameSplit.length > 0) {
                PapiroConsole.log(pathnameSplit)
                let routePage = pathnameSplit[pathnameSplit.length - 1]
                if (routePage != null) {
                    PapiroConsole.log(routePage)
                    localStorage.removeItem('calledListPage')
                    localStorage.setItem('calledListPage', `${routePage}`)
                }
            }*/
    
            this.setState({ countLoading: 0, });
            await this.setState({ filterSelected: 0, searchValue: '', page: 1, contentFilter: '', contentOrder: '' });
            let sessionPage = localStorage.getItem('calledsPage')
            if(sessionPage == null) {
                sessionPage = 1
                localStorage.setItem('calledsPage', '1')
            }
            if(!isNaN(sessionPage) && typeof sessionPage === 'string')
                sessionPage = parseInt(sessionPage)
            this.setState({ page: sessionPage })
            PapiroConsole.log("entrada aqui 2 ")
            await this.addFilterOnComponentDidMount(this.props.intl)
            if (nextProps.location.state === "calleds") {
                await this.setState({ loading: true, isFinished: 0,isArchived:0, isCanceled:0 })
                await this.getCalleds('', this.state.locationState === 'calleds' ? parseInt(sessionPage) : 1);
                
    
                await this.getFiltersByFlagDisplayFilterInCalledListNew()
    
                this.setState({ locationState: 'calleds', loading: false})
            }
            else if (nextProps.location.state === "assignedcalleds") {
                this.setState({ loading: true, isFinished: 0,isArchived:0, isCanceled:0 })
                await this.getCalleds(nextProps.location.state, this.state.locationState === 'assignedcalleds' ? parseInt(sessionPage) : 1)
                this.setState({ locationState: 'assignedcalleds'})
    
                
            }
            else if (nextProps.location.state === "finishedCalleds" ) {
                await this.setState({ loading: false, isFinished: 1, isArchived:0, isCanceled:0 })
                
                //await this.getCalleds(nextProps.location.state, this.state.locationState === 'finishedCalleds' ? parseInt(sessionPage) : 1);
                
                
                await this.getFiltersByFlagDisplayFilterInCalledListNew()
    
                this.setState({ loading: false, locationState: 'finishedCalleds', emptySearchFinishCalleds: true, emptySearchArchived : false, emptySearchCanceled : false, emptyArray : true, dataFilter: false, calledsList:[]})
                
            }
            else if (nextProps.location.state === "archivedcallleds") {
                PapiroConsole.log("entrei aqui")
                if(this.props.userSession && this.props.userSession.user && this.props.userSession.user && this.props.userSession.user.canuserviewarchivedcalleds == true ){
                    
                    
                    await this.setState({ loading: false, isArchived: 1,isFinished: 0, isCanceled:0 , emptySearchCanceled : false, emptySearchArchived : true, emptySearchFinishCalleds : false, emptyArray : true, dataFilter: false, calledsList:[]})
                    //await this.getCalleds(nextProps.location.state, this.state.locationState === 'archivedcallleds' ? parseInt(sessionPage) : 1);
                   
                    
    
                    await this.getFiltersByFlagDisplayFilterInCalledListNew()
    
                    this.setState({ locationState: 'archivedcallleds', loading: false })
                }
                else{
                    this.props.history.push(`/${this.props.match.params.lang}/calleds`)
                    await this.getCalleds('', this.state.locationState === 'calleds' ? parseInt(sessionPage) : 1);
                    
    
                    await this.getFiltersByFlagDisplayFilterInCalledListNew()
    
                    this.setState({ locationState: 'calleds', loading: false})
                     
                }
                
            }  
            else if (nextProps.location.state === "canceledcalleds") {
                
                
                    await this.setState({ loading: false, isArchived: 0,isFinished: 0, isCanceled:1 , emptySearchCanceled : true ,emptySearchArchived : false, emptySearchFinishCalleds : false, emptyArray : true, dataFilter: false, calledsList:[]})
                    
                    await this.getFiltersByFlagDisplayFilterInCalledListNew()
    
                    this.setState({ locationState: 'canceledcalleds', loading: false })
                
                
                
            }  
            /*else if (nextProps.location.state == "" ) {
    
                if(nextProps.location.pathname == `/${this.props.match.params.lang}/archived-calleds`){
                    await this.setState({ loading: false, isArchived: 1,isFinished: 0, })
                }
                else if(nextProps.location.pathname == `/${this.props.match.params.lang}/finishedCalleds`){
                    
                    await this.setState({ loading: false, isFinished: 1,isArchived: 0})
                }
                
            }*/
            else if (nextProps.headerMenu.refresh === true) {
                PapiroConsole.log("entrei aqui teste")
                this.props.reload(false);
                await this.getCalleds('', parseInt(sessionPage));
                if(!this.isClient){
                    await this.getCalledsToCard(); 
                  }
                
            }
        }
        
        
    }

    getLastCalledsQueueByUserId = async userId => {
        if (this.props.lastCalledsQueue == null || (this.props.lastCalledsQueue != null && (this.props.lastCalledsQueue.items == null || (this.props.lastCalledsQueue.items != null && Object.keys(this.props.lastCalledsQueue.items).length === 0 && this.props.lastCalledsQueue.items.constructor === Object)))) {
            let response = await getLastCalledsQueueByUserId(userId)
            if (response != null && response.success != null && response.success && response.data != null) {
            if (Array.isArray(response.data)) {
                response.data.forEach(item => {
                if (item.id != null && item.title != null)
                    this.props.equeuelastCalledsQueue({ id: item.id, title: item.title })
                })
            }
            } else {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: false,
                notificationVariant: 'error',
                notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(response)
            }
        }
    }

    getDefaultFilter = async () => {

        const { userSession, intl } = this.props
        let userId = userSession && userSession.user && userSession.user.id
        PapiroConsole.log("entrei nesse endpoint x vezes")
        let result = await TableCustomFilterService.getDefaultByUserIdAndTableName(userId, "calleds")
        if(result && result.success) {
            if(result.data && result.data.length > 0) {
                if(result.data[0].tablecustomfilter && result.data[0].tablecustomfilter.tablecustomfilterfields) {
                    let field = ''
                    let search = ''
                    let ofield = ''
                    let osearch = ''
                    result.data[0].tablecustomfilter.tablecustomfilterfields.map(item => {
                        let operator = ''
                        switch(item.operator) {
                            case 1:
                                operator = ' == '
                                break
                            case 2:
                                operator = ' = '
                                break
                            case 3:
                                operator = ' STARTS WITH '
                                break
                            case 4:
                                operator = ' ENDS WITH '
                                break
                            case 5:
                                operator = ' IS EMPTY '
                                break
                            case 6:
                                operator = ' IS NOT EMPTY '
                                break
                            case 7:
                                operator = ' BIGGER THEN '
                                break
                            case 8:
                                operator = ' LESS THAN '
                                break
                            case 9:
                                operator = ' BIGGER OR EQUAL '
                                break
                            case 10:
                                operator = ' LESS OR EQUAL '
                                break
                            case 11:
                                operator = ` ${intl.formatMessage({id:"order.filter"})}: `
                                break
                        }

                        if (item.operator != 11) {
                            field += `${item.field}|`
                            search += `${item.value}|`
                        } else {
                            ofield += `${item.field}|`
                            osearch += `${item.value}|`
                        }

                        let nameField = item.field
                        let filterName = this.state.filters.filter(f => f.id === item.field)
                        if(filterName && filterName.length > 0)
                            nameField = filterName[0].name

                        if (item.operator != 11) {
                            PapiroConsole.log("content filter atualizado 10")
                            this.setState({
                                contentFilter: `${this.state.contentFilter}${nameField}${operator}${item.value.trim()}\n`
                            });
                        } else {
                            this.setState({
                                contentOrder: `${this.state.contentOrder}${nameField}${operator}${item.value.trim() == 1 ? `${intl.formatMessage({id:"order.filter.ascending"})}` : `${intl.formatMessage({id:"order.filter.descending"})}`}\n`
                            });
                        }
                    })
                    localStorage.setItem('newFilter', field)
                    localStorage.setItem('newSearch', search)
                    localStorage.setItem('newOrderFilter', ofield)
                    localStorage.setItem('newOrderSearch', osearch)
                }
            }
        } else {
            const intl = this.props.intl
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
        }
    }

    calledListDataFormmat = (data) => {
      let selected = []

      if (!this.state.selectedColumnns.includes('calledid') && data.calledid === true)
        selected.push('calledid')
      if (!this.state.selectedColumnns.includes('calledorganization') && data.calledorganization === true)
        selected.push('calledorganization')
      if (!this.state.selectedColumnns.includes('calledtitle') && data.calledtitle === true)
        selected.push('calledtitle')
      if (!this.state.selectedColumnns.includes('calledcategory') && data.calledcategory === true)
        selected.push('calledcategory')
      if (!this.state.selectedColumnns.includes('calledtype') && data.calledtype === true)
        selected.push('calledtype')
      if (!this.state.selectedColumnns.includes('calleddepartament') && data.calleddepartament === true)
        selected.push('calleddepartament')
      if (!this.state.selectedColumnns.includes('calledslaservice') && data.calledslaservice === true)
        selected.push('calledslaservice')
      if (!this.state.selectedColumnns.includes('calledslasolution') && data.calledslasolution === true)
        selected.push('calledslasolution')
      if (!this.state.selectedColumnns.includes('calledstatus') && data.calledstatus === true)
        selected.push('calledstatus')
      if (!this.state.selectedColumnns.includes('calledchangestatus') && data.calledchangestatus === true)
        selected.push('calledchangestatus')
      if (!this.state.selectedColumnns.includes('calledrequester') && data.calledrequester === true)
        selected.push('calledrequester')
      if (!this.state.selectedColumnns.includes('calledassigner') && data.calledassigner === true)
        selected.push('calledassigner')
      if (!this.state.selectedColumnns.includes('calledaddress') && data.calledaddress === true)
        selected.push('calledaddress')
      if (!this.state.selectedColumnns.includes('calledclassification') && data.calledclassification === true)
        selected.push('calledclassification')
      if (!this.state.selectedColumnns.includes('calledopendate') && data.calledopendate === true)
        selected.push('calledopendate')
      if (!this.state.selectedColumnns.includes('calledduedate') && data.calledduedate === true)
        selected.push('calledduedate')
      if (!this.state.selectedColumnns.includes('calledslaserviceexpired') && data.calledslaserviceexpired === true)
        selected.push('calledslaserviceexpired')
      if (!this.state.selectedColumnns.includes('calledmenu') && data.calledmenu === true)
        selected.push('calledmenu')
      if (!this.state.selectedColumnns.includes('calledchangeassign') && data.calledchangeassign === true)
        selected.push('calledchangeassign')
/* 
 POSSIVEL CRIME!!!! POSSIVEL CRIME!!!! POSSIVEL CRIME!!!! POSSIVEL CRIME!!!!

      this.setState(prevState => ({
          selectedColumnns: [
              ...prevState.selectedColumnns,
              ...selected,
          ]
        }))
*/

		return selected;
    }
	arrayUnique(array) {
		var a = array.concat();
		for(var i=0; i<a.length; ++i) {
			for(var j=i+1; j<a.length; ++j) {
				if(a[i] === a[j])
					a.splice(j--, 1);
			}
		}
	
		return a;
	}

    async getCalleds(filter = '', page = 1) {

        PapiroConsole.log("filtro aqui")
        PapiroConsole.log(filter)
        const intl = this.props.intl
        const { userSession } = this.props
        this.emptySearchArchived = false
        this.emptySearchFinishCalleds = false
        this.emptySearchCanceledCalleds = false
        
        
        let url = `/calleds/paginate?page=${page}`;

        let newFilter = localStorage.getItem('newFilter');
        let newSearch = localStorage.getItem('newSearch');
        let newOrderFilter = localStorage.getItem('newOrderFilter');
        let newOrderSearch = localStorage.getItem('newOrderSearch');

        if (this.isClient) {
            
            url = `/calleds/org/paginate/${this.state.organizationId}?page=${page}`;

        }
        url += `&type=0`;

        if (newFilter != '' && newSearch != '') {

            url += `&search=${newSearch}&field=${newFilter}`;
            this.setState({ dataFilter: true });
        }
        if (newOrderFilter != '' && newOrderSearch != '') {
            url += `&osearch=${newOrderSearch}&ofield=${newOrderFilter}`
        }
        if (filter != '') {
            url = url + '&filter=' + filter;
        }

        url += `&isfinished=${this.state.isFinished}`;

        url += `&isarchived=${this.state.isArchived}`;

        url += `&iscanceled=${this.state.isCanceled}`;

        var formattedList = null

        this.setState({ selectedColumnns: [] })

        PapiroConsole.log(url)
        PapiroConsole.log("url aqui")

         await Api.get(url)
            .then(async result =>  {
                if (result.data.success) {
                    if (result.data.data.length === 0) {
                        if (page > 1) {
                            this.onChangePaginate(null, (page - 1))
                        }
                        //this.emptyArray = true
                        this.setState({ emptySearchArchived: false, emptySearchFinishCalleds:false,emptySearchCanceled : false, emptyArray: true});
                    }
                    else {
                        //this.emptyArray = false
                        this.setState({ emptySearchArchived: false, emptySearchFinishCalleds:false,emptySearchCanceled : false, emptyArray: false});
                    }

                    this.arrayConteudo = []

                    let count = result.data.count;

                   
                    
					let setCat = false
					let setType = false
					let tableTitles = []
                    PapiroConsole.log(result.data.data)
                    PapiroConsole.log("result.data.data CONSOLE AQUI")
					formattedList=result.data.data;
					let issetDepartamentsInList = false
					let canclientselectpatrimonyincalled = false
					let canclientviewslametricsincalled = false
					let selectedCols = []
                    formattedList = result.data.data.map(item => {
                        let times = "";
                        let cardItem ="";
                        let DepartamentName = ""
                        if(item && item.organizationdepartament && item.organizationdepartament.name) {
                            DepartamentName = item.organizationdepartament.name
                        }
                        let orgCat = item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name

                        if (item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientselecttypeincalled === true) {
                            setType = true
                            
                        }
                        if (item.organization && item.organization.organizationsettings && item.organization.organizationsettings.roleclientcanselectcategory === 1 || (item.organization.organizationsettings.canclientselecttypeincalled === false 
							&& item.organization.organizationsettings.roleclientcanselectcategory === 0)) {
                            setCat = true
                           
                        }


                        let onlyType = setType ? item.type.name : '---'
                        let onlyCategory = setCat ? item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name : '---'
                        let categoryAndType = `${onlyCategory} / ${onlyType}`

                        if (setType === true && setCat === false)
                            orgCat = this.state.categorySetting ? categoryAndType : onlyType
                        else if (setType === true && setCat === true)
                            orgCat = categoryAndType
                        else
                            orgCat = this.state.typeSetting ? categoryAndType : onlyCategory;

                        if(item && item.techinicalteam && item.techinicalteam.name && item.techinicalteam.name.length > 0) {
                            times = item.techinicalteam.name
                        } else if (item.organizationcategory.techinicalteamorganizationcategories != null) {
                            item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                                times = times + item2.techinicalteam.name + ","
                            });
                            times = times.substring(0, times.length - 1);
                        }

                        let CallDueDate = <Tooltip title={<h5>{intl.formatMessage({id:"attended.sla"})}</h5>}><AccessTimeIcon style={{ color: '#43a047', fill: '#43a047' }} /></Tooltip>

						let service = getSlaAtendimento(item, this.props.userSession)
					    let solution = getSlaSolucao(item, this.props.userSession)

                        service = service ? service.toFixed(2) : 0
                        solution = solution ? solution.toFixed(2) : 0

                    

                        

                        let slaSolution = item.slasolution ? item.slasolution.toFixed(2) + "%" : solution + "%"
                        let slaService = item.slaservice ? item.slaservice.toFixed(2) + "%" : service + "%"

                        let slaSolutionN = item.slasolution ? item.slasolution.toFixed(2) : solution
                        let slaServiceN = item.slaservice ? item.slaservice.toFixed(2) : service
                        
                        if(item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientviewslametricsincalled 
							&& item.organization.organizationsettings.canclientviewslametricsincalled === true) {
                            let slaSol = ""
                        } else {
                            if(this.isClient) {
                                slaSolution = "----"
                                slaService = "----"
                            }
                        }


                        if(service && service >= 100)
                        	CallDueDate =<Tooltip title={<h5>{intl.formatMessage({id:"expired"})}</h5>}><AccessTimeIcon style={{ color: '#CC1717', fill: '#CC1717' }} /></ Tooltip>;

						if(item && item.organizationdepartament && item.organizationdepartament.name){
							issetDepartamentsInList = true;
						}

						if(item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientselectpatrimonyincalled && item.organization.organizationsettings.canclientselectpatrimonyincalled === true)
						  canclientselectpatrimonyincalled = true;

						if(item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientviewslametricsincalled && item.organization.organizationsettings.canclientviewslametricsincalled === true)
						  canclientviewslametricsincalled = true;

						  const patrimonyList = item.calledorganizationpatrimonies;

						  let patrimonies = '';
						  if (patrimonyList) {
							  patrimonyList.forEach(item => {
								  if (item.organizationpatrimony) {
									  patrimonies = patrimonies + `${item.organizationpatrimony.tipping}(${item.organizationpatrimony.serialnumber}), `;
									 
								  }
							  });
							  patrimonies = patrimonies.slice(0, -2);
						  }
						  if (item && item.calledlistdata != null){
  						     let cols = this.calledListDataFormmat(item.calledlistdata);
 							 selectedCols = this.arrayUnique(selectedCols.concat(cols)); 

						  }

                         /*

						*/

						let newitem={
							...item,

							statusName: item.status.name,
                            patrimonies,
                            OrganizationCategory: item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name,
                            Organization: item.organization.name,
                            slaAtendimento: slaService,
                            slaSolucao: slaSolution,
                            service: slaServiceN,
                            solution: slaSolutionN,
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : "",
                            time: times,
                            addressname: item.address.name,
                            opening: moment(item.openingdate).format("DD/MM/YYYY"),
                            openingHour: moment(item.openingdate).format("HH:mm"),
                            dueDt: moment(item.duedate).format("DD/MM/YYYY"),
                            dueHour: moment(item.duedate).format("HH:mm"),
                            solutionDt: item.solutiondate != null ? moment(item.solutiondate).format("DD/MM/YYYY") : null,
                            solutionHour: item.solutiondate != null ? moment(item.solutiondate).format("HH:mm") : null,
                            DateCreatedAt: moment(item.openingdate).format("DD/MM/YYYY HH:mm"),
                            classification: item.calledclassification ? <Rating name="read-only" value={item.calledclassification.noteassigned} readOnly /> : 'N/A',
                            typeName: item.type.name,
                            originRequestName: item.originrequest.name,
                            cardItem: cardItem,
                            orgCat: orgCat,
                            DepartamentName: DepartamentName,
                            CallDueDate: CallDueDate,
							calledlistdata: item.calledlistdata
						}

						let popupData =[];
						if(this.isClient){
							popupData = this.popupColumnsClient.map(({ title, field }) => {
								return { title, value: newitem[field] }
							});
	
						}
						else{
							popupData = this.popupColumns.map(({ title, field }) => {
								return { title, value: newitem[field] }
							});
						}
                        
                        return {
                            ...newitem,
							
							/*
							statusName: "",
                            patrimonies: "",
                            OrganizationCategory: "",
                            Organization: "",
                            slaAtendimento:"",
                            slaSolucao: "",
                            assignedUser: "",
                            requestingUser: "",
                            time: "",
                            addressname: "",
                            opening: "",
                            DateCreatedAt: "",
                            classification:  'N/A',
                            typeName: "",
                            originRequestName: "",
                            cardItem: "",
                            orgCat: "",
                            DepartamentName: "",
                            CallDueDate: "",
							*/
							//address: null,
							//assigneduser: null,
							//calledhistorychecks: null,
							//calledstatus: null,
							//organization: null,
							//organizationcalendarevents: null,
							//organizationcategory: null,
							//originrequest: null,
							//priority: null,
							//requestinguser: null,
							//status: null,
							//type: null,
							actions: this.isClient ? null :  <div style={{display:'flex', flexDirection:'row'}}>
										<IconButton
											size="small"
											onClick={() =>  this.goToCalled(newitem, true)}
											>
											<CallMade />
										</IconButton>
										<IconButton size="small">
											<TablePopup data={popupData} />
										</IconButton>
										<IconButton
											size="small"
											onClick={() =>  this.openConfirmAssignCalledDialog(newitem)}
											>
											<PanToolIcon style={{ color: '#58595B', fill: '#58595B' }} />
										</IconButton>

							</div>,
							onlyId: <LinkA component="button"
							onClick={() => {
								let abl = this.context.can('Answer', 'Called');
								let abl2 = this.context.can('Edit', 'Called');
								if (abl == false && abl2 == false) {
									
									this.showMessage( true, 'error', intl.formatMessage({id:"called.list.message.unallowed.task"}));
									return false;
								}
								if (newitem.assigneduser != null) {
									if (newitem.assigneduser.id != userSession.user.id) {
										if (newitem.organization.organizationsettings.canviewcalledfromothertechnical == false && this.isAdm == false) {
											this.showMessage( true, 'error', intl.formatMessage({id:"caled.list.message.unallowed.user"}));
											return false;
										}
									}
								}
			
								this.goToCalled(newitem, false)
							}
							} variant="body2">
							{newitem.id}
						  </LinkA>


                        }
                    });

					


					TableTitles = tableTitles;
					tableTitles = []; // foi necessário zerar o state tableTitles pois com ele carregado já com algum valor e chamando a funcao abaixo "setColumnTitles"
					// que já carrega ele novamente estava travando o sistema no container(POD KUBE) estourando a memoria do javascript no cliente(navegador)
					// Analisamos a lógica e vimos que não precisava trabalhar essa variável aqui, tendo em vista que sempre os titulos serão setados em "setColumnTitles"

					var pagination = this.setPagination(count, page, 10);
					//console.log("selectedCols");
					//console.log(selectedCols);

				

                    await this.setState({loading: false,typeSetting: setType, count: count,categorySetting: setCat,tableTitles: tableTitles ,calledsList: formattedList
						, pageStart: pagination.pageStart, pageEnd: pagination.pageEnd, totalPages: pagination.totalPages, page: pagination.page
					, selectedColumnns: selectedCols});

                    this.setCountLoading();
                    TableTitles = await this.setColumnTitles();
					

                } else {
                    // console.log("erro this.getcalleds")
                    this.setState({
                        openNotification: true, notificationVariant: 'error',
                        //notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                    this.setCountLoading();

                }
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
                this.setCountLoading();

            });
    };

    setColumnTitles = async () => {
        const { intl, userSession } = this.props
        let listTitles = []

        let issetDepartamentsInList = false
        this.state.calledsList.filter(item => {
            if(item && item.organizationdepartament && item.organizationdepartament.name)
                issetDepartamentsInList = true
        })

        if (this.isAdm) {
            
            if (this.state.selectedColumnns.length > 0) {
                if (this.state.selectedColumnns.includes('calledslaserviceexpired'))
                    listTitles.push({ title: intl.formatMessage({id:"situation"}), field: 'CallDueDate' })
                if (this.state.selectedColumnns.includes('calledid'))
                    listTitles.push({ title: intl.formatMessage({ id: "id" }), field: 'id' })
                if (this.state.selectedColumnns.includes('calledorganization'))
                    listTitles.push({ title: intl.formatMessage({ id: "organization" }), field: 'Organization' })
                if (this.state.selectedColumnns.includes('calledtitle'))
                    listTitles.push({ title: intl.formatMessage({ id: "title" }), field: 'title' })
                if (this.state.selectedColumnns.includes('calledcategory'))
                    listTitles.push({ title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' })
                if (this.state.selectedColumnns.includes('calledtype'))
                    listTitles.push({ title: intl.formatMessage({ id: "type" }), field: 'typeName' })
                if (this.state.selectedColumnns.includes('calleddepartament') && issetDepartamentsInList)
                    listTitles.push({ title: intl.formatMessage({ id: "departament" }), field: 'DepartamentName' })
                if (this.state.selectedColumnns.includes('calledslaservice'))
                    listTitles.push({ title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' })
                if (this.state.selectedColumnns.includes('calledslasolution'))
                    listTitles.push({ title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' })
                if (this.state.selectedColumnns.includes('calledstatus'))
                    listTitles.push({ title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName', })
                if (this.state.selectedColumnns.includes('calledrequester'))
                    listTitles.push({ title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' })
                if (this.state.selectedColumnns.includes('calledassigner'))
                    listTitles.push({ title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' })
                if (this.state.selectedColumnns.includes('calledaddress'))
                    listTitles.push({ title: intl.formatMessage({ id: "location" }), field: 'addressname' })
                if (this.state.selectedColumnns.includes('calledclassification'))
                    listTitles.push({ title: intl.formatMessage({ id: "classification" }), field: 'classification' })
                if (this.state.selectedColumnns.includes('calledopendate'))
                    listTitles.push({ title: intl.formatMessage({ id: "creation.date" }), field: 'DateCreatedAt' })
                if (this.state.selectedColumnns.includes('calledduedate'))
                    listTitles.push({ title: intl.formatMessage({ id: "estimated.solution.date" }), field: 'DateCreatedAt' })

                //console.log(listTitles)
            } else {
                listTitles.push(
                    { title: intl.formatMessage({id:"situation"}), field: 'CallDueDate' },
                    { title: intl.formatMessage({ id: "id" }), field: 'id' },
                    { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                    { title: intl.formatMessage({ id: "title" }), field: 'title' },
                    { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' }
                )

                if(issetDepartamentsInList) {
                    listTitles.push({ title: intl.formatMessage({ id: "departament" }), field: 'DepartamentName' })
                }

                listTitles.push(
                    { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
                    { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                    { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName', },
                    { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' },
                    { title: intl.formatMessage({ id: "location" }), field: 'addressname' },
                    { title: intl.formatMessage({id:"classification"}), field: 'classification' },
                    { title: intl.formatMessage({ id: "creation.date" }), field: 'DateCreatedAt' },
                    { title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' }
                )
            }
			listTitles.splice( 0,0 , { title: intl.formatMessage({id:"actions"}), field: 'actions' },);
		} else if (this.isAdm == false && this.isClient) {
            if (this.state.selectedColumnns.length > 0) {
                if (this.state.selectedColumnns.includes('calledslaserviceexpired'))
                    listTitles.push({ title: intl.formatMessage({id:"situation"}), field: 'CallDueDate' })
                if (this.state.selectedColumnns.includes('calledid'))
                    listTitles.push({ title: intl.formatMessage({ id: "id" }), field: 'id' })
                if (this.state.selectedColumnns.includes('calledorganization'))
                    listTitles.push({ title: intl.formatMessage({ id: "organization" }), field: 'Organization' })
                if (this.state.selectedColumnns.includes('calledtitle'))
                    listTitles.push({ title: intl.formatMessage({ id: "title" }), field: 'title' })
                if (this.state.selectedColumnns.includes('calledcategory'))
                    listTitles.push({ title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' })
                if (this.state.selectedColumnns.includes('calledtype'))
                    listTitles.push({ title: intl.formatMessage({ id: "type" }), field: 'typeName' })
                if (this.state.selectedColumnns.includes('calleddepartament') && issetDepartamentsInList)
                    listTitles.push({ title: intl.formatMessage({ id: "departament" }), field: 'DepartamentName' })
                if (this.state.selectedColumnns.includes('calledslaservice'))
                    listTitles.push({ title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' })
                if (this.state.selectedColumnns.includes('calledslasolution'))
                    listTitles.push({ title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' })
                if (this.state.selectedColumnns.includes('calledstatus'))
                    listTitles.push({ title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName', })
                if (this.state.selectedColumnns.includes('calledrequester'))
                    listTitles.push({ title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' })
                if (this.state.selectedColumnns.includes('calledassigner'))
                    listTitles.push({ title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' })
                if (this.state.selectedColumnns.includes('calledaddress'))
                    listTitles.push({ title: intl.formatMessage({ id: "location" }), field: 'addressname' })
                if (this.state.selectedColumnns.includes('calledclassification'))
                    listTitles.push({ title: intl.formatMessage({id:"classification"}), field: 'classification' })
                if (this.state.selectedColumnns.includes('calledopendate'))
                    listTitles.push({ title: intl.formatMessage({ id: "creation.date" }), field: 'DateCreatedAt' })
                if (this.state.selectedColumnns.includes('calledduedate'))
                    listTitles.push({ title: intl.formatMessage({id:"estimated.solution.date"}), field: 'DateCreatedAt' })
            } else {
                listTitles = [
                    { title: intl.formatMessage({ id: "id" }), field: 'id' },
                ]

                if(userSession && userSession.user && userSession.user.organizationusers && userSession.user.organizationusers.length > 1) {
                    listTitles.push({ title: intl.formatMessage({ id: "organization" }), field: 'Organization' })
                }

                listTitles.push(
                    { title: intl.formatMessage({id:"title"}), field: 'title' },
                    { title: (this.state.categorySetting && this.state.typeSetting) ? intl.formatMessage({ id: "category.and.type" }) : (!this.state.categorySetting && this.state.typeSetting) ? intl.formatMessage({ id: "type" }) : intl.formatMessage({ id: "category" }), field: 'orgCat' },
                )

                let canclientselectpatrimonyincalled = false
                let canclientviewslametricsincalled = false

                let listFilter = this.state.calledsList.filter(item => {
                    if(item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientselectpatrimonyincalled && item.organization.organizationsettings.canclientselectpatrimonyincalled === true)
                        canclientselectpatrimonyincalled = true
                    if(item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.canclientviewslametricsincalled && item.organization.organizationsettings.canclientviewslametricsincalled === true)
                        canclientviewslametricsincalled = true
                })

                if(canclientselectpatrimonyincalled) {
                    listTitles.push({ title: intl.formatMessage({id:"menu.organizationpatrimonies"}), field: 'patrimonies' })
                }

                listTitles.push({ title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName' })

                if(canclientviewslametricsincalled) {
                    listTitles.push(
                        { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
                        { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' }
                    )
                }

                listTitles.push(
                    { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' },
                    { title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' }
                )
            }
        } else {
            if (this.state.selectedColumnns.length > 0) {
                if (this.state.selectedColumnns.includes('calledslaserviceexpired'))
                    listTitles.push({ title: intl.formatMessage({id:"situation"}), field: 'CallDueDate' })
                if (this.state.selectedColumnns.includes('calledid'))
                    listTitles.push({ title: intl.formatMessage({ id: "id" }), field: 'id' })
                if (this.state.selectedColumnns.includes('calledorganization'))
                    listTitles.push({ title: intl.formatMessage({ id: "organization" }), field: 'Organization' })
                if (this.state.selectedColumnns.includes('calledtitle'))
                    listTitles.push({ title: intl.formatMessage({ id: "title" }), field: 'title' })
                if (this.state.selectedColumnns.includes('calledcategory'))
                    listTitles.push({ title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' })
                if (this.state.selectedColumnns.includes('calledtype'))
                    listTitles.push({ title: intl.formatMessage({ id: "type" }), field: 'typeName' })
                if (this.state.selectedColumnns.includes('calleddepartament') && issetDepartamentsInList)
                    listTitles.push({ title: intl.formatMessage({ id: "departament" }), field: 'DepartamentName' })
                if (this.state.selectedColumnns.includes('calledslaservice'))
                    listTitles.push({ title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' })
                if (this.state.selectedColumnns.includes('calledslasolution'))
                    listTitles.push({ title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' })
                if (this.state.selectedColumnns.includes('calledstatus'))
                    listTitles.push({ title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName', })
                if (this.state.selectedColumnns.includes('calledrequester'))
                    listTitles.push({ title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' })
                if (this.state.selectedColumnns.includes('calledassigner'))
                    listTitles.push({ title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' })
                if (this.state.selectedColumnns.includes('calledaddress'))
                    listTitles.push({ title: intl.formatMessage({ id: "location" }), field: 'addressname' })
                if (this.state.selectedColumnns.includes('calledclassification'))
                    listTitles.push({ title: intl.formatMessage({id:"classification"}), field: 'classification' })
                if (this.state.selectedColumnns.includes('calledopendate'))
                    listTitles.push({ title: intl.formatMessage({ id: "creation.date" }), field: 'DateCreatedAt' })
                if (this.state.selectedColumnns.includes('calledduedate'))
                    listTitles.push({ title: intl.formatMessage({id:"estimated.solution.date"}), field: 'DateCreatedAt' })
            } else {
                listTitles = [
                    //
                    { title: intl.formatMessage({id:"situation"}), field: 'CallDueDate' },
                    //
                    { title: intl.formatMessage({ id: "id" }), field: 'id' },
                    { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                    { title: intl.formatMessage({id:"title"}), field: 'title' },
                    { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' },
                    { title: intl.formatMessage({id:"menu.organizationpatrimonies"}), field: 'patrimonies' },
                    { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName' },
                    { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
                    { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                    { title: intl.formatMessage({ id: "location" }), field: 'addressname' },
                    { title: intl.formatMessage({ id: "requestuser" }), field: 'requestingUser' },
                    { title: intl.formatMessage({id:"assigned"}), field: 'assignedUser' }
                ]
            }
			listTitles.splice( 0,0 , { title: intl.formatMessage({id:"actions"}), field: 'actions' },);
		}

        this.setState({ tableTitles: listTitles })
		return listTitles;
    }

    populateAllCalleds = async _ => {
        const intl = this.props.intl;

        this.setState({ loading: true });
        PapiroConsole.log("=== populateAllCalleds ===")
        PapiroConsole.log(this.state.populateCalledsSize)
        if(this.state.calledCountNotTimeData > 0 && this.state.populateCalledsSize && this.state.populateCalledsSize > 0) {
            Api.get(`/calleds/papulateCalledTime/statustype/5/hastimedata/false/size/${this.state.populateCalledsSize}`)
            .then(async result => {
                if (result && result.data && result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: 'Métricas de tempo dos chamados salvas com sucesso'
                    });
                    if(result.data.data != null) {
                        this.setState({
                            calledCountNotTimeData: result.data.data.calledtimecount
                        });
                    }
                    this.closePopulateCalledsDialog()
                }
            }).catch((err) => {
                
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        err.response &&
                        err.response.data &&
                        err.response.data.errors &&
                        err.response.data.errors[0]
                        ? err.response.data.errors[0]
                        : intl.formatMessage({ id: "process.error" }),
                    });
                    Api.kickoff(err);
                });
        } else if (this.state.calledCountNotTimeData == 0) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'info',
                notificationMessage: 'Métricas de tempo dos chamados já foram populadas'
            });
            this.closePopulateCalledsDialog()
        } else {
            this.setState({ loading: false })
        }
    }

    getCalledCostCount = async _ => {
        this.setState({ loading: true });

        Api.get(`/calleds/count/statustype/5/hastimedata/false`)
        .then(async result => {
            if (result && result.data && result.data.success) {
                if(result.data.data != null) {
                    this.setState({
                        calledCountNotTimeData: result.data.data.calledtimecount
                    });
                }
            }
        }).catch((err) => {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                    err.response &&
                    err.response.data &&
                    err.response.data.errors &&
                    err.response.data.errors[0]
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
            });
            Api.kickoff(err);
        });
    }

    assignCalled = async () => {
        let calledToAssign = this.state.calledToAssign
        if(calledToAssign != null) {
            const { userSession } = this.props;
            this.loading(true);

            let data = new FormData()
            data.append("calledid", calledToAssign.id)
            data.append("userid", userSession.user.id)

            await Api.post(`/calleds/canassignuser/team`, data)
                .then(async result => {
                    if (result.data.success) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({id:"called.list.notification.called.taken"}),
                        });

                        this.goToCalled(calledToAssign)
                    }
                    this.componentDidMount()
                })
                .catch((err) => {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response &&
                                err.response.data &&
                                err.response.data.errors &&
                                err.response.data.errors[0]
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });
                    if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[1] && err.response.data.errors[1] === "Called_Assigned_By_Other_User") {
                        let calledToAssignId = calledToAssign.id
                        let calledList = this.state.calledsList
                        let calledListItemById = calledList.find(item => item.id === calledToAssignId)
                        calledListItemById.canAssign = false
                        let otherCalledListItems = calledList.filter(item => item.id !== calledToAssignId)
                        calledList = [...otherCalledListItems, calledListItemById]
                        calledList = calledList.sort((a, b) => a.id - b.id)

                        this.setState({ calledList: calledList })
                    }
                    Api.kickoff(err);
                });
            this.loading(false);
            this.setState({ confirmAssignCalledDialogOpen: false, calledToAssign: null })
        }
    }

    goToCalled = async (rowData, newTab = false) => {
        
        this.setState({ loading: true })
        let newassigned = rowData.assigneduser;
		this.setState({ loading: true})
        if(rowData.assigneduser){
            
            if(rowData.assigneduser.email == null || (rowData.assigneduser.email && (rowData.assigneduser.email.length ===  0 || rowData.assigneduser.email.length === 'undefined'))) {
               
                newassigned.name = `${rowData.assigneduser.name}`
            } else {
                
                newassigned.name = `${rowData.assigneduser.name} - ${rowData.assigneduser.email}`
            }
        }

        rowData.assigneduser = newassigned

		// COMENTADO POIS FOI RETIRADO DA API OS INCLUDES, O REDUX VEM SEM NENHUM DADO DE INCLUDE, COM AS LINHAS COMENTADAS, A PAGINA DE EDICAO BUSCA TODOS OS DADOS
        await this.props.addCurrentCalled(rowData);
        await this.props.addCalled(rowData);

        await this.props.addAccompanimentsList([]);

        await this.props.addAccompanimentsListInCalled([], rowData.id);
        if (!this.isClient && rowData.status.id == 3) { // validacao de rejeicao
           
            this.props.history.push(`/${this.props.match.params.lang}/called-rejection-edit`);
            //this.loading(false);
        } else {
            
            if (newTab == false) {
                
                this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${rowData.id}`);
                //this.loading(false);
            } else {
               
                let urlRedirect = "/" + this.props.match.params.lang + "/called-accompaniment/" + rowData.id;

                 setTimeout(() => {
                    window.open(urlRedirect, "_blank");
                    this.setState({ loading: false })
                }, 1000);
            }
        }
    }

    async getById(id, newTab = false) {
        let url = `/calleds/calledInfoPaginate/${id}`;
        let data = {}


        this.loading(true);

        Api.get(url)
            .then(async result => {
                if (result.data.success) {
                    let item = result.data.data
                    let times = "";
                    let newassigned = item.assigneduser;

                    if(item.assigneduser){
                        newassigned.name = item.assigneduser.name + " - " + item.assigneduser.email;
                    }

                    data = {
                        ...item,
                        typeName: item.type.name,
                        originRequestName: item.originrequest.name,
                        requestingUser: item.requestinguser ? item.requestinguser.name : "",
                        time: times,
                        opening: moment(item.openingdate).format("DD/MM/YYYY"),
                        assigneduser: newassigned
                    }

                    await this.props.addCurrentCalled(data);

                    PapiroConsole.log("entrei nesses dados aqui")
                    await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments : null);

                    await this.props.addCalled(data);
                    await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments : [], data.id);

                    if (!this.isClient && item.status.id == 3) { // validacao de rejeicao
                        this.props.history.push(`/${this.props.match.params.lang}/called-rejection-edit`);
                        this.loading(false);
                    }
                    else {
                        if (newTab == false) {
                            this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${data.id}`);
                            this.loading(false);
                        } else {
                            let urlRedirect = "/" + this.props.match.params.lang + "/called-accompaniment/" + data.id;

                            await setTimeout(() => {
                                window.open(urlRedirect, "_blank");
                                this.setState({ loading: false })
                            }, 3000);
                        }
                    }
                }
                else {
                    // console.log("Aqui 3");
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    // console.log(result);
                    Api.kickoff(result);
                }
            })
            .catch((err) => {
                
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        err.response &&
                            err.response.data &&
                            err.response.data.errors &&
                            err.response.data.errors[0]
                            ? err.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
                Api.kickoff(err);
            });
    };

    setAccordion(){

        const { userSession } = this.props

        var userId = userSession && userSession.user && userSession.user.id

        this.setState({ openAccordionFilter: !this.state.openAccordionFilter })
        localStorage.setItem(`openaccordionfilter${userId}`, !this.state.openAccordionFilter  )
        
    }

    async checkEmail() {
        this.setState({ loading: true });
        
        await Api.get('/emails/organization/' + this.state.organizationCheckEmail).then(result => {

            if (result.data.success) {
                const intl = this.props.intl;
                if (result.data.data > 1) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.was.created"}) + result.data.data + intl.formatMessage({id:"called.list.notification.called.with.success"}),
                        resultList: []
                    });
                } else if (result.data.data == 1) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.was.created.plural"}) + result.data.data + intl.formatMessage({id:"called.list.notification.called.with.success.plural"}),
                        resultList: []
                    });
                } else {

                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.empty.mailbox"}),
                        resultList: []
                    });
                    Api.kickoff(result);
                }

                this.componentDidMount();
            }
        }).catch(err => {
            const intl = this.props.intl;
            // console.log(err);
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(err);

        });;

        this.setState({ loading: false });
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    handleDelete(chipToDelete) {
        const intl = this.props.intl;
        let valueAtual = this.state.contentFilter.split("\n")
        let newvalue = "";
        let newFilter = ""
        let newSearch = ""
        PapiroConsole.log(valueAtual)
        PapiroConsole.log("valueAtual")
        valueAtual.map(item => {
            if (item != "" && item != valueAtual[chipToDelete]) {
                newvalue = newvalue + item + " \n"
                let itemSplit = item.split(" = ")
                PapiroConsole.log("itemSplit")
                PapiroConsole.log(itemSplit)
                
                if(itemSplit && itemSplit[0] && itemSplit[1]) {
                    let nameField = itemSplit[0]
                    let filterName = this.state.filters.filter(f => f.name === itemSplit[0])
                    PapiroConsole.log("nameField")
                    PapiroConsole.log("filterName")
                    PapiroConsole.log(nameField)
                    PapiroConsole.log(filterName)
                    
                    if(filterName && filterName.length > 0){
                        nameField = filterName[0].id
                        PapiroConsole.log("nameField")
                        PapiroConsole.log(nameField)
                    }
                    else if (nameField == intl.formatMessage({ id: "called.report.header" }) ){
                        nameField = "calledcards"
                    }
                        
                    
                    
                    newFilter += nameField + '|'
                    newSearch += itemSplit[1] + '|'
                }
            }
        })
        PapiroConsole.log("content filter atualizado 11")
        this.setState({
            contentFilter: newvalue
        });
        console.log("newFilter aqui 3")
        console.log(newFilter)
        localStorage.setItem("newFilter", newFilter);
        localStorage.setItem("newSearch", newSearch);
        PapiroConsole.log("variaveis de filtro")
        PapiroConsole.log(this.state.isArchived)
        PapiroConsole.log(this.state.isFinished)
        PapiroConsole.log(this.state.isCanceled)
        
        if(this.state.isArchived == 0 && this.state.isFinished == 0 && this.state.isCanceled == 0  ){
            PapiroConsole.log("entrei nesse if de teste")
            PapiroConsole.log(newvalue)
            PapiroConsole.log(this.state.contentOrder)
            if (newvalue == "" && this.state.contentOrder == "") {
                this.clearFields()
            } else {    
                    this.callSearch()  
            }

        }
        
    };

    handleOrderDelete(chipToDelete) {
        let valueAtual = this.state.contentOrder.split("\n")
        let newvalue = "";
        let newFilter = ""
        let newSearch = ""
        valueAtual.map(item => {
            if (item != "" && item != valueAtual[chipToDelete]) {
                newvalue = newvalue + item + " \n"
                let itemSplit = item.split(" = ")
                if(itemSplit && itemSplit[0] && itemSplit[1]) {
                    let nameField = itemSplit[0]
                    let filterName = this.state.filters.filter(f => f.name === itemSplit[0])
                    if(filterName && filterName.length > 0)
                        nameField = filterName[0].id
                    newFilter += nameField + '|'
                    newSearch += itemSplit[1] + '|'
                }
            }
        })

        this.setState({
            contentOrder: newvalue
        });

        localStorage.setItem("newOrderFilter", newFilter);
        localStorage.setItem("newOrderSearch", newSearch);

        if (newvalue == "" && this.state.contentFilter == "") {
            this.clearFields()
        } else {
            this.callSearch()
        }

    };

    handleDeleteFilterEdit = (chipToDelete) => {
        let currentvalue = this.state.contentFilterEdit.split("\n")
        var newvalue = "";
        let newFilter = ""
        let newSearch = ""
        currentvalue.map(item => {
            if (item != "" && item != currentvalue[chipToDelete]) {
                newvalue = newvalue + item + " \n"
                let itemSplit = item.split(" = ")
                if(itemSplit && itemSplit[0] && itemSplit[1]) {
                    let nameField = itemSplit[0]
                    let filterName = this.state.filters.filter(f => f.name === itemSplit[0])
                    if(filterName && filterName.length > 0)
                        nameField = filterName[0].id
                    newFilter += nameField + '|'
                    newSearch += itemSplit[1] + '|'
                }
            }
        })
        this.setState({
            contentFilterEdit: newvalue
        });
    }

    handleDeleteOrderEdit = (chipToDelete) => {
        let valueAtual = this.state.contentOrderEdit.split("\n")
        let newvalue = "";
        let newFilter = ""
        let newSearch = ""
        valueAtual.map(item => {
            if (item != "" && item != valueAtual[chipToDelete]) {
                newvalue = newvalue + item + " \n"
                let itemSplit = item.split(" = ")
                if(itemSplit && itemSplit[0] && itemSplit[1]) {
                    let nameField = itemSplit[0]
                    let filterName = this.state.filters.filter(f => f.name === itemSplit[0])
                    if(filterName && filterName.length > 0)
                        nameField = filterName[0].id
                    newFilter += nameField + '|'
                    newSearch += itemSplit[1] + '|'
                }
            }
        })

        this.setState({
            contentOrderEdit: newvalue
        });
    }

    setCurrentPopupData(calledId, columnsArray) {
        
        if (!this.state.calledsList || this.state.calledsList.length === 0) return null;
        const called = this.state.calledsList.find(i => i.id === calledId)

        const popupData = columnsArray.map(({ title, field }) => {
            return { title, value: called[field] }
        });
        if (popupData && popupData.length > 0) {
            this.setState({ currentPopupData: popupData })
        } else {
            this.setState({ currentPopupData: null })
        }
    }

    handleInputChange = (e, origin) => {
        const name = e.target.name
        const value = e.target.value

        if(origin === 'createTableCustomDialog') {
            this.setState(prevState => ({
                createTableCustomDialog: {
                    ...prevState.createTableCustomDialog,
                    [name]: value
                }
            }))
        } else if(origin === 'editTableCustomDialog') {
            this.setState(prevState => ({
                editTableCustomDialog: {
                    ...prevState.editTableCustomDialog,
                    [name]: value
                }
            }))
        }
    }

    render() {
        const { classes, userSession, headerMenu, actionsHeaderMenu, calleds } = this.props;
        const { createTableCustomDialog, editTableCustomDialog } = this.state
        PapiroConsole.log("userSession na listagem ")
        PapiroConsole.log(userSession)
        
       
        
        var link = `/${this.props.match.params.lang}/called-registration`;
        if (this.isClient) {
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        const openFilterMenu = Boolean(this.state.filterMenuAnchorEl)
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth={false} style={{ paddingLeft: 0, marginLeft: 0 }}>
                        {(!this.state.emptyArray || this.state.dataFilter) ? (
                            <div>
								<CssBaseline />

                                {this.state.modalopen && (
                                    <ModalVideoCall
                                        onClose={() => this.closeModal()}
                                        requestUser={userSession.user}
                                        orgSettings={this.state.orgSettings}
                                        calledStart={() => this.componentDidMount()}

                                    />
                                )}

                                {this.state.openCheckEmail && (
                                    <Dialog open={this.state.openCheckEmail} onClose={this.closeCheckEmail} >
                                        <DialogTitle id="form-dialog-title">{intl.formatMessage({id:"check.email"})}</DialogTitle>
                                        <DialogContent style={{minWidth: "600px"}}>
                                            <SimpleAutoComplete
                                                label={intl.formatMessage({ id: 'organization' })}
                                                options={this.userOrganizations}
                                                stateName='organizationCheckEmail'
                                                name='organizationCheckEmail'
                                                changeSelect={this.changeValuesCheckEmail}
                                                style={{minWidth: "400px"}}
                                                selected={this.state.organizationCheckEmail}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.closeCheckEmail} color="primary">
                                                {intl.formatMessage({id:"close"})}
                                            </Button>
                                            <Button onClick={this.checkEmail} color="primary">
                                                {intl.formatMessage({id:"confirm"})}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}

                                <Dialog open={this.state.populateCalledsDialogOpen} onClose={this.closePopulateCalledsDialog} >
                                    <DialogTitle id="form-dialog-title">Popular chamados (Métricas de tempo)</DialogTitle>
                                    <DialogContent style={{minWidth: "600px", padding: 50}}>
                                        <SimpleSelect
                                            label="Número de chamados a serem populados"
                                            options={[ { id: 100, name: '100'}, { id: 1000, name: '1000'}, { id: 5000, name: '5000'}, { id: 10000, name: '10000'}, { id: 25000, name: '25000'}, { id: 50000, name: '50000'}, { id: 100000, name: '100000'}, { id: 250000, name: '250000'}]}
                                            stateName='populateCalledsSize'
                                            name='populateCalledsSize'
                                            changeSelect={this.changeValuesPopulateCalledsSize}
                                            style={{minWidth: "400px"}}
                                            selected={this.state.populateCalledsSize}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.closePopulateCalledsDialog} color="primary">
                                            {intl.formatMessage({id:"close"})}
                                        </Button>
                                        <Button onClick={this.populateAllCalleds} color="primary">
                                            {intl.formatMessage({id:"confirm"})}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={this.state.canAssignCalledDialogOpen}
                                    onClose={this.closeCanAssignCalledDialog}
                                    aria-labelledby="dialog-can-assign-called"
                                    maxWidth="md"
                                    fullWidth={false} >
                                    <DialogTitle id="dialog-can-assign-called">
                                        Iniciar atendimento?
                                        <ClearIcon onClick={this.closeCanAssignCalledDialog} style={{ float: 'right', cursor: 'pointer' }} />
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            O sistema identificou que o chamado não está em atendimento. Deseja iniciar o atendimento? <br /> <br />Clique em "Sim" para iniciar o atendimento agora ou "Não" para apenas acessar o chamado.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.closeCanAssignCalledDialog} color="primary">
                                                {intl.formatMessage({id:"cancel"})}
                                        </Button>
                                        <Button onClick={this.negativeCanAssignCalledDialog} color="primary">
                                            {intl.formatMessage({id:"no"})}
                                        </Button>
                                        <Button onClick={this.confirmCanAssignCalledDialog} color="primary" variant="contained">
                                            {intl.formatMessage({id:"yes"})}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={this.state.confirmAssignCalledDialogOpen}
                                    onClose={this.closeConfirmAssignCalledDialog}
                                    aria-labelledby="form-delete-filter"
                                    maxWidth="md"
                                    fullWidth={false}
                                    style={{ overflow: 'hidden' }}
                                    scroll="body" >
                                    <DialogTitle id="form-delete-filter">{intl.formatMessage({id:"assume.called"})}</DialogTitle>
                                    <DialogContent style={{minWidth: "400px"}}>
                                        {intl.formatMessage({id:"called.list.dialog.called.assigned.in.progress"})}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.closeConfirmAssignCalledDialog} color="primary">
                                            {intl.formatMessage({id:"cancel"})}
                                        </Button>
                                        <Button onClick={this.assignCalled} color="primary" variant="contained">
                                            {intl.formatMessage({id:"confirm"})}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.confirmDeleteTableCustomFilterDialogOpen}
                                    onClose={this.closeConfirmDeleteTableCustomFilterDialog}
                                    aria-labelledby="form-delete-filter"
                                    maxWidth="md"
                                    fullWidth={true}
                                    style={{ overflow: 'hidden' }}
                                    scroll="body" >
                                    <DialogTitle id="form-delete-filter">Filtro personalizado</DialogTitle>
                                    <DialogContent style={{width: "600px"}}>
                                        {intl.formatMessage({id:"called.list.dialog.confirm.delete.filter"})}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.closeConfirmDeleteTableCustomFilterDialog} color="primary">
                                            {intl.formatMessage({id:"close"})}
                                        </Button>
                                        <Button onClick={this.deleteTableCustomFilter} color="primary">
                                            {intl.formatMessage({id:"confirm"})}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.createTableCustomDialogOpen}
                                    onClose={this.closeCreateTableCustomDialog}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth="md"
                                    fullWidth={true}
                                    style={{ overflow: 'hidden' }}
                                    scroll="body">
                                    <DialogTitle id="form-dialog-title">
                                        {intl.formatMessage({id:"called.list.dialog.save.custom.filter"})}
                                        <ClearIcon onClick={this.closeCreateTableCustomDialog} style={{ float: 'right', cursor: 'pointer' }} />
                                    </DialogTitle>
                                    <DialogContent style={{ padding: 24 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    inputProps={{ maxLength: 255 }}
                                                    fullWidth
                                                    label={intl.formatMessage({id:"name"})}
                                                    autoComplete='fname'
                                                    variant='outlined'
                                                    name='name'
                                                    onChange={(e) => this.handleInputChange(e, 'createTableCustomDialog')}
                                                    value={createTableCustomDialog ? createTableCustomDialog.name : ""}
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    inputProps={{ maxLength: 15 }}
                                                    fullWidth
                                                    label={intl.formatMessage({id:"exhibition.name"})}
                                                    autoComplete='fname'
                                                    variant='outlined'
                                                    name='exhibitionName'
                                                    onChange={(e) => this.handleInputChange(e, 'createTableCustomDialog')}
                                                    value={createTableCustomDialog ? createTableCustomDialog.exhibitionName : ""}
                                                    required />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <SimpleAutoCompleteMultiple
                                                    label={`${intl.formatMessage({ id: 'organization' })}${(!this.admGlobal && (this.isAdmOrg || this.isCoordenator) && !createTableCustomDialog.isPrivate) ? '*' : ''}`}
                                                    options={this.state.tableCustomFilterOrganizationList}
                                                    stateName='tableCustomFilterOrganizationIds'
                                                    name='tableCustomFilterOrganizationIds'
                                                    changeSelect={this.changeValuesCreateTableCustomDialog}
                                                    style={{minWidth: "400px"}}
                                                    selected={createTableCustomDialog.tableCustomFilterOrganizationIds}
                                                />
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label={intl.formatMessage({id:"private"})}
                                                    name='isPrivate'
                                                    stateName='isPrivate'
                                                    changeSelect={this.changeValuesCreateTableCustomDialog}
                                                    selected={createTableCustomDialog ? createTableCustomDialog.isPrivate : false}
                                                    disabled={ (this.isClient) || (this.isTechnical && !this.isAdm && !this.admGlobal) } />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"called.list.tooltip.turns.custom.filter.private"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label={intl.formatMessage({id:"save.as.favorite"})}
                                                    name='isFavorite'
                                                    stateName='isFavorite'
                                                    changeSelect={this.changeValuesCreateTableCustomDialog}
                                                    selected={createTableCustomDialog ? createTableCustomDialog.isFavorite : false} />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"called.list.tooltip.saves.filter.as.favorite"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label= {intl.formatMessage({id:"standard.filter"})}
                                                    name='isDefault'
                                                    stateName='isDefault'
                                                    changeSelect={this.changeValuesCreateTableCustomDialog}
                                                    selected={createTableCustomDialog ? createTableCustomDialog.isDefault : false} />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"called.list.tooltip.saves.filter.as.standard"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label= {intl.formatMessage({id:"display.filter.in.called.list"})}
                                                    name='displayFilterInCalledList'
                                                    stateName='displayFilterInCalledList'
                                                    changeSelect={this.changeValuesCreateTableCustomDialog}
                                                    selected={createTableCustomDialog ? createTableCustomDialog.displayFilterInCalledList : false} />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"display.filter.in.called.list.tooltip"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions justify="flex-end" style={{marginBottom:'10px'}} >
                                        <Grid container spacing={4} justifyContent="flex-end">
                                            <Grid item xs={12} sm={4}>
                                                <Button
                                                    variant='contained'
                                                    type="button"
                                                    onClick={this.closeCreateTableCustomDialog}
                                                    fullWidth >
                                                    {intl.formatMessage({id:"cancel"})}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Button
                                                    type='submit'
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={this.saveTableCustomFilter} >
                                                    {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.listTableCustomDialogOpen}
                                    onClose={this.closeListTableCustomDialog}
                                    aria-labelledby="form-list-filters"
                                    maxWidth="md"
                                    scroll="body"
                                    style={{ overflow: 'hidden' }}
                                    fullWidth={true}>
                                    <DialogTitle id="form-list-filters">
                                        { this.state.customFilters }
                                        <ClearIcon onClick={this.closeListTableCustomDialog} style={{ float: 'right', cursor: 'pointer' }} />
                                    </DialogTitle>
                                    <DialogContent style={{ padding: 24 }}>
                                        { this.state.editTableCustomFilterStep == 1 ?
                                        (<Grid container spacing={4}>
                                            <Grid item xs={12} sm={12} style={{ marginLeft: 5, marginTop: 20, padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                                                <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                    <TextField type="text"
                                                        name="searchValueListTableCustomField"
                                                        value={this.state.searchValueListTableCustomField}
                                                        placeholder={intl.formatMessage({ id: "search" })}
                                                        onChange={this.onFieldChange} />
                                                </Grid>
                                                <Grid item xs={12} sm={1}></Grid>
                                                <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.button}
                                                        startIcon={<Search />}
                                                        onClick={this.searchFilter} >
                                                        {intl.formatMessage({id:"search"})}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={1}></Grid>
                                                <Grid item xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.button}
                                                        startIcon={<ClearIcon />}
                                                        onClick={this.listTableCustomFilters} >
                                                        {intl.formatMessage({id:"clear"})}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12}
                                                style={{
                                                    marginLeft: 5,
                                                    marginBottom: 5,
                                                    border: '3px solid #eee'
                                                }} >
                                                <div style={{ height: 350 }}>
                                                    <DataGrid
                                                        style={{ fontSize: 14, color: '#000' }}
                                                        className="DataGridStyle"
                                                        rows={this.state.listTableCustomDialogList}
                                                        rowsPerPageOptions={[5]}
                                                        columns={[
                                                            {
                                                                headerName: intl.formatMessage({id:"apply"}),
                                                                field: 'data',
                                                                width: 85,
                                                                renderCell: (params) => (
                                                                    <IconButton aria-label="search" color="primary" onClick={() => this.setFilters(params.value, 'listCustomTableFilter')}>
                                                                        <ZoomInIcon/>
                                                                    </IconButton>
                                                                )
                                                            },
                                                            {
                                                                headerName: intl.formatMessage({id:"delete"}),
                                                                field: 'deleteId',
                                                                width: 85,
                                                                renderCell: (params) => (
                                                                    <IconButton aria-label="delete" color="primary" style={ this.admGlobal || params.value.user ? {} : { display: 'none' } } onClick={() => this.openConfirmDeleteTableCustomFilterDialog(params.value.id)}>
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                )
                                                            },
                                                            {
                                                                headerName: intl.formatMessage({id:"edit"}),
                                                                field: 'editId',
                                                                width: 85,
                                                                renderCell: (params) => (
                                                                    <IconButton aria-label="edit" color="primary" style={ this.admGlobal || params.value.user ? {} : { display: 'none' } } onClick={() => this.setEditStep(params.value.id)}>
                                                                        <EditIcon/>
                                                                    </IconButton>
                                                                )
                                                            },
                                                            {
                                                                headerName: intl.formatMessage({id:"favorite"}),
                                                                field: 'isfavorite',
                                                                width: 85,
                                                                renderCell: (params) => (
                                                                    <StarRateIcon style={params.value ? { fontColor: "#ffb400", fill: "#ffb400" } : { display: 'none' }} />
                                                                )
                                                            },
                                                            { headerName: intl.formatMessage({id:"name"}), field: 'name', width: 400 },
                                                            { headerName: intl.formatMessage({id:"exhibition.name"}), field: 'exhibitionName', width: 200 },
                                                            { headerName: 'Privado?', field: 'isPrivateText', width: 85 },
                                                            { headerName: 'Usuário que criou filtro', field: 'filterCreatorName', width: 200 }
                                                            //{ headerName: 'Mostrar na listagem de chamados?', field: 'displayFilterInCalledList', width: 150 }
                                                        ]} />
                                                </div>
                                            </Grid>
                                        </Grid>) :
                                        (<Grid container spacing={4}>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    inputProps={{ maxLength: 255 }}
                                                    fullWidth
                                                    label={intl.formatMessage({id:"name"})}
                                                    autoComplete='fname'
                                                    variant='outlined'
                                                    name='name'
                                                    onChange={(e) => this.handleInputChange(e, 'editTableCustomDialog')}
                                                    value={editTableCustomDialog ? editTableCustomDialog.name : ""}
                                                     />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    inputProps={{ maxLength: 15 }}
                                                    fullWidth
                                                    label={intl.formatMessage({id:"exhibition.name"})}
                                                    autoComplete='fname'
                                                    variant='outlined'
                                                    name='exhibitionName'
                                                    onChange={(e) => this.handleInputChange(e, 'editTableCustomDialog')}
                                                    value={editTableCustomDialog ? editTableCustomDialog.exhibitionName : ""}
                                                    required />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <SimpleAutoCompleteMultiple
                                                    label={`${intl.formatMessage({ id: 'organization' })}${(!this.admGlobal && (this.isAdmOrg || this.isCoordenator) && !createTableCustomDialog.isPrivate) ? '*' : ''}`}
                                                    options={this.state.tableCustomFilterOrganizationList}
                                                    stateName='tableCustomFilterOrganizationIds'
                                                    name='tableCustomFilterOrganizationIds'
                                                    changeSelect={this.changeValuesEditTableCustomDialog}
                                                    style={{minWidth: "400px"}}
                                                    selected={editTableCustomDialog.tableCustomFilterOrganizationIds}
                                                />
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label={intl.formatMessage({id:"private"})}
                                                    name='isPrivate'
                                                    stateName='isPrivate'
                                                    changeSelect={this.changeValuesEditTableCustomDialog}
                                                    selected={editTableCustomDialog ? editTableCustomDialog.isPrivate : ""}
                                                    disabled={ (this.isClient) || (this.isTechnical && !this.isAdm && !this.admGlobal) } />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"called.list.tooltip.turns.custom.filter.private"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label={intl.formatMessage({id:"save.as.favorite"})}
                                                    name='isFavorite'
                                                    stateName='isFavorite'
                                                    changeSelect={this.changeValuesEditTableCustomDialog}
                                                    selected={editTableCustomDialog ? editTableCustomDialog.isFavorite : ""} />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"called.list.tooltip.saves.filter.as.favorite"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label={intl.formatMessage({id:"standard.filter"})}
                                                    name='isDefault'
                                                    stateName='isDefault'
                                                    changeSelect={this.changeValuesEditTableCustomDialog}
                                                    selected={editTableCustomDialog ? editTableCustomDialog.isDefault : ""} />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"called.list.tooltip.saves.filter.as.standard"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <SimpleCheckBox
                                                    label= {intl.formatMessage({id:"display.filter.in.called.list"})}
                                                    name='displayFilterInCalledList'
                                                    stateName='displayFilterInCalledList'
                                                    changeSelect={this.changeValuesEditTableCustomDialog}
                                                    selected={editTableCustomDialog ? editTableCustomDialog.displayFilterInCalledList : false} />
                                            </Grid>
                                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                <Tooltip title={intl.formatMessage({id:"display.filter.in.called.list.tooltip"})}>
                                                    <HelpIcon />
                                                </Tooltip>
                                            </Grid>
                                            {this.admGlobal && this.state.editTableCustomDialog.userId != userSession.user.id &&
                                                
                                                    <Grid item xs={10} sm={5}>
                                                        <SimpleCheckBox
                                                            label= {intl.formatMessage({id:"display.filter.in.creator.called.list"})}
                                                            name='displayFilterInCreatorCalledList'
                                                            stateName='displayFilterInCreatorCalledList'
                                                            changeSelect={this.changeValuesEditTableCustomDialog}
                                                            selected={editTableCustomDialog ? editTableCustomDialog.displayFilterInCreatorCalledList : false} />
                                                    </Grid>
                                            }

                                            {this.admGlobal && this.state.editTableCustomDialog.userId != userSession.user.id &&
                                                
                                                <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                                                    <Tooltip title={intl.formatMessage({id:"display.filter.in.creator.called.list.tooltip"})}>
                                                        <HelpIcon />
                                                    </Tooltip>
                                                </Grid>
                                            }
                                            
                                            
                                            {(this.state.contentFilterEdit || this.state.contentOrderEdit) &&
                                            <Grid item xs={12} sm={12}>
                                                <Card className="background-title-search">
                                                    <CardContent>
                                                        <Grid container spacing={1} alignItems="flex-end">
                                                            <Grid item xs={8} md={6}>
                                                                <Typography component={'span'} variant="h5">{intl.formatMessage({id:"filters"})}:</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <strong>
                                                                    {this.state.contentFilterEdit.split("\n").map((i, key) => {
                                                                        if (i.trim() != "") {
                                                                            return <Chip
                                                                                key={key}
                                                                                label={i}
                                                                                onDelete={() => this.handleDeleteFilterEdit(key)}
                                                                                color="primary"
                                                                                variant="outlined"
                                                                            />
                                                                        }
                                                                    })}
                                                                    {this.state.contentOrderEdit.split("\n").map((i, key) => {
                                                                        if (i.trim() != "") {
                                                                            return <Chip
                                                                                key={key}
                                                                                label={i}
                                                                                onDelete={() => this.handleDeleteOrderEdit(key)}
                                                                                color="primary"
                                                                                variant="outlined"
                                                                            />
                                                                        }
                                                                    })}
                                                                </strong>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>}
                                            <Grid item xs={12} sm={12}>
												<Card className="background-title-search">
														<CardContent>
															<Grid container spacing={1} justify="space-between" alignItems="flex-end">
																<Grid item xs={12} sm={12} md={12} lg={12}>
																	<Grid container justify="flex-end" alignItems="flex-end">
																		<SearchBarDialog
                                                                            field={this.state.filterSelectedEdit}
                                                                            filterValue={this.state.searchValueEdit}
                                                                            filterName={"searchValueEdit"}
                                                                            filters={this.state.filters}
                                                                            addFilterEdit={this.addFilterEdit} 
																			clearFieldsEdit={this.clearFieldsEdit}
                                                                            callSearch={this.callSearchEdit}
                                                                            handleChangeFieldValue={this.handleChangeFieldValue}
																			{...this.props}
																			/>

																	</Grid>
																</Grid>
															</Grid>
														</CardContent>
												</Card>	 
																									
                                            </Grid>
                                        </Grid>)}
                                    </DialogContent>
                                    { this.state.editTableCustomFilterStep == 2 &&
                                    <DialogActions justify="flex-end">
                                        <Grid container spacing={4} justifyContent="flex-end">
                                            <Grid item xs={12} sm={4}>
                                                <Button
                                                    variant='contained'
                                                    type="button"
                                                    onClick={this.closeEditStep}
                                                    fullWidth >
                                                    {intl.formatMessage({id:"cancel"})}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Button
                                                    type='submit'
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={this.editTableCustomFilter} >
                                                    {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>}
                                </Dialog>

                                {(this.admGlobal && (userSession && userSession.user && (userSession.user.email == 'suporte@papiro.ai' || userSession.user.email == 'suporte@mexx.ai' || userSession.user.email == 'eduardo.lima@cmtech.com.br' || userSession.user.email == 'tpporto@gmail.com'))) ? (
                                <Grid container className={classes.mb20} spacing={0}>
                                    <Grid item xs={6} sm={6} md={6} lg={6} >
										{((userSession != null && userSession.user != null && userSession.user.isexternal != null && userSession.user.isexternal && userSession.user.canexternaluserclientcreatecalled != null && userSession.user.canexternaluserclientcreatecalled) || (userSession != null && userSession.user.isexternal != null && !userSession.user.isexternal)) &&
											<Can I="Open" a="Called">
												<Button
													variant="contained"
													color="secondary"
													size="large"
													className={classes.button}
													startIcon={<AddIcon />}
													component={Link} {...{ to: link }}
												>
													{<FormattedMessage id="called.new" />}
												</Button>
											</Can>
										}
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5} lg={5} >
										
                                            {/*<Button
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                className={classes.button}
                                                startIcon={<SaveIcon />}
                                                onClick={() => this.openPopulateCalledsDialog()}
                                            >
                                                Popular chamados (Métricas de tempo) {this.state.calledCountNotTimeData} chamados restantes
                                            </Button>*/}
									
									</Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} >
                                        {!this.isClient && 
                                                <TreeDots items={this.state.itemsTree} />
                                        }
                                    </Grid>
                                </Grid>) : (
                                <Grid container className={classes.mb20} spacing={0}>
                                    <Grid item xs={11} sm={11} md={11} lg={11} >
									{(
                                        (
                                         (userSession != null && userSession.user != null && userSession.user.isexternal != null && userSession.user.isexternal && userSession.user.canexternaluserclientcreatecalled != null && userSession.user.canexternaluserclientcreatecalled)
                                        || 
                                        (userSession != null && userSession.user.isexternal != null && !userSession.user.isexternal)
                                        ) 
                                        
                                        && (userSession.user.originrequestid != 8 && userSession.user.originrequestid != 9)
                                        ) &&
                                        <Can I="Open" a="Called">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                component={Link} {...{ to: link }}
                                            >
                                                {<FormattedMessage id="called.new" />}
                                            </Button>
                                        </Can>
										}
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }} >
                                        {!this.isClient && 
                                                <TreeDots items={this.state.itemsTree} />
                                        }
                                        
                                    </Grid>
                                </Grid>
                                )}
                                {!this.isClient && this.state.isFinished == 0  && this.state.isArchived == 0 &&  this.state.isCanceled == 0 &&
                                    <Grid container  mb={1}>

                                    
                                        <Grid item xs={12} sm={6} md={2}  style={{ padding: '2px' }}>
                                            <CalledsQuantityCards message="expired.calleds.total" quantity={this.state.countExpiredCalleds} icon="expiredGeral"  refresh={false} callSearch={this.callSearch} clearFilters={this.clearFilters} disabledCardActionArea={false} origin="called-list" tooltipCard ="expired.calleds.total.tooltip"  />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}  style={{ padding: '2px' }}>
                                            <CalledsQuantityCards message="expired.calleds.today" quantity={this.state.countExpiredCalledsToday} icon="expiredToday" refresh={false} callSearch={this.callSearch} clearFilters={this.clearFilters} disabledCardActionArea={false} origin="called-list" tooltipCard ="expired.calleds.today.tooltip"    />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}  style={{ padding: '2px' }}>
                                            <CalledsQuantityCards message="general.open.calleds" quantity={this.state.countOpenCalleds} icon="openGeral" refresh={false} callSearch={this.callSearch} clearFilters={this.clearFilters} disabledCardActionArea={false} origin="called-list" tooltipCard ="general.open.calleds.tooltip"    />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}  style={{ padding: '2px' }}>
                                        <   CalledsQuantityCards message="today.pending.calleds" quantity={this.state.countPendingCalledsToday} icon="pendingToday" refresh={true} getCalledsToCard ={this.getCalledsToCard} callSearch={this.callSearch} clearFilters={this.clearFilters} disabledCardActionArea={false} origin="called-list"  tooltipCard="today.pending.calleds.tooltip" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={2}  style={{ padding: '2px' }}>
                                        <   CalledsQuantityCards message="today.open.calleds" quantity={this.state.countOpenCalledsToday} icon="openToday" refresh={true} getCalledsToCard ={this.getCalledsToCard} callSearch={this.callSearch} clearFilters={this.clearFilters} disabledCardActionArea={false} origin="called-list"  tooltipCard="today.open.calleds.tooltip" />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={6} md={2}  style={{ padding: '2px' }}>
                                            <CalledsQuantityCards message="calleds.without.assigned" quantity={this.state.countCalledsWithoutAssignedUser} icon="WithoutAssignedUser"  refresh={false} callSearch={this.callSearch} clearFilters={this.clearFilters} disabledCardActionArea={false} origin="called-list"  tooltipCard="calleds.without.assigned.tooltip"  />
                                        </Grid>
                                        

                                    </Grid>

                                    

                                    
                                }
                                
                                {!this.isClient && this.state.filtersByFlag.length > 0 && 
                                    
                                 
                                    <div style={{marginBottom:'10px'}}>
                                        <Accordion onChange={this.setAccordion}  expanded={this.state.openAccordionFilter} >
                                                <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                >
                                                <Typography className={classes.heading}>Filtros criados pelo usuário</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <Grid container spacing={1} direction="row" justify="space-between" alignItems="stretch" style={ this.state.favoriteFilters && this.state.favoriteFilters.length > 0 ? { marginBottom: 30 } : {}}>
                                                            {this.state.filtersByFlag.map((item, key) => {
                                                            return <Grid item xs={12} sm={6} md={4} lg={2}>
                                                            <Favoritefilterscards item={item} clearFilters={this.clearFilters} quantity={item.countcalleds} icon="FilterListIcon"  goToCalledWithFilter={this.goToCalledWithFilter} origin="called-list" callSearch={this.callSearch}/>
                                                                    </Grid>
                                                })}

                                                         </Grid>
                                                    </Grid>
                                               
                                                </AccordionDetails>
                                        </Accordion>
                                    </div>
                                        

                                        
                                        

                            

                                    

                                    
                                }
                                

                                {(this.state.contentFilter || this.state.contentOrder) &&
                                    <Grid container spacing={6} style={{marginBottom:'15px'}}>
                                        <Grid item xs={12} sm={12}>
                                            <Card className="background-title-search">
                                                <CardContent>
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                        <Grid item xs={8} md={6}>
                                                            <Typography component={'span'} variant="h5">
                                                                {intl.formatMessage({id:"filters"})}:
                                                        </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <strong>
                                                                {this.state.contentFilter && this.state.contentFilter.length > 0 && this.state.contentFilter.split("\n").map((i, key) => {
                                                                    if (i.trim() != "") {
                                                                        return <Chip
                                                                            key={key}
                                                                            label={i}
                                                                            onDelete={() => this.handleDelete(key)}
                                                                            color="primary"
                                                                            variant="outlined"
                                                                        />
                                                                    }
                                                                })}
                                                                {this.state.contentOrder && this.state.contentOrder.length > 0 && this.state.contentOrder.split("\n").map((i, key) => {
                                                                    if (i.trim() != "") {
                                                                        return <Chip
                                                                            key={key}
                                                                            label={i}
                                                                            onDelete={() => this.handleOrderDelete(key)}
                                                                            color="primary"
                                                                            variant="outlined"
                                                                        />
                                                                    }
                                                                })}
                                                            </strong>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container spacing={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Card className="background-title-search">
                                            <CardContent>
                                                <Grid container spacing={1} justify="space-between" alignItems="center">
                                                    <Grid item xs={12} sm={12} md={2} lg={2}>
                                                        <Typography component={'span'} variant="h5">
                                                            {<FormattedMessage id="called.header" />}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={10} lg={10}>
                                                        <Grid container spacing={1} direction="row" alignItems="center">
															<SearchBar
                                                                field={this.state.filterSelected}
                                                                filterValue={this.state.searchValue}
                                                                filterName={"searchValue"}
                                                                filters={this.state.filters}
                                                                addFilterEdit={this.addFilter} 
																clearFieldsEdit={this.clearFields}
                                                                callSearch={this.callSearch}
                                                                orderFields={this.state.orderFields}
                                                                handleChangeFieldValue={this.handleChangeFieldValue}
																{...this.props} />
															<Grid item xs={1} sm={1} md={1} lg={1}>
                                                                <IconButton
                                                                    color='primary'
                                                                    aria-label='filters'
                                                                    component='span'
                                                                    id="filter-menu"
                                                                    aria-controls={ openFilterMenu ? 'filter-menu' : undefined }
                                                                    aria-haspopup="true"
                                                                    aria-expanded={ openFilterMenu ? 'true' : undefined }
                                                                    onClick={this.handleClickFilterMenu} >
                                                                    <MoreVert />
                                                                </IconButton>
                                                                <Menu
                                                                    id="filter-menu"
                                                                    aria-labelledby="filter-menu"
                                                                    anchorEl={this.state.filterMenuAnchorEl}
                                                                    open={openFilterMenu}
                                                                    onClose={this.handleCloseFilterMenu}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left'
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left'
                                                                    }} >
                                                                        <MenuItem onClick={this.openCreateTableCustomDialog} disabled={ (this.state.contentFilter == null || this.state.contentFilter == '') && (this.state.contentOrder == null || this.state.contentOrder == '') }>
                                                                            <ListItemIcon style={{ minWidth: 25 }}>
                                                                                <SaveIcon style={{ fontColor: "#3f51b4", fill: "#3f51b4" }} />
                                                                            </ListItemIcon>
                                                                            {intl.formatMessage({id:"save"})}
                                                                        </MenuItem>
                                                                        <Divider />
                                                                        <MenuItem disabled={true}>{intl.formatMessage({id:"custom.filters"})}</MenuItem>
                                                                        { this.state.filterFavorite && this.state.filterFavorite.map((item, idx) => {
                                                                            return <MenuItem key={idx} onClick={() => this.setFilters(item)}>
                                                                                {item.tablecustomfilterfavorite != null && (
                                                                                <ListItemIcon style={{ minWidth: 25 }}>
                                                                                    <StarRateIcon style={{ fontColor: "#ffb400", fill: "#ffb400" }} />
                                                                                </ListItemIcon>
                                                                                )}
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        })}
                                                                        { this.state.countFavorite > 0 &&
                                                                        (<Divider />)}
                                                                        { this.state.countFavorite > 0 &&
                                                                        (<MenuItem onClick={this.openListTableCustomDialog} >
                                                                            <ListItemIcon style={{ minWidth: 25 }}>
                                                                                <FormatListBulletedIcon style={{ fontColor: "#3f51b4", fill: "#3f51b4" }} />
                                                                            </ListItemIcon>
                                                                            {intl.formatMessage({id:"called.report.config.organization.field.block.list.all"})}
                                                                        </MenuItem>)}
                                                                        <Divider />
                                                                        <MenuItem onClick={this.handleClickExportToCSV} >
                                                                            <ListItemIcon style={{ minWidth: 25 }}>
                                                                                <ExitToAppIcon style={{ fontColor: "#3f51b4", fill: "#3f51b4" }} />
                                                                            </ListItemIcon>
                                                                            {intl.formatMessage({id:"called.list.export"})}
                                                                        </MenuItem>
                                                                </Menu>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>

                                    </Grid>

                                </Grid>


                                <Can I="List" a="Called">
                                <div className='material-table'>
										{/*tableList()*/}
                                        <Grid container spacing={0}>
                                            {this.state.calledsList.map((item) => {
                                                return <ItemCard
                                                    called={item}
                                                    isAdm={this.isAdm}
                                                    isClient={this.isClient}
                                                    isTechnical={this.isTechnical}
                                                    goToCalled={this.openCanAssignCalledDialog}
                                                    openConfirmAssignCalledDialog={this.openConfirmAssignCalledDialog} openConfirmAssignCalledDialogFinal={this.openConfirmAssignCalledDialog}
                                                    popupColumns={this.isClient ? this.popupColumnsClient : this.popupColumns}
										            context={this.context}
                                                    showMessage={this.showMessage} {...this.props}
                                                    currentPopupData={this.state.currentPopupData}
                                                    setCurrentPopupData={this.setCurrentPopupData} />
                                            })}
                                        </Grid>
                                </div>
                                </Can>

                                <Can I='List' a='Called'>

                                    <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                        <Grid item xs={12} md={4}>
                                            <Pagination
                                                count={this.state.totalPages}
                                                page={this.state.page}
                                                onChange={this.onChangePaginate}
                                                showFirstButton
                                                showLastButton />
                                        </Grid>
                                        <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                            {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                        </Grid>
                                    </Grid>

                                </Can>

                            </div>)
                            : (


                                <div>

                                    {this.state.emptyArray && this.state.emptySearchArchived && 
                                        <div>
                                            
                                            {this.ShowFilter()}
                        
                                            <FirstElement
                                                title={intl.formatMessage({id:"archived.calleds"})}
                                                subtitle={intl.formatMessage({id:"need.search"})}
                                                
                                                link={link}
                                                icon={<QueuePlayNextIcon style={{ fontSize: "45px" }} />}
                                            />
                                             
                                        </div>
                                        
                                    }
                                    {this.state.emptyArray && this.state.emptySearchFinishCalleds && 
                                        <div>
                                            
                                            {this.ShowFilter()}
                        
                                            <FirstElement
                                                title={intl.formatMessage({id:"called.finished"})}
                                                subtitle={intl.formatMessage({id:"need.search"})}
                                                
                                                link={link}
                                                icon={<QueuePlayNextIcon style={{ fontSize: "45px" }} />}
                                            />
                                             
                                        </div>
                                        
                                    }
                                    {this.state.emptyArray && this.state.emptySearchCanceled && 
                                        <div>
                                            
                                            {this.ShowFilter()}
                        
                                            <FirstElement
                                                title={intl.formatMessage({id:"canceled.calleds"})} 
                                                subtitle={intl.formatMessage({id:"need.search"})}
                                                
                                                link={link}
                                                icon={<QueuePlayNextIcon style={{ fontSize: "45px" }} />}
                                            />
                                             
                                        </div>
                                        
                                    }
                                    {this.state.emptyArray && this.state.emptySearchFinishCalleds == false && this.state.emptySearchArchived == false && this.state.emptySearchCanceled == false  && 
                                        <div>
                                            
                                           
                                            <FirstElement
                                                title={intl.formatMessage({id:"called.list.create.first.call"})}
                                                subtitle={((userSession.user.isexternal != null && userSession.user.isexternal && userSession.user.canexternaluserclientcreatecalled != null && userSession.user.canexternaluserclientcreatecalled) || (userSession.user.isexternal != null && !userSession.user.isexternal) && (userSession.user.originrequestid != 8 && userSession.user.originrequestid != 9) ) ? intl.formatMessage({id:"called.list.create.call.subtitle"}) : ""}
                                                buttonText={((userSession.user.isexternal != null && userSession.user.isexternal && userSession.user.canexternaluserclientcreatecalled != null && userSession.user.canexternaluserclientcreatecalled) || (userSession.user.isexternal != null && !userSession.user.isexternal)&& (userSession.user.originrequestid != 8 && userSession.user.originrequestid != 9) ) ?
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="large"
                                                    className={classes.button}
                                                    startIcon={<AddIcon />}
                                                    component={Link} {...{ to: link }}
                                                >
                                                    {<FormattedMessage id="called.new" />}
                                                </Button> : null}

                                                link={link}
                                                icon={<QueuePlayNextIcon style={{ fontSize: "45px" }} />}

                                            />
                                        </div>
                                        
                                    }
                                    
                                    
                                  
                                </div>
                            )}
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer organizations={this.userOrganizations} />
            </div>
        );
    }
}

Calleds.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    lastCalledsQueue: state.lastCalledsQueue,
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, calledsActions, headerMenuActions, lastCalledsQueueActions), dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Calleds)));
Calleds.contextType = AbilityContext;
