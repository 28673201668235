import React from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withStyles } from '@mui/styles'
import ItemCard from '../item-card/item-card'


function TechConversations(props) {
    const { intl } = props;
    return (<>
        {props && props.chatsInAttendance && props.chatsInAttendance.length > 0 ? props.chatsInAttendance.map((item) => {
            return <ItemCard
                chats={item}
                name="chatsInAttendance"
                updateChat={props.updateChat}
                currentUserId={props.currentUserId}
            />

        }) : null

        }
    </>)
}

TechConversations.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    chats: state.chats,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(TechConversations)))