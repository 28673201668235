import { Component } from "react";
import { bindActionCreators } from "redux";
import * as componentActions from '../../redux/actions/calledflows';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context';
import HeaderMenu from "../../components/header-menu";
import Api from "../../services/api";
import clsx from 'clsx';
import { Button, CircularProgress, Container, CssBaseline, Grid, Avatar, TextField, Typography, Switch, Tooltip } from "@mui/material";
import { browserHistory } from '../../helpers/history';
import Api2Talk from "../../services/api2talk";
import { Overlay } from "../../styles/global.js";
import CustomizedSnackbars from "../../components/material-snackbars/index.js"; 
import ChatIcon from '@mui/icons-material/Chat';
import SimpleAutoComplete from "../../components/auto-complete/autocomplete.js";
import { generateHashWPA, generateGUID } from "../../utils/functions/crypto.js";
import ReactQuill from "react-quill";
import * as WebchatService from "../../services/2talk/webchat.service.js";
import HelpIcon from '@mui/icons-material/Help';


class WebchatRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.itemInitialState = {
      createduser: userSession.user.id,
      webchattypeid: null,
      messageproviderid: null,
      name: '',
      hash: generateHashWPA(),
      accountid: generateGUID(),
      active: false,
      default: false,
      description: ''
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      optionsMessageProviders: [],
      optionsWebchatTypes: []
    };

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
  
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let input = {
      ...this.state.item,
      messageproviderid: this.state.item.messageproviderid?.id,
      webchattypeid: this.state.item.webchattypeid?.id,
    }

    try {
      const output = await WebchatService.create(input);

      if (output.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          item: {
            ...this.itemInitialState,
            hash: generateHashWPA(),
            accountid: generateGUID(),
            messageproviderid: this.state.item.messageproviderid
          },
          notificationMessage: 'Registro incluído com sucesso'
        });
      } else {
        this.fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }

  fetchOptionsMessageProvider = async () => {
    this.setState({ loading: true });

    try {
      const response = await Api2Talk.get(`/messageprovider`);

      const { data } = response;

      if (data.success) {
        const messageProviderOptions = data.data;

        const messageProvider = messageProviderOptions?.find(item => item.id == 6);

        this.setState(prevState => ({
          optionsMessageProviders: messageProviderOptions
        }))

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            messageproviderid: messageProvider ?? null
          }
        }))
      } else {
        this.fetchDataNotSuccess(response);
        Api.kickoff(response);
      }

    } catch (err) {
      this.fetchDataThrowError(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  fetchOptionsWebchatTypes = async () => {
    this.setState({ loading: true });

    try {
      const response = await Api2Talk.get(`/webchattype`);

      const { data } = response;

      if (data.success) {
        const webchatTypesOptions = data.data;
        this.setState(prevState => ({
          optionsWebchatTypes: webchatTypesOptions
        }))
      } else {
        this.fetchDataNotSuccess(response);
        Api.kickoff(response);
      }

    } catch (err) {
      this.fetchDataThrowError(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  handleChangeAutoComplete(stateName, value) {  
    let data = value.id > 0 ? value : null ;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: data
      }
    }));
  }

  handleChangeEditor(value, name) {
    this.setState(prevState => ({
        item: {
            ...prevState.item,
            [name]: value || ""
        }
    }));
  }

  async componentDidMount() {
    await this.fetchOptionsMessageProvider();
    await this.fetchOptionsWebchatTypes();
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
   
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
         <HeaderMenu />
         <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
         >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <ChatIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "webchat.registration" })}
              </Typography>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="MessageProviderId"
                        label={intl.formatMessage({ id: "select.message_provider" })}
                        options={this.state.optionsMessageProviders}
                        stateName='messageproviderid'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={item.messageproviderid}
                        htmlOptions={true}
                        required 
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="webchattypeid"
                        label={intl.formatMessage({ id: "select.webchattype" })}
                        options={this.state.optionsWebchatTypes}
                        stateName='webchattypeid'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={item.webchattypeid}
                        htmlOptions={true}
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"name"})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='name' 
                        onChange={this.handleInputChange} 
                        value={item.name} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"hash"})} 
                        autoComplete='fhash' 
                        variant='outlined' 
                        name='hash' 
                        onChange={this.handleInputChange} 
                        value={item.hash} 
                        required 
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"account.id"})} 
                        autoComplete='faccountid' 
                        variant='outlined' 
                        name='accountid' 
                        onChange={this.handleInputChange} 
                        value={item.accountid} 
                        required 
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Switch
                        value={item.active}
                        checked={item.active}
                        name="active"
                        onChange={(e) => this.handleChangeSwitch(e)}
                      />
                      <FormattedMessage id="active" />
                    </Grid> 
                    
                    <Grid item container direction='row' xs={12} sm={12}>
                      <Grid item xs={11.5} sm={11.5}>
                        <Switch
                          value={item.isdefault}
                          checked={item.isdefault}
                          name="isdefault"
                          onChange={(e) => this.handleChangeSwitch(e)}
                        />
                        <FormattedMessage id="default" />
                      </Grid>
                      <Grid item xs={0.5} sm={0.5}>
                        <Tooltip style={{cursor:'pointer'}} title={ intl.formatMessage({ id: 'webchat.default.option' })  }>
                            <HelpIcon />
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Typography>
                        <FormattedMessage id="description" />
                      </Typography>
                      <ReactQuill
                        name="description" 
                        value={item.description}
                        style={{ height: 150, marginBottom: 32 }} 
                        modules={this.reactQuillModules} 
                        formats={this.reactQuillFormats}
                        fullWidth
                        variant="outlined"
                        id="description"
                        onChange={(value) => this.handleChangeEditor(value, "description")}
                      />					  
                    </Grid>
                    

                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(WebchatRegistration)));

WebchatRegistration.contextType = AbilityContext;