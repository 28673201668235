import Api from './api';

export const getAll = async () => { 
  let data = []
  await Api.get("/satisfactionsurvey/getall")
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};


export const getByHirerIdAndCalledId = async (id,calledId) => { 
  let data = []
  await Api.get(`/satisfactionsurvey/getbyhireridandcalledid/${id}/${calledId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

