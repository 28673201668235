import { Grid, Rating, Typography } from "@mui/material";
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import Api from "../../services/api";
import { PapiroConsole } from "../../utils/papiroConsole";

const colorsZeroToTen = [
  '#EC475C',
  '#EC475C',
  'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
  '#FB8A31',
  '#FB8A31',
  'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
  '#FFE44B',
  '#FFE44B',
  'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
  '#0eae9c',
  '#0eae9c'

]
const colorsOneToTen = [
  '#EC475C',
  'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
  '#FB8A31',
  '#FB8A31',
  'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
  '#FFE44B',
  '#FFE44B',
  'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
  '#0eae9c',
  '#0eae9c'
]
const colorsOneToFive = [
  '#EC475C',
  '#FB8A31',
  '#FFE44B',
  'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
  '#0eae9c',
]

function RatingElement(props) {
  const { type, value, edit, onChangeRating } = props

  function onClickChange(value) {
    if (edit) {
      onChangeRating(value)
    }
  }

  switch (type) {
    case 1:
      break;

    case 2:
      return ((value != undefined && value != null) || edit) ?
        <div className="vote-gradient">
          {Array.from({ length: 11 }, (_, index) => {
            return (
            <div
              key={index}
              className={"vote-number" + (value == index ? '' : ' vote-number-selected')}
              style={{ marginRight: 2, background: colorsZeroToTen[index] }}
              onClick={_ => onClickChange(index)}
            >{index}</div>
          )})}
        </div>
        :
        'N/A'

    case 3:
      return ((value != undefined && value != null) || edit) ?
        <div className="vote-gradient">
          {Array.from({ length: 10 }, (_, index) => (
            <div
              key={index}
              className={"vote-number" + (value == index + 1 ? '' : ' vote-number-selected')}
              style={{ marginRight: 2, background: colorsOneToTen[index] }}
              onClick={_ => onClickChange(index + 1)}
            >{index + 1}</div>
          ))}
        </div>
        :
        'N/A'

    case 4:
      return ((value != undefined && value != null) || edit) ? <Rating name="read-only" value={value ?? 0} max={10} readOnly={!edit} onChange={(e, newValue) => onClickChange(newValue)} /> : 'N/A'

    case 5:
      return ((value != undefined && value != null) || edit) ?
        <div className="vote-gradient">
          {Array.from({ length: 5 }, (_, index) => (
            <div
              key={index}
              className={"vote-number" + (value == index + 1 ? '' : ' vote-number-selected')}
              style={{ marginRight: 2, background: colorsOneToFive[index] }}
              onClick={_ => onClickChange(index + 1)}
            >{index + 1}</div>
          ))}
        </div>
        :
        'N/A'

    case 6:
      return ((value != undefined && value != null) || edit) ? <Rating name="read-only" value={value ?? 0} readOnly={!edit} onChange={(e, newValue) => onClickChange(newValue)} /> : 'N/A'

    default:
      if (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15)) {
        return (value || edit) ? <div className="vote-gradient">
          {Array.from({ length: 5 }, (_, index) => (
            <div
              key={index}
              className={"vote-number" + (value == index + 1 ? '' : ' vote-number-selected')}
              style={{ marginRight: 2, background: colorsOneToFive[index] }}
              onClick={_ => onClickChange(index + 1)}
            >{index + 1}</div>
          ))}
        </div> : 'N/A'
      }
      else {
        return (value != undefined || edit) ? <Rating name="read-only" value={value ?? 0} readOnly={!edit} onChange={(e, newValue) => onClickChange(newValue)} /> : 'N/A'
      }

  }
}

export default withStyles(Styles)(RatingElement)