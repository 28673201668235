import Api from './api';

export const getLastFeedbacks = async (organizationId = 0) => { 
  let data = [];
  await Api.get(`/dashboard/lastFeedback/dapper?organizationid=${organizationId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  return data;
};

