import React from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import PropTypes from 'prop-types'
import styled from "styled-components";
import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withStyles } from '@mui/styles'
import './styles.css'
import randomColor from "randomcolor";
import Request from '../icons/request';
import Sent from '../icons/sent';
import Read from '../icons/read';
import Delivered from '../icons/delivered';
import Failed from '../icons/failed';
import {  Tooltip } from '@mui/material';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import ChatMessageHandler from './message-type/chat-message-handler';

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border: none;
  }
  & .MuiInputLabel-root {
    color: #FFF;
  }
  & .MuiOutlinedInput-input {
    padding: 7.5px 10px;
    color: #FFF;
  }
  & .MuiOutlinedInput-root {
    background-color: #3F51B4;
    border: none;
    border-radius: 20px;
  }
  & .MuiOutlinedInput-root .Mui-focused {
    border: none;
  }
  & .MuiInputBase-colorPrimary {
    border: none;
  }
`;

function ChatMessage({ userSession, message, setLoading, reSendMessage }) {
  PapiroConsole.log("=== ChatMessage ===")
  PapiroConsole.log(message)
  

  const sendInternalMessage = async e => {
    //e.preventDefault()
    PapiroConsole.log("=== sendInternalMessage ===")
    if (message != null ) {
        var chatid = message.chatid;

        reSendMessage(message,chatid);
      
    }
  }
  



  if (message.senderuserid === userSession.user.id) {
    let color = "#3F51B4";//randomColor();
    return (<div key={message.id} className="message sender">
      <div className="sender-content">
        <span style={{fontWeight:'bold', color: color}}>{message.sendername}</span><br />
        {/*message.content*/}
        <ChatMessageHandler
         content={message.content}
         id={message.id}
         setLoading={setLoading}
         title={message.content}
         messagetype={message.messagetype} />
        { ( message.request && ! message.sent  && ! message.delivered && ! message.read && message.chatmessagestatus != 5) && ( <Request key={message.id} title={"Sending..."} style={{ width: 15 }} />)}
        { ( message.request && message.sent  && ! message.delivered && ! message.read && message.chatmessagestatus != 5) && ( <Sent key={message.id} style={{ width: 15 }} />)}
        { ( message.request && message.sent && message.delivered && ! message.read  && message.chatmessagestatus != 5) && ( <Delivered key={message.id} style={{ width: 15 }} />)}
        { ( message.request && message.sent && message.delivered && message.read  && message.chatmessagestatus != 5) && ( <Read key={message.id} style={{ width: 15 }} />)}
        { ( message.request &&  message.chatmessagestatus === 5) && ( 
          <Tooltip title={"Code: "+message.errorcode+" | Message: "+message.errormessage}>
            <IconButton key={message.id} onClick={() => {
              //alert('RESEND');
              sendInternalMessage();
            }}>
              <Failed key={message.id} style={{ width: 15 }} title={"Reenviar"} /> 
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div key={"arrow"+message.id} className="arrow"></div>
    </div>)
  } else {
    let color = "#3F51B4";//randomColor();
    return (<div  key={"receiver"+message.id} className="message receiver">
      <div key={"arrowreceiver"+message.id} className="arrow"></div>
      <p>
        <span style={{fontWeight:'bold', color: color}}>{message.sendername}</span><br />
        <ChatMessageHandler
         content={message.content}
         id={message.id}
         setLoading={setLoading}
         title={message.content}
         messagetype={message.messagetype} />
      </p>
    </div>)
  }
}

ChatMessage.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatMessage)))