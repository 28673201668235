import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CardHeader } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from '@mui/material/Chip';
import * as componentActions from '../../redux/actions/knowledgebases';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import './style.css';
import DescriptionIcon from '@mui/icons-material/Description';
import ReactHtmlParser from 'react-html-parser';
import ImageList from '@mui/material/ImageList';
import { AttachFile } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import moment from 'moment';
import * as EvaluationService from '../../services/knowledgebaseevaluations.service'
import FileCopyIcon from '@mui/icons-material/FileCopy';
//import ReactHtmlParser from 'react-html-parser';
import * as FileService from '../../services/attachment.service';
import { PapiroConsole } from '../../utils/papiroConsole';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
  }));

class ClientKnowledgeBases extends Component {

    constructor(props) {
        super(props);

        const { userSession } = this.props;

        this.isClient = true;

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
        });

        let filters = [
            { name: "Palavras-Chaves", id: "keyword" },
            /*{ name: "Autor", id: "createduser" },
            { name: "Patrimônios", id: "patrimony" },
            { name: "Descrição", id: "description" },
            { name: "Titulo", id: "title" },*/
        ];

        if (!this.isClient)
            filters.push({ name: "Apenas para uso interno?", id: "onlyinternuse" })

        this.initialKnowledgeBaseItem = {
            id: 0,
            title: '',
            description: '',
            knowledgebasepatrimonies: [],
            knowledgebaseattachments: [],
            knowledgebasekeywords: [],
        }

        this.initialEvaluation = {
            id: 0,
            knowledgebaseid: 0,
            userid: userSession.user.id,
            wasuseful: null,
            description: '',
            createduser: userSession.user.id,
            createdat: moment().format('YYYY/MM/DD HH:mm:ss'),
            updateduser: userSession.user.id,
            updatedat: moment().format('YYYY/MM/DD HH:mm:ss'),
        }

        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            contentFilter: '',
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
            knowledgeBaseDetailsDialogOpen: false,
            knowledgeBaseItem: this.initialKnowledgeBaseItem,
            evaluation: this.initialEvaluation
        };

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.knowledgeBaseDetails = this.knowledgeBaseDetails.bind(this);

        localStorage.setItem('newFilter', '');
        localStorage.setItem('newSearch', '');
    }

    async changeValues(stateName, value, text = '') {

        this.setState({ filterSelected: value });
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    knowledgeBaseDetailsClose = _ => this.setState({ knowledgeBaseDetailsDialogOpen: false, knowledgeBaseItem: this.initialKnowledgeBaseItem, evaluation: this.initialEvaluation })

    knowledgeBaseDetails = async (item) => {
        this.setState({ loading: true })

        const { userSession } = this.props;

        let evaluationExists = await EvaluationService.getByKnowledgeBaseIdAndUserId(item.id, userSession.user.id)
        if(evaluationExists && evaluationExists.success) {
            if(evaluationExists.data && evaluationExists.data.id) {
                this.setState(prevState => ({
                    evaluation: {
                        ...prevState.evaluation,
                        id: evaluationExists.data.id,
                        knowledgebaseid: evaluationExists.data.knowledgebaseid,
                        wasuseful: evaluationExists.data.wasuseful,
                        description: evaluationExists.data.description
                    }
                }))
            } else {
                this.setState(prevState => ({
                    evaluation: {
                        ...prevState.evaluation,
                        id: 0,
                        knowledgebaseid: item.id
                    }
                }))
            }
        } else {
            this.setState({
                openNotification: true, notificationVariant: 'error',
                notificationMessage: evaluationExists.data && evaluationExists.data.response && evaluationExists.data.response.data && evaluationExists.data.response.data.errors && evaluationExists.data.response.data.errors[0] ? evaluationExists.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(evaluationExists);
        }

        this.setState({ loading: false, knowledgeBaseDetailsDialogOpen: true, knowledgeBaseItem: item })
    }

    positiveEvaluation = async () => {
        const intl = this.props.intl;
        let wasUseful = this.state.evaluation.wasuseful

        await this.setState(prevState => ({
            evaluation: {
                ...prevState.evaluation,
                wasuseful: true
            },
            loading: true
        }))

        let data = new FormData();
        if (this.state.evaluation.id && this.state.evaluation.id > 0) {
            data.append('id', this.state.evaluation.id)
            data.append('updateduser', this.state.evaluation.updateduser)
        } else {
            data.append('createduser', this.state.evaluation.createduser)
        }
        data.append('knowledgebaseid', this.state.evaluation.knowledgebaseid)
        data.append('userid', this.state.evaluation.userid)
        data.append('wasuseful', true)
        data.append('Description', "")

        let saveEvaluation = (this.state.evaluation.id && this.state.evaluation.id > 0) ? await EvaluationService.editItem(data) : await EvaluationService.addItem(data)
        if(saveEvaluation && saveEvaluation.success) {
            this.setState(prevState => ({
                evaluation: {
                    ...prevState.evaluation,
                    id: saveEvaluation.data.id,
                },
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"knowledgebase.report.list.evaluation"})
            }));
        } else {
            this.setState(prevState => ({
                evaluation: {
                    ...prevState.evaluation,
                    wasuseful: wasUseful
                },
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: saveEvaluation.data && saveEvaluation.data.response && saveEvaluation.data.response.data && saveEvaluation.data.response.data.errors && saveEvaluation.data.response.data.errors[0] ? saveEvaluation.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              }));
            Api.kickoff(saveEvaluation);
        }
    }

    negativeEvaluation = async () => {
        const intl = this.props.intl;
        let wasUseful = this.state.evaluation.wasuseful
        
        await this.setState(prevState => ({
            evaluation: {
                ...prevState.evaluation,
                wasuseful: false
            },
            loading: true
        }))

        let data = new FormData();
        if (this.state.evaluation.id && this.state.evaluation.id > 0) {
            data.append('id', this.state.evaluation.id)
            data.append('updateduser', this.state.evaluation.updateduser)
        } else {
            data.append('createduser', this.state.evaluation.createduser)
        }
        data.append('knowledgebaseid', this.state.evaluation.knowledgebaseid)
        data.append('userid', this.state.evaluation.userid)
        data.append('wasuseful', false)
        data.append('Description', this.state.evaluation.description)

        let saveEvaluation = (this.state.evaluation.id && this.state.evaluation.id > 0) ? await EvaluationService.editItem(data) : await EvaluationService.addItem(data)
        if(saveEvaluation && saveEvaluation.success) {
            this.setState(prevState => ({
                evaluation: {
                    ...prevState.evaluation,
                    id: saveEvaluation.data.id,
                },
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"knowledgebase.report.list.evaluation"})
            }));
        } else {
            this.setState(prevState => ({
                evaluation: {
                    ...prevState.evaluation,
                    wasuseful: wasUseful
                },
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: saveEvaluation.data && saveEvaluation.data.response && saveEvaluation.data.response.data && saveEvaluation.data.response.data.errors && saveEvaluation.data.response.data.errors[0] ? saveEvaluation.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              }));
            Api.kickoff(saveEvaluation);
        }
    }

    handleEvaluationDescription = (val) => {
        this.setState(prevState => ({
            evaluation: {
                ...prevState.evaluation,
                description: val
            }
        }))
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    async addFilter() {
        const intl = this.props.intl;
        if (this.state.searchValue.trim() == '' && this.state.filterSelected == 0) {
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"caled.list.notification.select.one.filter.option"})
            });
        } else {
            let newFilter = localStorage.getItem('newFilter');
            let newSearch = localStorage.getItem('newSearch');

            if (this.state.searchValue != '' && this.state.filterSelected != 0) {

                if (this.state.filterSelected === "open") {
                    let date = this.state.searchValue.split("/");
                    if (date.length === 3) {
                        date = `${date[2]}-${date[1]}-${date[0]}`
                    }

                    newFilter += this.state.filterSelected + "@";
                    newSearch += date + "@";

                    let nameField;
                    for (let i = 0; i < this.state.filters.length; i++) {

                        if (this.state.filters[i].id === this.state.filterSelected) {
                            nameField = this.state.filters[i].name;
                        }
                    }

                    this.setState({
                        contentFilter: this.state.contentFilter + nameField + ": " + this.state.searchValue + "\n"
                    });

                } else {

                    newFilter += this.state.filterSelected + "@";
                    newSearch += this.state.searchValue + "@";

                    let nameField;
                    for (let i = 0; i < this.state.filters.length; i++) {
                        if (this.state.filters[i].id === this.state.filterSelected) {
                            nameField = this.state.filters[i].name;
                        }
                    }

                    this.setState({
                        contentFilter: this.state.contentFilter + nameField + ": " + this.state.searchValue + "\n"
                    });
                }

                localStorage.setItem("newFilter", newFilter);
                localStorage.setItem("newSearch", newSearch);

                this.setState({
                    searchValue: '',
                    filterSelected: 0
                })
            }
        }

        // console.log(this.state.contentFilter);

    }

    onFieldChange(event) {
        this.setState({ [event.target.name]: event.target.value, page: 1 });
    }

    callSearch = async () => {
        if (this.state.searchValue.trim() != '' && this.state.filterSelected != 0) {
			await this.addFilter();
        }
        if (this.state.contentFilter && this.state.contentFilter.length > 0) {
            this.setState({ loading: true });
            await this.getItens(this.state.page);
            this.setState({ loading: false });
        }
        /*if (this.state.filterSelected != 0) {
            this.getItens(this.state.page);
        }*/
    }

    async clearFields() {
        await this.setState({ filterSelected: 0, searchValue: '', page: 1,contentFilter: '' })
        localStorage.setItem('newFilter', '');
        localStorage.setItem('newSearch', '');
        this.getItens(1);
    }

    

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'KnowledgeBase');
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
            resolve(); return false;
        }

        Api.delete(`/knowledgebases/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });


    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === 'reload') {
            this.componentDidMount();
        }
    }
    componentDidMount() {
        this.getItens();
    }

    getItens(page = 1) {
        this.setState({ loading: true });
        PapiroConsole.log("USERSESSIONUSER")
        PapiroConsole.log(this.props.userSession.user.onlyclient)
        const role = this.props.userSession.user.onlyclient ? "client" : "agent"
        PapiroConsole.log(role)
        let url = `/knowledgebasekeywords/${role}/paginate?page=${page}`;

        let newFilter = localStorage.getItem('newFilter');
        let newSearch = localStorage.getItem('newSearch');

        if (newFilter != '' && newSearch != '') {
            url += `&search=${newSearch}&field=${newFilter}`;
            this.setState({ dataFilter: true });
        }

        Api.get(url)
            .then(result => {
                if (result.data.success) {

                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);

                    const newFormmatedList = result.data.data.map(item => {

                        let knowledgebaseknowledgebases = []

                        if(item.knowledgebaseknowledgebases != null) {
                            item.knowledgebaseknowledgebases.map((kbItem, kbIdx) => {

                                let keywords = "";
                                let keyWordsList = [];
                                kbItem.knowledgebaseknowledgebasekeywords.map(linkKb => {
                                    if(linkKb && linkKb.knowledgebasekeyword && linkKb.knowledgebasekeyword.word)
                                    keywords = linkKb.knowledgebasekeyword.word + ", " + keywords;
                                    keyWordsList.push(<div className='kb-key'>{linkKb.knowledgebasekeyword.word}</div>)
                                })
                                keywords = keywords.slice(0, -2);

                                keyWordsList = keyWordsList.sort(function(a, b) {
                                    if(a.title < b.title) { return -1 }
                                    if(a.title > b.title) { return 1 }
                                    return 0
                                })
                                if(kbItem.knowledgebaseknowledgebasekeywords && kbItem.knowledgebaseknowledgebasekeywords.length > 2) {
                                    let keywordsSublist = kbItem.knowledgebaseknowledgebasekeywords.slice(2)
                                    let moreKeyWords =
                                        <HtmlTooltip
                                            interactive
                                            placement="bottom-start"
                                            title={
                                                <React.Fragment className='kb-tooltip-root'>
                                                    <div className='kb-tooltip'>
                                                        { keywordsSublist.map(kItem => { return <div className='kb-key kb-key-tooltip'>{kItem.knowledgebasekeyword.word}</div> }) }
                                                    </div>
                                                </React.Fragment>
                                            }
                                        >
                                            <div className='kb-key'>+{(keyWordsList.length - 2)}</div>
                                        </HtmlTooltip>
                                    keyWordsList.push(moreKeyWords)
                                }
                                    knowledgebaseknowledgebases.push(<div className='kb-card' onClick={() => this.knowledgeBaseDetails(kbItem)}>
                                        <div className='kb-card-more'>
                                            <Tooltip title={<h5><FormattedMessage id="details" /></h5>}>
                                                <IconButton color="primary" aria-label="details">
                                                    <DescriptionIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className='kb-card-details'>
                                            <div className='kb-title'>{kbItem.title}</div>
                                            <div className='kb-key-title'>#{kbItem.id}</div>
                                        {(keyWordsList && keyWordsList.length > 0 && keyWordsList.length <= 2) &&
                                            <div className='kb-key-title'>Palavras chave: <div className='kb-keys'>{ keyWordsList.map(keyItem => { return keyItem }) }</div></div>
                                        }
                                        {(keyWordsList && keyWordsList.length > 2) &&
                                            <div className='kb-key-title'>Palavras chave: <div className='kb-keys'>{ keyWordsList.map((keyItem, keyIdx) => {
                                                if(keyIdx < 2 || (keyWordsList.length - 1 === keyIdx)) return keyItem
                                            })}</div></div>
                                        }
                                        </div>
                                    </div>)
                            })
                        }

                        let collapseCardItem = <Accordion style={{marginTop:  "10px"}} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`keyword${item.id}-content`}
                                id={`keyword${item.id}-header`} >
                                <div style={{ display: 'flex' }}>
                                    <Typography style={{ textTransform: 'capitalize', marginRight: 10, fontSize: '1.5em' }}>{item.word}</Typography>
                                    <Badge badgeContent={item.count} color="primary">
                                        <EmojiObjectsIcon />
                                    </Badge>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='material-table'>
                                    {knowledgebaseknowledgebases.map(item => {
                                        return item
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        return {
                            ...item,
                            collapseCardItem: collapseCardItem
                        }

                    });
                    this.setState({ loading: false, resultList: newFormmatedList });
                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }

    handleDelete(chipToDelete) {
        let valueAtual = this.state.contentFilter.split("\n")
        var newvalue = "";
        valueAtual.map(item => {
            if(item != "" && item != valueAtual[chipToDelete]){
                newvalue = newvalue + item+" \n"
            }
        })
        
        this.setState({
            contentFilter: newvalue
        });

        if(newvalue == ""){
            this.clearFields()
        }
        
    };

    useStyles = _ => {
        return withStyles({
            ImageList: {
                width: "100%",
                height: 100,
            },
        });
    }

    getCalledFile = async (attachment) => {
        /*
		let fName = fileName
        if(fName.startsWith('/wwwroot/', 0))
          fName = fName.substring(9)
        window.open(`${Api.apiurlbase()}/${fName}`, '_blank');
		*/

		const intl = this.props.intl;
		this.setState({ loading: true });
		
		
		let url = await FileService.getFile(attachment.hirerid,attachment.id,attachment.path,attachment.filerepositorymethod); 
		//alert(JSON.stringify(url.data));
		this.setState({ loading: false });
		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		}
		else{
			this.setState({
				loading: false, openNotification: true, notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});
	
		}	
	

    }

    copyFileLink = async (attachment) => {
        const intl = this.props.intl;
		this.setState({ loading: true });
		
		let url = await FileService.getFileDownloadLink(attachment.id);
		if(url &&  url.data && url.data.data && url.data.success){
            this.setState({ loading: false });
			navigator.clipboard.writeText(url.data.data)
            this.setState({
				loading: false,
                openNotification: true,
                notificationVariant: "success",
				notificationMessage: "Link de download copiado!"
			})
		} else{
			this.setState({
				loading: false,
                openNotification: true,
                notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});
        }
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        const classes2 = this.useStyles();

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Dialog
                        maxWidth="md"
                        open={this.state.knowledgeBaseDetailsDialogOpen}
                        onClose={this.knowledgeBaseDetailsClose}
                        scroll={'body'}
                        aria-labelledby="knowledgebase-dialog-title">
                        <DialogTitle id="knowledgebase-dialog-title">
                            # {this.state.knowledgeBaseItem.id} | {this.state.knowledgeBaseItem.title}
                            <ClearIcon onClick={this.knowledgeBaseDetailsClose} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent className="knowledgebase-dialog-client-details" >
                            <Grid container spacing={0} style={{ marginTop: 15 }}>
                                <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <h5><FormattedMessage id="description" />:</h5>
                                    <div style={{ padding: '5px 10px', border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: 10 }} >{ReactHtmlParser(this.state.knowledgeBaseItem.description)}</div>
                                </Grid>
                                { (this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords && this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.length > 0) && <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <h5><FormattedMessage id="keywords" />:</h5>
                                    <div className='kb-keys' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                        {this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.map(item => {
                                            return <div className='kb-key' style={{ marginTop: 15 }}>{item.knowledgebasekeyword.word}</div>
                                        })}
                                    </div>
                                </Grid>}
                                {this.state.knowledgeBaseItem.knowledgebaseattachments.length > 0 && (
                                    <Grid item xs={12}>
                                        <div className={classes.attachmentsBox}>
                                            <label>{<FormattedMessage id="attachments" />}</label>
                                            <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                                <ul>
                                                    {this.state.knowledgeBaseItem.knowledgebaseattachments.map(file => {
                                                        return (<li key={file.attachment.path}>
                                                            <AttachFile /> <Button onClick={() => this.getCalledFile(file.attachment)}>{file.attachment.description}</Button>
                                                            <Tooltip title={<h5>Copiar link de download</h5>}>
                                                                <IconButton aria-label="textevidence"
                                                                    onClick={ () => this.copyFileLink(file.attachment)}>
                                                                    <FileCopyIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </li>)
                                                    })}
                                                </ul>
                                            </ImageList>
                                        </div>
                                    </Grid>
                                )}
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={6} style={{ marginTop: 30 }}>
                                    <Card>
                                        <CardHeader title={intl.formatMessage({id:"knowledgebase.report.list.info.useful"})} />
                                        <CardContent>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} >
                                                    <IconButton onClick={() => this.positiveEvaluation()} style={ this.state.evaluation.wasuseful === true ? { border: '1px solid #43A047' } : { border: '1px solid rgba(0, 0, 0, 0.54)' }}>
                                                        <ThumbUpIcon style={ this.state.evaluation.wasuseful === true ? { color: '#43A047', fill: '#43A047' } : { color: 'rgba(0, 0, 0, 0.54)', fill: 'rgba(0, 0, 0, 0.54)' }} />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} >
                                                    <IconButton onClick={() => this.negativeEvaluation()} style={ this.state.evaluation.wasuseful === false ? { border: '1px solid #E53935' } : { border: '1px solid rgba(0, 0, 0, 0.54)' }}>
                                                        <ThumbDownIcon style={ this.state.evaluation.wasuseful === false ? { color: '#E53935', fill: '#E53935' } : { color: 'rgba(0, 0, 0, 0.54)', fill: 'rgba(0, 0, 0, 0.54)' }} />
                                                    </IconButton>
                                                </Grid>
                                                { this.state.evaluation.wasuseful === false && <Grid item xs={12} style={{ marginTop: 25 }} >
                                                    <TextField
                                                        autoComplete="fname"
                                                        id="outlined-multiline-static"
                                                        label={intl.formatMessage({id:"knowledgebase.report.list.suggestion"})}
                                                        name="description"
                                                        multiline
                                                        rows="4"
                                                        fullWidth
                                                        value={this.state.evaluation.description}
                                                        variant="outlined"
                                                        onChange={(e) => this.handleEvaluationDescription(e.target.value)}
                                                        onBlur={() => this.negativeEvaluation()} />
                                                    
                                                </Grid>}
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={3} ></Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={4} justify="flex-end" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={this.knowledgeBaseDetailsClose}
                                        fullWidth
                                    >
                                        {intl.formatMessage({id:"close"})}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%' }}>
                        <CssBaseline />
                        {this.state.contentFilter &&
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={12}>
                                    <Card className="background-title-search">
                                        <CardContent>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item xs={8} md={6}>
                                                    <Typography variant="h5">
                                                        {intl.formatMessage({id:"filters"})}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <strong>
                                                        {this.state.contentFilter.split("\n").map((i, key) => {
                                                            if( i.trim() != "" ){
                                                                return <Chip
                                                                            key={key}
                                                                            label={i}
                                                                            onDelete={() => this.handleDelete(key)}
                                                                            color="primary"
                                                                            variant="outlined"
                                                                        />
                                                            }
                                                        })}
                                                    </strong>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            }

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>

                                <Card className="background-title-search">
                                    <CardContent>

                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5">
                                                    {intl.formatMessage({id:"knowledgebase"})}
                                                        </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                                <SimpleSelect label={intl.formatMessage({ id: "field" })} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected} />
                                            </Grid>
                                            <Grid item>
                                                <Search />
                                            </Grid>
                                            <Grid item >
                                                <TextField type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({ id: "search" })}
                                                    onChange={this.onFieldChange} />
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.addFilter}
                                                    className={classes.button}
                                                    style={{ marginRight: 4, marginLeft: 8 }}

                                                >
                                                    <ZoomInIcon />
                                                </Button>
                                                    
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight: 4 }}
                                                    onClick={this.callSearch}
                                                >
                                                    <Search />
                                                </Button>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button}

                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                            </Grid>

                        </Grid>
                        <Can I='List' a='KnowledgeBase'>
                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={12}>
                                { this.state.resultList.map(item => {
                                    return item.collapseCardItem
                                }) }
                                </Grid>
                            </Grid>
                        </Can>

                        <Can I='List' a='KnowledgeBase'>

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>

                        </Can>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}


                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
ClientKnowledgeBases.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ClientKnowledgeBases)));
ClientKnowledgeBases.contextType = AbilityContext;
