import Api2Talk from "../api2talk";

const PATH = '/webchatsettings';

const create = async (input, config) => { 
  try {
    const response = await Api2Talk.post(PATH, input, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (input, config) => { 
  try {
    const response = await Api2Talk.put(PATH, input, config);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getByWebchatId = async (webchatid) => { 
  try {
    const response = await Api2Talk.get(`${PATH}/get-by-webchat/${webchatid}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const removeLogo = async (id) => { 
  try {
    const response = await Api2Talk.delete(`${PATH}/removelogo/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export {
  create,
  update,
  getByWebchatId,
  removeLogo
}